import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { RootState } from '../store';
import { getSubscriptions } from '../store/features/subscription/get.all.subscription.infos.slice';
import { SubscriptionPlan } from '../models/subscription/get.all.subscription.info.model';
import ContactModal from '../components/ContactModal/ContactModal';
import Footer from '../components/Footer/Footer';

export default function Component() {
  const dispatch = useAppDispatch();
  const subscriptionSlice = useAppSelector((x: RootState) => x.getSubscriptionsSlice);
  const subscriptions = subscriptionSlice.data;
  const [isYearly, setIsYearly] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');

  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);

  const toggleBillingCycle = () => {
    setIsYearly(!isYearly);
  };

  const handleContactClick = (planName: string) => {
    setSelectedPlan(planName);
    setShowContactModal(true);
  };

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-[90%] mx-auto">
        <div className="text-center space-y-4 mb-12">
          <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl lg:text-5xl">
            Get more from VEEWER
          </h1>
          <div className="flex items-center justify-center mt-6">
            <span className={`text-sm font-medium mr-2 ${!isYearly ? 'text-black' : 'text-gray-400'}`}>Monthly</span>
            <button
              onClick={toggleBillingCycle}
              className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              style={{ backgroundColor: isYearly ? '#4F46E5' : '#D1D5DB' }}
            >
              <span
                className={`${
                  isYearly ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
              />
            </button>
            <span className={`text-sm font-medium ml-2 ${isYearly ? 'text-black' : 'text-gray-400'}`}>Yearly</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mt-8">
          {subscriptions && subscriptions.map((plan: SubscriptionPlan) => (
            <div
              key={plan.name}
              className={`relative flex flex-col rounded-2xl shadow-lg overflow-hidden ${
                plan.isMostPopular
                  ? 'bg-gradient-to-b from-gray-800 to-gray-900 text-white z-10'
                  : 'bg-white'
              }`}
            >
              {plan.isMostPopular && (
                <>
                  <div className="absolute top-0 left-0 right-0 h-1 bg-yellow-400" />
                  <div className="absolute top-0 left-1/2 -translate-x-1/2 px-4 py-1 bg-yellow-400 text-gray-900 text-xs font-semibold rounded-b-lg">
                    Most Popular
                  </div>
                </>
              )}
              
              <div className="p-6">
                <div className="space-y-4">
                  <h3 className={`text-xl font-semibold ${plan.isMostPopular ? 'text-gray-100' : 'text-gray-900'}`}>
                    {plan.name}
                  </h3>
                  <div className="flex items-baseline">
                    <span className={`text-4xl font-bold tracking-tight ${plan.isMostPopular ? 'text-white' : 'text-gray-900'}`}>
                      {plan.name == "Enterprise" ? "" : plan.name !== "Starter" ? "£" : "Free"}{ plan.name !== "Starter" && (isYearly ? plan.pricing.yearly : plan.pricing.monthly)}
                    </span>
                    {plan.pricing.monthly !== 'Custom' && (
                      <span className={`ml-1 text-sm ${plan.isMostPopular ? 'text-gray-300' : 'text-gray-500'}`}>
                        {plan.name !== "Starter" && (isYearly ? '/year' : '/month')}
                      </span>
                    )}
                  </div>
                  
                  <div className={`text-sm ${plan.isMostPopular ? 'text-gray-300' : 'text-gray-600'}`}>
   
                    <div>Uploads: {plan.name == "Enterprise" ? "Custom" : plan.name == "Starter" ?  plan.monthlyCredit: isYearly? plan.yearlyCredit+"/year" : plan.monthlyCredit+"/month"}</div>
                  </div>
                </div>

                <div className="mt-6 space-y-4">
                  <div className={`text-sm font-semibold ${plan.isMostPopular ? 'text-gray-200' : 'text-gray-900'}`}>
                    Features included:
                  </div>
                  <div className="space-y-2">
                    {plan.features.map((feature, i: number) => (
                      <div key={i} className="flex items-start gap-2">
                        <div className="flex-shrink-0 h-5 w-5">
                          {feature.available ? (
                            <svg className={`h-5 w-5 ${plan.isMostPopular ? 'text-yellow-400' : 'text-green-500'}`} viewBox="0 0 24 24" fill="currentColor">
                              <path d="M10 15.172l-3.59-3.59-1.415 1.415L10 18 20 8l-1.415-1.415z" />
                            </svg>
                          ) : (
                            <svg className="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M18.3 5.71L12 12.001l-6.29-6.29-1.42 1.42 6.29 6.29-6.29 6.29 1.42 1.42L12 14.421l6.29 6.29 1.42-1.42-6.29-6.29 6.29-6.29z" />
                            </svg>
                          )}
                        </div>
                        <span className={`text-sm ${plan.isMostPopular ? 'text-gray-300' : 'text-gray-600'}`}>
                          {feature.featureName}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="mt-auto p-6 bg-gray-50">
                <button
                  onClick={() => window.location.href = "/login?tab=signup"}
                  className={`w-full py-3 px-4 rounded-xl text-sm font-semibold transition-colors
                    ${
                      plan.isMostPopular
                        ? 'bg-yellow-400 text-gray-900 hover:bg-yellow-300'
                        : 'bg-gray-900 text-white hover:bg-gray-800'
                    }
                  `}
                >
                  Start using for free
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    

      <ContactModal 
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
        selectedPlan={selectedPlan}
      />
      
    </div>
  );
}

