import { PutUploadedObjectResponseModel } from "../../../models/object/uploadedObject/put.uploadedObject.response.model";
import { ProcessUploadIterationResponseModel } from "../../../models/object/uploadedObject/uploadV2/processing.upload.model";
import { BasePostService } from "../../base/base.post.service";

export class FinalizeUploadModelsService extends BasePostService<ProcessUploadIterationResponseModel[],PutUploadedObjectResponseModel>{
    constructor(controllerName:string="objects", endpointName:string="finalizeToUpload"){
        super(controllerName, endpointName)
    }

    async PostAsync(requestBody: ProcessUploadIterationResponseModel[], signal?: AbortSignal): Promise<PutUploadedObjectResponseModel> {
        const url = this.getUrl();
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(requestBody),
                signal
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            if (error instanceof Error && error.name === 'AbortError') {
                throw new Error('Upload cancelled by user');
            }
            throw error;
        }
    }
}