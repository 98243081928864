import React, { useEffect, useState } from 'react';


interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  status: 'idle' | 'loading' | 'success' | 'error';
  message?: string;
  sessionId?: string;
  checkoutUrl?: string;
}



export default function PaymentModal({ isOpen, onClose, status, message, sessionId, checkoutUrl }: PaymentModalProps) {
  useEffect(() => {
    console.log('PaymentModal props:', { isOpen, status, message, sessionId, checkoutUrl });
  }, [isOpen, status, checkoutUrl, sessionId, message]);

  // Function to redirect to Stripe checkout
  const handleRedirectToStripe = () => {
    if (checkoutUrl) {
      console.log('Redirecting to Stripe checkout:', checkoutUrl);
      window.location.href = checkoutUrl;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[60] overflow-y-auto">
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-white rounded-lg w-full max-w-md mx-auto shadow-xl p-6">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-3 right-3 text-gray-400 hover:text-gray-500 z-10"
          >
            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {status === 'loading' && (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto mb-4"></div>
              <p className="text-gray-600">Creating your payment session...</p>
            </div>
          )}

          {status === 'success' && checkoutUrl && (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto mb-4"></div>
              <p className="text-gray-600">Redirecting to payment...</p>
            </div>
          )}
          
          {status === 'success' && !checkoutUrl && sessionId && (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto mb-4"></div>
              <p className="text-gray-600">Initializing payment...</p>
            </div>
          )}

          {status === 'error' && (
            <div className="text-center py-6">
              <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Payment Session Error</h3>
              <p className="text-gray-600 mb-6">{message}</p>
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 