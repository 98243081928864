'use client'

import React, { useState, useEffect, useRef, SyntheticEvent } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  ContentCopy,
  Save,
  Refresh,
  Settings,
  Visibility,
  VisibilityOff,
  ColorLens,
  Landscape,
  Architecture,
  Fullscreen,
  ZoomIn,
  Person,
  PanTool,
  ViewInAr,
  CameraAlt,
  Category,
  Straighten,
  Home,
  Share,
  AccountTree,
  Cameraswitch,
  InfoOutlined,
  ExpandMore,
  Language,
  AspectRatio,
  Palette,
  Build,
  PushPin
} from '@mui/icons-material'
import { QRCodeSVG } from 'qrcode.react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material'
import ObjectID from 'bson-objectid'
import axios from 'axios'

import { UpdateModelIframeCSSButtonService } from '../../services/uploadedObject/update.iframeCSSButton.service'
import { useAppDispatch } from '../../store'
import { GetEmbedCodeInfoService } from '../../services/uploadedObject/get.embedCodeInfo.service'
import { getUploadedObjects } from '../../store/features/object/get.list.uploadedObject.slice'
import { GetQrSourceCodeService } from '../../services/uploadedObject/get.qr.source.code.service'
import { GetSecondaryTokenService } from '../../services/auth/get.secondary.token.service'
import { QRCodeModal } from '../../components/Modals/QRCodeModal'
import { baseUrl } from "../../config/axios.config";
import { CheckFeatureService } from '../../services/subscription/check.feature.service';
import SubscriptionModal from '../../components/SubscriptionModal/SubscriptionModal';

interface ToolIcon {
  name: string
  css: string
  icon: React.ReactNode
  active: boolean
}

interface QrCodeResponse {
  qrSourceCode: boolean
  message?: string
}

interface ApiResponse {
  qrSourceCode: boolean
  message?: string
  cameraViews?: CameraView[]
}

// Add new interface for markups
interface Markup {
  _id: string
  title: string
  description: string
  posX: number
  posY: number
  posZ: number
  cameraMode: string
  position: {
    x: number
    y: number
    z: number
  }
  target: {
    x: number
    y: number
    z: number
  }
  up: {
    x: number
    y: number
    z: number
  }
  isEditing?: boolean
  originalTitle?: string
  originalDescription?: string
  originalPosition?: {
    posX: number
    posY: number
    posZ: number
    position: {
      x: number
      y: number
      z: number
    }
    target: {
      x: number
      y: number
      z: number
    }
    up: {
      x: number
      y: number
      z: number
    }
    cameraMode: string
  }
  isTemp?: boolean
}

// Add new interface for tracking changes
interface MarkupChange {
  _id: string
  timestamp: number
  changes: {
    position?: {
      posX: number
      posY: number
      posZ: number
    }
    camera?: {
      posX: number
      posY: number
      posZ: number
      target: {
        posX: number
        posY: number
        posZ: number
      }
    }
  }
}

// Add new interface for camera views
interface CameraView {
  _id: string;
  position: {
    x: number;
    y: number;
    z: number;
  };
  target: {
    x: number;
    y: number;
    z: number;
  };
  up: {
    x: number;
    y: number;
    z: number;
  };
  name?: string;
  cameraMode?: string;
}

const lightPresetMap = {
  "Simple Grey": 0,
  "Sharp Highlights": 1,
  "Dark Sky": 2,
  "Grey Room": 3,
  "Photo Booth": 4,
  "Tranquility": 5,
  "Infinity Pool": 6,
  "Simple White": 7,
  "Riverbank": 8,
  "Contrast": 9,
  "Rim Highlights": 10,
  "Cool Light": 11,
  "Warm Light": 12,
  "Soft Light": 13,
  "Grid Light": 14
}

// Add this constant at the top of the file, after imports
const POSITION_CHANGE_THRESHOLD = 0.1;

// Add this helper function
const hasSignificantPositionChange = (newPos: any, oldPos: any) => {
    return Math.abs(newPos.posX - oldPos.posX) >= POSITION_CHANGE_THRESHOLD ||
           Math.abs(newPos.posY - oldPos.posY) >= POSITION_CHANGE_THRESHOLD ||
           Math.abs(newPos.posZ - oldPos.posZ) >= POSITION_CHANGE_THRESHOLD;
};

export default function EmbedCode() {
  const location = useLocation()
  const initialEmbedCode = location.state?.embedCode || ''
  const initialWebSite = location.state?.webSite || ''
  const initialModelId = location.state?.modelId || ''
  const name = location.state?.name || ''
  const [newEmbedCode, setNewEmbedCode] = useState<string>(initialEmbedCode)
  const [width, setWidth] = useState<string>("750px")
  const [height, setHeight] = useState<string>("500px")
  const [showIframe, setShowIframe] = useState<boolean>(true)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [toolIcons, setToolIcons] = useState<ToolIcon[]>([
    { name: 'Section Analysis', css: 'toolbar-sectionTool', icon: <ViewInAr />, active: false },
    { name: 'Pan', css: 'toolbar-panTool', icon: <PanTool />, active: false },
    { name: 'Zoom', css: 'toolbar-zoomTool', icon: <ZoomIn />, active: false },
    { name: 'Properties', css: 'toolbar-propertiesTool', icon: <Build />, active: false },
    { name: 'Orbit', css: 'toolbar-orbitTools', icon: <Architecture />, active: false },
    { name: 'Settings', css: 'toolbar-settingsTool', icon: <Settings />, active: false },
    { name: 'Model Browser', css: 'toolbar-modelStructureTool', icon: <AccountTree />, active: false },
    { name: 'First Person', css: 'toolbar-bimWalkTool', icon: <Person />, active: false },
    { name: 'Cam Instructions', css: 'toolbar-cameraSubmenuTool', icon: <CameraAlt />, active: false },
    { name: 'Explode Model', css: 'toolbar-explodeTool', icon: <Category />, active: false },
    { name: 'Measure', css: 'toolbar-measurementSubmenuTool', icon: <Straighten />, active: false },
    { name: 'Full Screen', css: 'toolbar-fullscreenTool', icon: <Fullscreen />, active: false },
    { name: 'ViewCube', css: 'viewcube', icon: <ViewInAr />, active: false },
    { name: 'ViewHome', css: 'homeViewWrapper', icon: <Home />, active: false },
  ])
  const [website, setWebsite] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const b = sessionStorage.getItem('b') as string
  const [lightPreset, setLightPreset] = useState<string>("Simple Grey")
  const [backgroundColor, setBackgroundColor] = useState<string>("#FFFFFF")
  const [groundShadow, setGroundShadow] = useState<boolean>(false)
  const [groundReflection, setGroundReflection] = useState<boolean>(false)
  const [showWatermark, setShowWatermark] = useState<boolean>(false)
  const [showSettings, setShowSettings] = useState<boolean>(true)
  const [qrCodeData, setQrCodeData] = useState<string>('')
  const [isQrModalOpen, setIsQrModalOpen] = useState(false)
  const [isQrEnabled, setIsQrEnabled] = useState(false)
  const loginInfo = sessionStorage.getItem('login') as string
  const jsonLoginInfo = JSON.parse(loginInfo)
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState<boolean>(true)
  const [changes, setChanges] = useState<Array<{property: string, value: string}>>([])
  const [isChangesPanelOpen, setIsChangesPanelOpen] = useState<boolean>(false)
  const [activeMenu, setActiveMenu] = useState<string | null>(null)
  const [tooltipContent, setTooltipContent] = useState<string>('')
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const tooltipRef = useRef<HTMLDivElement>(null)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [isEditingMode, setIsEditingMode] = useState<boolean>(true)
  const menuRef = useRef<HTMLDivElement>(null)
  const initialStates = useRef({
    width: "750px",
    height: "500px",
    lightPreset: "Simple Grey",
    backgroundColor: "#FFFFFF",
    groundShadow: false,
    groundReflection: false,
    showWatermark,
    toolIcons: [] as ToolIcon[],
    website,
    embedCode: newEmbedCode,
    hideBottomToolbar: false,
    isEditingMode
  })
  const [websiteMenuOpen, setWebsiteMenuOpen] = useState(false);
  const [sizeMenuOpen, setSizeMenuOpen] = useState(false);
  const [lightMenuOpen, setLightMenuOpen] = useState(false);
  const [viewMenuOpen, setViewMenuOpen] = useState(false);
  const [toolsMenuOpen, setToolsMenuOpen] = useState(false);
  const [qrMenuOpen, setQrMenuOpen] = useState(false);
  const [markupMenuOpen, setMarkupMenuOpen] = useState(false);
  const [markups, setMarkups] = useState<Markup[]>([]);
  const [editingMarkup, setEditingMarkup] = useState<Markup | null>(null);
  const [isAddingMarkup, setIsAddingMarkup] = useState<boolean>(false);
  const [markupChanges, setMarkupChanges] = useState<MarkupChange[]>([]);
  const [activeMarkupId, setActiveMarkupId] = useState<string | null>(null);
  const [editedMarkups, setEditedMarkups] = useState<{ [key: string]: boolean }>({});

  // Add new state for tracking position updates
  const [positionUpdates, setPositionUpdates] = useState<{[key: string]: {
    x: number;
    y: number;
    z: number;
  }}>({});

  // Add new state for camera views
  const [cameraViews, setCameraViews] = useState<CameraView[]>([]);
  const [isAddingCameraView, setIsAddingCameraView] = useState(false);

  // Add new state for camera view editing
  const [editingViewId, setEditingViewId] = useState<string | null>(null);
  const [reorderingViews, setReorderingViews] = useState(false);

  // Add new state for camera mode
  const [currentCameraMode, setCurrentCameraMode] = useState<string>('perspective');

  const tooltips = {
    website: "Enter your website URL and embed code here. This will be used to display your 3D model.",
    size: "Adjust the width and height of your 3D model viewer.",
    appearance: "Customize lighting, background, and visual effects.",
    tools: "Enable or disable various tools for interacting with your 3D model.",
    markup: "Add and manage markup points on your 3D model."
  }

  const [activeTab, setActiveTab] = useState<string>('website');
  const [hideBottomToolbar, setHideBottomToolbar] = useState<boolean>(false);
  const [iframeError, setIframeError] = useState<boolean>(false);
  const [errorType, setErrorType] = useState({ code: 0, message: '' });
  const [isServerHealthy, setIsServerHealthy] = useState<boolean>(false);
  const [isCheckingHealth, setIsCheckingHealth] = useState<boolean>(true);
  const [featureAvailability, setFeatureAvailability] = useState({
    watermarkRemoval: false,
    customBackground: false,
    dataAnnotations: false,
    cameraAnimations: false,
    customBranding: false
  });

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isLogoShown, setIsLogoShown] = useState<boolean>(false);

  // Add feature check effect
  useEffect(() => {
    const checkFeatures = async () => {
      const login = sessionStorage.getItem('login');
      if (!login) return;

      const { uid } = JSON.parse(login);
      const checkFeatureService = new CheckFeatureService();

      const [
        watermarkRemoval,
        customBackground,
        dataAnnotations,
        cameraAnimations,
        customBranding
      ] = await Promise.all([
        checkFeatureService.GetByStringManyParamsAsync([uid, 'Watermark removal']),
        checkFeatureService.GetByStringManyParamsAsync([uid, 'Custom background']),
        checkFeatureService.GetByStringManyParamsAsync([uid, 'Data annotations']),
        checkFeatureService.GetByStringManyParamsAsync([uid, 'Camera animations']),
        checkFeatureService.GetByStringManyParamsAsync([uid, 'Custom branding'])
      ]);

      setFeatureAvailability({
        watermarkRemoval,
        customBackground,
        dataAnnotations,
        cameraAnimations,
        customBranding
      });

      // Set initial logo visibility based on custom branding feature
    };

    checkFeatures();
  }, []);

  // Add health check effect
  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        setIsCheckingHealth(true);
        const response = await axios.get(`${baseUrl}health`);
        if (response.data.status === "Healthy") {
          setIsServerHealthy(true);
          setIframeError(false);
          setErrorType({ code: 0, message: '' });
        } else {
          setIsServerHealthy(false);
          setIframeError(true);
          setErrorType({
            code: -1,
            message: 'VEEWER service is currently unavailable. Please try again later.'
          });
        }
      } catch (error) {
        setIsServerHealthy(false);
        setIframeError(true);
        setErrorType({
          code: -1,
          message: 'VEEWER service is currently unavailable. Please try again later.'
        });
      } finally {
        setIsCheckingHealth(false);
      }
    };

    checkServerHealth();
  }, []);

  useEffect(() => {
    const service = new GetEmbedCodeInfoService()
    const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
    if (srcMatch) {
      const srcValue = srcMatch[1]
      const splittedSrc = srcValue.split("/")
      
      const urn = splittedSrc[7]

      service.GetByStringManyParamsAsync([urn,name]).then((data) => {
        setNewEmbedCode(data.iframeCssButton)
        setWebsite(data.webSite)
        
        // Get QR code data
        const qrService = new GetQrSourceCodeService();
        qrService.GetByStringAsync(initialModelId).then((response: any) => {
          const qrResponse = response as ApiResponse;
          if (qrResponse.qrSourceCode && response.status !== 500) {
            const qrUrl = `https://ar.veewer.com?userId=${jsonLoginInfo.uid}&modelId=${urn}`;
            setQrCodeData(qrUrl);
            setIsQrEnabled(true);  // Only enable if we have valid QR data
          } else {
            setIsQrEnabled(false);
            setQrCodeData('');
            console.error('QR code generation failed:', qrResponse);
          }
        }).catch((error) => {
          console.error('Failed to get QR code:', error);
          setIsQrEnabled(false);
          setQrCodeData('');
        });

        // Parse the embed code and set initial states
        const embedCodeSrc = data.iframeCssButton.match(/src=['"]([^'"]*)['"]/)?.[1] || ''
        const [, propertiesString] = embedCodeSrc.split('/properties/')
        const properties = new URLSearchParams(propertiesString || '')

        // Set logo visibility from properties
        const logoValue = properties.get('isLogoShown');
        console.log('Initial isLogoShown value from properties:', logoValue);
        if (logoValue !== null) {
          setIsLogoShown(logoValue === 'true');
          console.log('Setting isLogoShown to:', logoValue === 'true');
        } else {
          // Default to false if not specified
          setIsLogoShown(false);
          console.log('No isLogoShown in properties, defaulting to false');
        }

        // Set width and height
        const widthMatch = data.iframeCssButton.match(/width=['"](\d+px)['"]/);
        const heightMatch = data.iframeCssButton.match(/height=['"](\d+px)['"]/);
        if (widthMatch) setWidth(widthMatch[1])
        if (heightMatch) setHeight(heightMatch[1])
  
        // Set light preset
        const lightPresetValue = properties.get('lightPreset')
        if (lightPresetValue) {
          const presetNumber = parseInt(lightPresetValue)
          const presetName = Object.keys(lightPresetMap).find(
            (key) => lightPresetMap[key as keyof typeof lightPresetMap] === presetNumber
          ) || "Simple Grey"
          setLightPreset(presetName)
        }
  
        // Set background color
        const backgroundColorValue = properties.get('backgroundColor')
        if (backgroundColorValue) {
          setBackgroundColor(`#${backgroundColorValue}`)
        }

        // Set ground shadow
        setGroundShadow(properties.get('setGroundShadow') === 'true')

        // Set ground reflection
        setGroundReflection(properties.get('setGroundReflection') === 'true')

        // Set watermark based on iframe properties - tersine çevrilmiş mantık
        const watermarkValue = properties.get('showWatermark')
        setShowWatermark(watermarkValue === 'false' || watermarkValue === undefined);

        // Set tool icons
        const activeToolsString = properties.get('activeTools') || ''
        const activeTools = activeToolsString.split(',')
        setToolIcons((prevIcons) =>
          prevIcons.map((icon) => ({
            ...icon,
            active: activeTools.includes(icon.css),
          }))
        )

        // Load camera views if they exist
        if (data.cameraViews) {
          setCameraViews(data.cameraViews);
          // Send camera views to iframe
          if (iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage({
              type: 'SET_CAMERA_VIEWS',
              views: data.cameraViews
            }, '*');
          }
        }
      })
    }
  }, [initialEmbedCode, name])
  
  useEffect(() => {
    // Store initial states when component mounts
    initialStates.current = {
      width,
      height,
      lightPreset,
      backgroundColor,
      groundShadow,
      groundReflection,
      showWatermark,
      toolIcons: [...toolIcons],
      website,
      embedCode: newEmbedCode,
      hideBottomToolbar,
      isEditingMode
    }
  }, [])

  const updateProperty = (propertyName: string, value: string) => {
    setNewEmbedCode(prevCode => {
      const srcMatch = prevCode.match(/src=['"]([^'"]*)['"]/);
      if (srcMatch) {
        let srcValue = srcMatch[1]
        const [baseUrl, propertiesString] = srcValue.split('/properties/')
        const properties = new URLSearchParams(propertiesString || '')
        
        if (value) {
          properties.set(propertyName, value)
        } else {
          properties.delete(propertyName)
        }

        const newSrc = `${baseUrl}/properties/${properties.toString()}`
        return prevCode.replace(/src=['"]([^'"]*)['"]/,  `src='${newSrc}'`)
      }
      return prevCode
    })
  }

  // Add effect to update isEditableMarkup when edit mode changes
  useEffect(() => {
    updateProperty('isEditableMarkup', isEditingMode.toString())
  }, [isEditingMode])

  const updateToolIcons = (newToolIcons: ToolIcon[]) => {
    const activeTools = newToolIcons.filter(icon => icon.active).map(icon => icon.css).join(',')
    updateProperty('activeTools', activeTools)
  }

  const addChange = (property: string, value: string) => {
    // Skip adding markup operations to the changes list
    if (property.includes('Markup') || property.includes('markup')) {
      console.log('Skipping markup change in changes list:', property, value);
      return;
    }
    
    // Only add non-markup changes to the changes list
    setChanges(prev => [{property, value}, ...prev]);
    setHasUnsavedChanges(true);
  }

  const handleToggleIcon = (index: number) => {
    setToolIcons(prevIcons => {
      const newIcons = prevIcons.map((icon, i) => 
        i === index ? { ...icon, active: !icon.active } : icon
      )
      const icon = prevIcons[index]
      addChange('Tool', `${icon.name} is now ${!icon.active ? 'Enabled' : 'Disabled'}`)
      updateToolIcons(newIcons)
      return newIcons
    })
  }

  function extractContent() {
    const startIndex = newEmbedCode.indexOf("content/") + "content/".length;
    const endIndex = newEmbedCode.indexOf("'>", startIndex);
    
    if (startIndex !== -1 && endIndex !== -1) {
      navigator.clipboard.writeText(newEmbedCode.substring(startIndex, endIndex)).then(() => {
        Swal.fire({
          title: 'Copied!',
          text: 'SharePoint Urn code copied to clipboard',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        })
      })
      .catch(() => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to copy embed code',
          icon: 'error'
        })
      });
    }
    return null;
  }
  
  const handleCopy = () => {
    // Create a temporary embed code with isEditableMarkup=false
    let tempEmbedCode = newEmbedCode;
    const srcMatch = tempEmbedCode.match(/src=['"]([^'"]*)['"]/);
    
    if (srcMatch) {
      let srcValue = srcMatch[1];
      const [baseUrl, propertiesString] = srcValue.split('/properties/');
      const properties = new URLSearchParams(propertiesString || '');
      
      // Set isEditableMarkup to false
      properties.set('isEditableMarkup', 'false');
      
      const newSrc = `${baseUrl}/properties/${properties.toString()}`;
      tempEmbedCode = tempEmbedCode.replace(/src=['"]([^'"]*)['"]/,  `src='${newSrc}'`);
    }
    
    navigator.clipboard.writeText(tempEmbedCode)
      .then(() => {
        Swal.fire({
          title: 'Copied!',
          text: 'Embed code copied to clipboard',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        });
      })
      .catch(() => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to copy embed code',
          icon: 'error'
        });
      });
  };

  const handleBackgroundColorChange = (color: React.ChangeEvent<HTMLInputElement>) => {
    if (!featureAvailability.customBackground) {
      Swal.fire({
        title: 'Feature Not Available',
        text: 'Custom background is not available in your current plan.',
        icon: 'warning'
      });
      return;
    }
    const newColor = color.target.value;
    setBackgroundColor(newColor);
    // The updateProperty and addChange calls are moved to the Apply button
  };
  
  const handleSavingSettings = async () => {
    setIsLoading(true)
    // Update embed code with isEditableMarkup=false before saving
    updateProperty('isEditableMarkup', 'false');
    
    const service = new UpdateModelIframeCSSButtonService()
    const srcMatch = newEmbedCode.match(/src=['"]([^'"]*)['"]/);
    if (srcMatch) {
      const srcValue = srcMatch[1]
      const splittedSrc = srcValue.split("/")
      const urn = splittedSrc[7]
      const uid = splittedSrc[9]
      try {
        const data = await service.PostAsync({
          iframeCSSButton: newEmbedCode,
          webSite: website,
          urn: urn,
          uid: uid,
          name: name,
          cameraViews: cameraViews
        })
        if (data.isSuccess) {
          const service = new GetEmbedCodeInfoService()
          const updatedResponse = await service.GetByStringManyParamsAsync([urn,name])
          setNewEmbedCode(updatedResponse.iframeCssButton)
          setWebsite(updatedResponse.webSite)
          dispatch(
            getUploadedObjects({
              bucketKey: b
            })
          )
          // Update initial states after successful save
          initialStates.current = {
            width,
            height,
            lightPreset,
            backgroundColor,
            groundShadow,
            groundReflection,
            showWatermark,
            toolIcons: [...toolIcons],
            website,
            embedCode: newEmbedCode,
            hideBottomToolbar,
            isEditingMode
          }
          setHasUnsavedChanges(false)
          
          // Show custom success modal
          Swal.fire({
            html: `
              <div class="flex flex-col items-center">
                <div class="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
                  <svg class="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <h3 class="text-lg font-medium text-gray-900 mb-2">Settings Saved Successfully!</h3>
                <p class="text-sm text-gray-500">Your changes have been saved and applied to the model viewer.</p>
              </div>
            `,
            showConfirmButton: false,
            timer: 2000,
            customClass: {
              popup: 'rounded-lg shadow-xl',
              htmlContainer: 'p-4'
            },
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          })
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          title: 'Error',
          text: 'Failed to save settings',
          icon: 'error',
          customClass: {
            popup: 'rounded-lg'
          }
        })
      } finally {
        setIsLoading(false)
      }
    } else {
      console.log("src not found")
      setIsLoading(false)
      Swal.fire({
        title: 'Error',
        text: 'Source URL not found in embed code',
        icon: 'error',
        customClass: {
          popup: 'rounded-lg'
        }
      })
    }
  }

  const handleUpdateIframe = () => {
    setShowIframe(true)
    if (iframeRef.current) {
      iframeRef.current.src = iframeRef.current.src
    }
  }

  const handleWidthChange = (newWidth: string) => {
    setWidth(newWidth)
    setNewEmbedCode(prevCode => 
      prevCode.replace(/width=['"][\d.]+(?:px|%)?['"]/, `width='${newWidth}'`)
    )
    if (iframeRef.current) {
      iframeRef.current.style.width = newWidth
    }
    addChange('Width', newWidth)
  }

  const handleHeightChange = (newHeight: string) => {
    setHeight(newHeight)
    setNewEmbedCode(prevCode => 
      prevCode.replace(/height=['"][\d.]+(?:px|%)?['"]/, `height='${newHeight}'`)
    )
    if (iframeRef.current) {
      iframeRef.current.style.height = newHeight
    }
    addChange('Height', newHeight)
  }

  const handleNumberInputChange = (handler: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    handler(e.target.value + 'px')
  }

  const handleLightPresetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as keyof typeof lightPresetMap
    setLightPreset(value)
    updateProperty('lightPreset', lightPresetMap[value].toString())
    addChange('Light Preset', value)
  }

  const handleGroundShadowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked
    setGroundShadow(newValue)
    updateProperty('setGroundShadow', newValue ? 'true' : '')
    addChange('Ground Shadow', newValue ? 'Enabled' : 'Disabled')
  }

  const handleGroundReflectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked
    setGroundReflection(newValue)
    updateProperty('setGroundReflection', newValue ? 'true' : '')
    addChange('Ground Reflection', newValue ? 'Enabled' : 'Disabled')
  }

  const handleWatermarkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!featureAvailability.watermarkRemoval) {
      Swal.fire({
        title: 'Feature Not Available',
        text: 'Watermark removal is not available in your current plan.',
        icon: 'warning'
      });
      return;
    }
    const isChecked = e.target.checked;
    setShowWatermark(isChecked);
    updateProperty('showWatermark', (!isChecked).toString());
    addChange('Remove Watermark', isChecked ? 'Enabled' : 'Disabled');
  }

  const handleLogoVisibilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!featureAvailability.customBranding) {
      Swal.fire({
        title: 'Feature Not Available',
        text: 'Logo visibility control is not available in your current plan.',
        icon: 'warning'
      });
      return;
    }
    const isChecked = e.target.checked;
    console.log('Changing isLogoShown to:', isChecked);
    setIsLogoShown(isChecked);
    
    // Update property in iframe src URL
    updateProperty('isLogoShown', isChecked.toString());
    
    // Add to changes list
    addChange('Show Logo', isChecked ? 'Enabled' : 'Disabled');
    
    // Immediately update iframe if possible
    if (iframeRef.current?.contentWindow) {
      console.log('Sending logo visibility update to iframe:', isChecked);
      iframeRef.current.contentWindow.postMessage({
        type: 'UPDATE_LOGO_VISIBILITY',
        visible: isChecked
      }, '*');
    }
  }

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element
      if (activeMenu && !target.closest('.menu-dropdown')) {
        setActiveMenu(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [activeMenu])

  const showTooltip = (content: string, event: React.MouseEvent) => {
    const target = event.currentTarget.getBoundingClientRect()
    const tooltipElement = tooltipRef.current
    
    if (tooltipElement) {
      const tooltipWidth = 200 // Fixed tooltip width
      const windowWidth = window.innerWidth
      
      // Calculate x position to keep tooltip within viewport
      let xPos = target.left + (target.width - tooltipWidth) / 2
      xPos = Math.max(10, Math.min(xPos, windowWidth - tooltipWidth - 10))
      
      setTooltipPosition({
        x: xPos,
        y: target.bottom + window.scrollY + 5
      })
    }
    
    setTooltipContent(content)
    setTooltipVisible(true)
  }

  const hideTooltip = () => {
    setTooltipVisible(false)
  }

  const MenuHeader = ({ title, tooltip }: { title: string, tooltip: string }) => (
    <div className="flex items-center justify-between mb-3">
      <div className="flex items-center">
        <h3 className="text-white text-sm font-medium">{title}</h3>
        <div
          className="ml-2 text-gray-400 hover:text-white cursor-help"
          onMouseEnter={(e) => showTooltip(tooltip, e)}
          onMouseLeave={hideTooltip}
        >
          <InfoOutlined className="w-4 h-4" />
        </div>
      </div>
    </div>
  )

  const handleUndoAllChanges = () => {
    // First, restore the original embed code completely
    setNewEmbedCode(initialStates.current.embedCode);
    
    // Reset all UI states to match initial values
    setWidth(initialStates.current.width);
    setHeight(initialStates.current.height);
    setLightPreset(initialStates.current.lightPreset);
    setBackgroundColor(initialStates.current.backgroundColor);
    setGroundShadow(initialStates.current.groundShadow);
    setGroundReflection(initialStates.current.groundReflection);
    setShowWatermark(initialStates.current.showWatermark);
    setHideBottomToolbar(initialStates.current.hideBottomToolbar);
    setWebsite(initialStates.current.website);
    
    // Reset tool icons
    if (initialStates.current.toolIcons.length > 0) {
        setToolIcons([...initialStates.current.toolIcons]);
    }
    
    // Reset all markups to their original values
    setMarkups(prevMarkups => {
        const restoredMarkups = prevMarkups.map(m => {
            const restored = {
                ...m,
                title: m.originalTitle || m.title,
                description: m.originalDescription || m.description
            };
            
            // Restore original position if it exists
            if (m.originalPosition) {
                restored.posX = m.originalPosition.posX;
                restored.posY = m.originalPosition.posY;
                restored.posZ = m.originalPosition.posZ;
                restored.position = m.originalPosition.position;
                restored.target = m.originalPosition.target;
                restored.up = m.originalPosition.up;
                restored.cameraMode = m.originalPosition.cameraMode;
            }
            
            return restored;
        });
        
        // Send updates to iframe
        if (iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage({
                type: 'SET_MARKUPS',
                markups: restoredMarkups
            }, '*');
        }
        
        return restoredMarkups;
    });
    
    // Make sure editing mode is preserved
    if (initialStates.current.isEditingMode !== undefined) {
        setIsEditingMode(initialStates.current.isEditingMode);
    }
    
    // Clear all change tracking
    setEditedMarkups({});
    setPositionUpdates({});
    setHasUnsavedChanges(false);
    setChanges([]);
    
    // Update the iframe after all state changes
    setTimeout(() => {
        if (iframeRef.current) {
            // Use the same src from the original embed code
            const srcMatch = initialStates.current.embedCode.match(/src=['"]([^'"]*)['"]/);
            if (srcMatch && srcMatch[1]) {
                iframeRef.current.src = srcMatch[1];
            } else {
                // Fallback to the standard reload
                handleUpdateIframe();
            }
        }
    }, 10); // small delay to ensure all state updates are processed
    
    closeAllMenus();
};

  const closeAllMenus = (except?: string) => {
    if (except !== 'website') setWebsiteMenuOpen(false);
    if (except !== 'size') setSizeMenuOpen(false);
    if (except !== 'light') setLightMenuOpen(false);
    if (except !== 'view') setViewMenuOpen(false);
    if (except !== 'tools') setToolsMenuOpen(false);
    if (except !== 'qr') setQrMenuOpen(false);
    if (except !== 'markup') setMarkupMenuOpen(false);
  };

  const toggleMenu = (menu: string) => {
    closeAllMenus(menu);
    switch (menu) {
      case 'website':
        setWebsiteMenuOpen(!websiteMenuOpen);
        break;
      case 'size':
        setSizeMenuOpen(!sizeMenuOpen);
        break;
      case 'light':
        setLightMenuOpen(!lightMenuOpen);
        break;
      case 'view':
        setViewMenuOpen(!viewMenuOpen);
        break;
      case 'tools':
        setToolsMenuOpen(!toolsMenuOpen);
        break;
      case 'qr':
        setQrMenuOpen(!qrMenuOpen);
        break;
      case 'markup':
        setMarkupMenuOpen(!markupMenuOpen);
        break;
    }
  };

  // Add this new useEffect to fetch markups when component mounts
  useEffect(() => {
    const fetchMarkups = async () => {
        try {
            const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
            if (!srcMatch) return;

            const srcValue = srcMatch[1];
            const splittedSrc = srcValue.split("/");
            const urn = splittedSrc[7];
            
            try {
                const tokenService = new GetSecondaryTokenService();
                const token = await tokenService.GetByStringAsync(jsonLoginInfo.uid);
                
                const response = await fetch(`${baseUrl}objects/dataannotations?urn=${urn}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json();
                const initializedMarkups = data.map((markup: Markup) => ({
                    ...markup,
                    _id: markup._id,
                    isEditing: false,
                    // Store original values for all existing markups
                    originalTitle: markup.title,
                    originalDescription: markup.description,
                    originalPosition: {
                        posX: markup.posX,
                        posY: markup.posY,
                        posZ: markup.posZ,
                        position: { ...markup.position },
                        target: { ...markup.target },
                        up: { ...markup.up },
                        cameraMode: markup.cameraMode
                    }
                }));
                
                setMarkups(initializedMarkups);
                // Reset all change tracking states
                setEditedMarkups({});
                setPositionUpdates({});
                setHasUnsavedChanges(false);
                
            } catch (error) {
                console.error('Error fetching markups:', error);
                if (error instanceof Error) {
                    console.error('Error details:', error.message);
                }
            }
        } catch (error) {
            console.error('Error in fetchMarkups:', error);
        }
    };

    fetchMarkups();
}, [initialEmbedCode, jsonLoginInfo.uid]);

  // Completely redesign the Cancel behavior to avoid React state issues

  // First, add a temporary state to store edit values
  const [tempEditValues, setTempEditValues] = useState<{[key: string]: {title: string, description: string}}>({});

  // Update handleDoubleClick to initialize temp values
  const handleDoubleClick = (markup: Markup, field: 'title' | 'description') => {
    // Store current values in temp state first
    setTempEditValues(prev => ({
      ...prev,
      [markup._id]: {
        title: markup.title || '',
        description: markup.description || ''
      }
    }));
    
    // Then set editing mode
    setMarkups((prevMarkups: Markup[]) => prevMarkups.map((m: Markup) => {
      const isMatchingMarkup = m._id === markup._id;
      
      return isMatchingMarkup
        ? {
            ...m,
            isEditing: true,
            originalTitle: m.originalTitle || m.title || '',
            originalDescription: m.originalDescription || m.description || ''
          }
        : {
            ...m,
            isEditing: false
          };
    }));
  };

  // Update message handler
  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      console.log('Received message:', event.data);

      if (event.data.type === 'CAMERA_MODE_CHANGED') {
        console.log('Camera mode changed to:', event.data.mode);
        setCurrentCameraMode(event.data.mode);
      }
      else if (event.data.type === 'NEW_MARKUP') {
        console.log('Creating new markup in mode:', currentCameraMode);
        setIsAddingMarkup(false);
        
        // Create new markup with the ID from iframe
        const newMarkup = {
          ...event.data.markup,
          isEditing: true,
          isTemp: true,
          cameraMode: currentCameraMode,
          originalPosition: {
            posX: event.data.markup.posX,
            posY: event.data.markup.posY,
            posZ: event.data.markup.posZ,
            position: event.data.markup.position,
            target: event.data.markup.target,
            up: event.data.markup.up,
            cameraMode: event.data.markup.cameraMode
          }
        };
        
        // Update markups state with the new markup and ensure others are not in edit mode
        setMarkups(prevMarkups => {
          const updatedMarkups = prevMarkups.map(m => ({
            ...m,
            isEditing: false
          }));
          return [...updatedMarkups, newMarkup];
        });

        setEditingMarkup(newMarkup);
      }
      else if (event.data.type === 'MARKUP_POSITION_CHANGED') {
        const { markup } = event.data;
        console.log('Position change received:', markup);
        
        setMarkups(prevMarkups => {
          return prevMarkups.map(m => {
            if (m._id === markup._id) {
              // Store current position as original if not already stored
              const originalPosition = m.originalPosition || {
                  posX: m.posX,
                  posY: m.posY,
                  posZ: m.posZ,
                  position: { ...m.position },
                  target: { ...m.target },
                  up: { ...m.up },
                  cameraMode: m.cameraMode
              };

              // Check if the position change is significant
              const currentPos = {
                  posX: m.posX,
                  posY: m.posY,
                  posZ: m.posZ
              };
              
              const newPos = {
                  posX: markup.posX,
                  posY: markup.posY,
                  posZ: markup.posZ
              };

              // Only update if change is significant
              if (!hasSignificantPositionChange(newPos, currentPos)) {
                  return m;
              }

              const updatedMarkup = {
                  ...m,
                  posX: markup.posX,
                  posY: markup.posY,
                  posZ: markup.posZ,
                  position: markup.position,
                  target: markup.target,
                  up: markup.up,
                  cameraMode: markup.cameraMode,
                  originalPosition: m.originalPosition || originalPosition
              };

              setPositionUpdates(prev => ({
                  ...prev,
                  [markup._id]: {
                      x: markup.posX,
                      y: markup.posY,
                      z: markup.posZ
                  }
              }));

              setEditedMarkups(prev => ({ ...prev, [markup._id]: true }));
              return updatedMarkup;
            }
            return m;
          });
        });

        setActiveMarkupId(markup._id);
        setTimeout(() => {
          setActiveMarkupId(null);
        }, 2000);
      } else if (event.data.type === 'CAMERA_VIEW_ADDED') {
        try {
          const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
          if (!srcMatch) return;

          const srcValue = srcMatch[1];
          const splittedSrc = srcValue.split("/");
          const urn = splittedSrc[7];

          const tokenService = new GetSecondaryTokenService();
          const token = await tokenService.GetByStringAsync(jsonLoginInfo.uid);

          // Generate a MongoDB compatible ObjectId
          const newId = new ObjectID().toHexString();

          // Ensure view data has valid coordinates
          const viewData = event.data.view || {};
          const position = viewData.position || { x: 0, y: 0, z: 0 };
          const target = viewData.target || { x: 0, y: 0, z: 0 };

          // Prepare the new camera view with frontend format
          const newView = {
            _id: newId,
            name: `View ${cameraViews.length + 1}`,
            position: {
              x: Number(position.x || 0),
              y: Number(position.y || 0),
              z: Number(position.z || 0)
            },
            target: {
              x: Number(target.x || 0),
              y: Number(target.y || 0),
              z: Number(target.z || 0)
            },
            up: { x: 0, y: 1, z: 0 }
          };

          // Convert to backend format for saving
          const backendView = {
            _id: newId,
            name: newView.name,
            position: {
              posX: newView.position.x,
              posY: newView.position.y,
              posZ: newView.position.z
            },
            target: {
              posX: newView.target.x,
              posY: newView.target.y,
              posZ: newView.target.z
            }
          };

          // Add to backend
          const response = await fetch(`${baseUrl}objects/cameraanimation?urn=${urn}`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(backendView)
          });

          if (!response.ok) {
            throw new Error('Failed to save camera view');
          }

          // Update local state with frontend format
          setCameraViews(prev => [...prev, newView]);
          setIsAddingCameraView(false);

          // Notify iframe about the new ID and send all views
          if (iframeRef.current?.contentWindow) {
            // First notify about the new view
            iframeRef.current.contentWindow.postMessage({
              type: 'CAMERA_VIEW_ADDED',
              view: newView
            }, '*');
            
            // Then send updated list
            setTimeout(() => {
              iframeRef.current?.contentWindow?.postMessage({
                type: 'SET_CAMERA_VIEWS',
                views: [...cameraViews, newView]
              }, '*');
            }, 100);
          }

        } catch (error) {
          console.error('Error saving camera view:', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to save camera view',
            icon: 'error'
          });
        }
      } else if (event.data.type === 'CAMERA_VIEW_DELETED') {
        setCameraViews(prev => prev.filter(view => view._id !== event.data.viewId));
      } else if (event.data.type === 'CAMERA_VIEW_ACTIVATED') {
        // Handle view activation if needed
        console.log('Camera view activated:', event.data.viewId);
      } else if (event.data.type === 'CURRENT_CAMERA_STATE') {
        const { state } = event.data;
        console.log('Received CURRENT_CAMERA_STATE:', state);
        
        // We only want to use this state when explicitly adding a new camera view
        // NOT for updating existing views
        if (isAddingCameraView) {
          console.log('isAddingCameraView is true, will use this camera state for the new view');
          // We don't update any state here - we'll handle this in the handleAddCameraView promise
        } else {
          console.log('Ignoring CURRENT_CAMERA_STATE as we are not adding a new view');
        }
      } else if (event.data.type === 'MARKUP_DELETED') {
        console.log('Markup deleted confirmation received:', event.data._id);
        // Update local state if needed
        setMarkups(prevMarkups => prevMarkups.filter(m => m._id !== event.data._id));
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [initialEmbedCode, cameraViews, currentCameraMode, isAddingCameraView]); // Add currentCameraMode and isAddingCameraView to dependencies

  const handleSaveMarkup = async (markup: Markup) => {
    if (!markup) return;
    
    // Get temp values
    const tempValues = tempEditValues[markup._id] || {
      title: markup.title || '',
      description: markup.description || ''
    };

    try {
      const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
      if (!srcMatch) return;

      const srcValue = srcMatch[1];
      const splittedSrc = srcValue.split("/");
      const urn = splittedSrc[7];

      const tokenService = new GetSecondaryTokenService();
      const token = await tokenService.GetByStringAsync(jsonLoginInfo.uid);

      // Get current position from positionUpdates if available
      const currentPos = positionUpdates[markup._id] || {
        x: markup.posX,
        y: markup.posY,
        z: markup.posZ
      };

      // Use temp values for title and description
      const backendMarkup = {
        _id: markup._id,
        title: tempValues.title,
        description: tempValues.description,
        posX: currentPos.x,
        posY: currentPos.y,
        posZ: currentPos.z,
        cameraMode: markup.cameraMode || 'perspective',
        position: markup.position,
        target: markup.target,
        up: markup.up
      };

      // Determine if this is a new markup or an update
      const isNewMarkup = markup.isTemp;
      const endpoint = isNewMarkup 
        ? `${baseUrl}objects/dataannotation?urn=${urn}`
        : `${baseUrl}objects/dataannotations?urn=${urn}`;

      const response = await fetch(endpoint, {
        method: isNewMarkup ? 'POST' : 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(isNewMarkup ? backendMarkup : [backendMarkup])
      });

      if (!response.ok) {
        throw new Error('Failed to save markup');
      }

      // Clear temp values after successful save
      setTempEditValues(prev => {
        const updated = { ...prev };
        delete updated[markup._id];
        return updated;
      });

      // Clear states after successful save
      setEditedMarkups(prev => {
        const newState = { ...prev };
        delete newState[markup._id];
        return newState;
      });

      // Update the markup with saved values and exit edit mode
      setMarkups(prevMarkups => 
        prevMarkups.map(m => {
          if (m._id === markup._id) {
            // Save current values as original values
            return { 
              ...m, 
              isTemp: false, 
              isEditing: false,
              // Apply temp values to markup
              title: tempValues.title,
              description: tempValues.description,
              // Update original values
              originalTitle: tempValues.title,
              originalDescription: tempValues.description,
              originalPosition: {
                posX: currentPos.x,
                posY: currentPos.y,
                posZ: currentPos.z,
                position: { ...m.position },
                target: { ...m.target },
                up: { ...m.up },
                cameraMode: m.cameraMode
              }
            };
          }
          return m;
        })
      );

      setPositionUpdates(prev => {
        const newUpdates = { ...prev };
        delete newUpdates[markup._id];
        return newUpdates;
      });

      // Send update to iframe
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage({
          type: 'SAVE_MARKUP',
          markup: {
            _id: markup._id,
            title: tempValues.title,
            description: tempValues.description,
            posX: currentPos.x,
            posY: currentPos.y,
            posZ: currentPos.z,
            position: markup.position,
            target: markup.target,
            up: markup.up,
            cameraMode: markup.cameraMode
          }
        }, '*');
      }

      // Show success message
      Swal.fire({
        title: 'Success',
        text: 'Markup saved successfully',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });

    } catch (error) {
      console.error('Error saving markup:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to save markup',
        icon: 'error'
      });
    }
  };

  // Add this helper function to check if there are text changes
  const hasTextChanges = (markup: Markup) => {
    // Check for title or description changes
    const textChanged = (markup.originalTitle !== undefined && markup.title !== markup.originalTitle) ||
           (markup.originalDescription !== undefined && markup.description !== markup.originalDescription);
    
    // Check for position changes
    const positionChanged = !!positionUpdates[markup._id];
    
    return textChanged || positionChanged;
  };

  // Add this function to handle canceling markup edit
  const handleCancelEditMarkup = (markup: Markup) => {
    console.log('Canceling edit for markup:', markup._id);
    
    // Just exit edit mode without applying temp changes
    setMarkups(prev => prev.map(m => 
      m._id === markup._id 
        ? {...m, isEditing: false} 
        : m
    ));
    
    // Clear this markup from editedMarkups 
    setEditedMarkups(prev => {
      const updated = { ...prev };
      delete updated[markup._id];
      return updated;
    });
    
    // Reset temp values
    setTempEditValues(prev => {
      const updated = { ...prev };
      delete updated[markup._id];
      return updated;
    });
  };

  const handleUndoMarkup = (markup: Markup) => {
    console.log('Undoing changes for markup:', markup);
    
    // Reset to original values
    setMarkups(prevMarkups => {
      return prevMarkups.map(m => {
        if (m._id === markup._id) {
          const restoredMarkup = {
            ...m,
            title: m.originalTitle || m.title,
            description: m.originalDescription || m.description,
            isEditing: false
          };
          
          // Restore original position if it exists
          if (m.originalPosition) {
            restoredMarkup.posX = m.originalPosition.posX;
            restoredMarkup.posY = m.originalPosition.posY;
            restoredMarkup.posZ = m.originalPosition.posZ;
            restoredMarkup.position = m.originalPosition.position;
            restoredMarkup.target = m.originalPosition.target;
            restoredMarkup.up = m.originalPosition.up;
            restoredMarkup.cameraMode = m.originalPosition.cameraMode;
          }
          
          // Send updated markup to iframe
          if (iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage({
              type: 'SAVE_MARKUP',
              markup: restoredMarkup
            }, '*');
          }
          
          return restoredMarkup;
        }
        return m;
      });
    });
    
    // Clear this markup from editedMarkups
    setEditedMarkups(prev => {
      const updated = { ...prev };
      delete updated[markup._id];
      return updated;
    });
    
    // Clear position updates for this markup
    setPositionUpdates(prev => {
      const updated = { ...prev };
      delete updated[markup._id];
      return updated;
    });
    
    // Also clear temp values
    setTempEditValues(prev => {
      const updated = { ...prev };
      delete updated[markup._id];
      return updated;
    });
  };

  // Add CSS class for active markup
  const getMarkupClassName = (_id: string) => {
    const baseClasses = "bg-white p-3 rounded-lg border border-gray-200 hover:border-indigo-300 transition-colors";
    return `${baseClasses} ${activeMarkupId === _id ? 'highlight-update' : ''}`;
  };

  // Add this function to handle markup deletion
  const handleDeleteMarkup = async (_id: string) => {
    try {
      const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
      if (!srcMatch) {
        console.error('Source URL not found in embed code');
        return;
      }

      const srcValue = srcMatch[1];
      const splittedSrc = srcValue.split("/");
      const urn = splittedSrc[7];

      // Find the markup to get both IDs
      const markup = markups.find(m => m._id === _id);
      if (!markup) {
        console.error('Markup not found');
        return;
      }

      // Use _id if available, otherwise use id
      const idToDelete = markup._id;

      // First send message to iframe to remove the markup visually
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage({
          type: 'DELETE_MARKUP',
          markupId: markup._id,
          isTemp: markup.isTemp // Add isTemp flag to handle temporary markups differently
        }, '*');
      }

      // Only proceed with backend deletion if it's not a temporary markup
      if (!markup.isTemp) {
        // Get the secondary token
        const tokenService = new GetSecondaryTokenService();
        const token = await tokenService.GetByStringAsync(jsonLoginInfo.uid);

        // Delete from backend using the appropriate ID
        const response = await fetch(`${baseUrl}objects/dataannotation?urn=${urn}&dataAnnotationId=${idToDelete}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to delete markup');
        }
      }

      // Update local state - only remove the specific markup
      setMarkups(prevMarkups => prevMarkups.filter(m => m._id !== _id));
      
      // Ensure markup deletion doesn't affect hasUnsavedChanges state
      // Only reset if all changes are markup-related
      if (changes.every(c => c.property.includes('Markup') || c.property.includes('markup'))) {
        setHasUnsavedChanges(false);
      }
      
      // Show success notification
      Swal.fire({
        title: 'Success',
        text: 'Markup deleted successfully',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      });
    } catch (error) {
      console.error('Error deleting markup:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to delete markup',
        icon: 'error'
      });
    }
  };

  // Add CSS for animations
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes highlightUpdate {
        0% { 
          background-color: rgba(59, 130, 246, 0.1);
          transform: scale(1);
        }
        50% { 
          background-color: rgba(59, 130, 246, 0.2);
          transform: scale(1.02);
        }
        100% { 
          background-color: rgba(59, 130, 246, 0);
          transform: scale(1);
        }
      }

      .highlight-update {
        animation: highlightUpdate 2s ease-in-out;
        border-color: #3B82F6;
      }

      .position-value {
        transition: all 0.3s ease;
        padding: 2px 4px;
        border-radius: 4px;
      }

      .highlight-update .position-value {
        background-color: rgba(99, 102, 241, 0.2);
        color: #4F46E5;
        font-weight: bold;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Update handleMarkupChange to track if there are actual changes
  const handleMarkupChange = (_id: string, field: 'title' | 'description', value: string) => {
    console.log(`Updating temp ${field} for markup ${_id} to: "${value}"`);
    
    // Update temp values first
    setTempEditValues(prev => ({
      ...prev,
      [_id]: {
        ...prev[_id],
        [field]: value
      }
    }));
  };

  // Add function to handle adding new camera view
  const handleAddCameraView = async () => {
    if (!featureAvailability.cameraAnimations) {
      Swal.fire({
        title: 'Feature Not Available',
        text: 'Camera animations are not available in your current plan.',
        icon: 'warning'
      });
      return;
    }
    if (cameraViews.length >= 10) {
      Swal.fire({
        title: 'Maximum Views Reached',
        text: 'You can only create up to 10 camera views.',
        icon: 'warning',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    // Set this flag to true before requesting camera state
    setIsAddingCameraView(true);

    try {
      // Clear any previously stored camera state
      console.log('Requesting current camera state for new view');
      
      // Get fresh camera state directly from iframe
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage({
          type: 'GET_CURRENT_CAMERA_STATE',
          timestamp: Date.now() // Add timestamp to ensure fresh response
        }, '*');
      }

      // Wait for the response with a timeout
      const cameraState = await new Promise<any>((resolve, reject) => {
        const handleCameraStateMessage = (event: MessageEvent) => {
          if (event.data.type === 'CURRENT_CAMERA_STATE') {
            window.removeEventListener('message', handleCameraStateMessage);
            console.log('Received camera state for new view:', event.data.state);
            resolve(event.data.state);
          }
        };
        
        window.addEventListener('message', handleCameraStateMessage);
        
        // Set timeout to prevent hanging if no response
        setTimeout(() => {
          window.removeEventListener('message', handleCameraStateMessage);
          reject(new Error('Timeout waiting for camera state'));
        }, 5000);
      });

      if (!cameraState) {
        throw new Error('Failed to get camera state');
      }

      const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
      if (!srcMatch) return;

      const srcValue = srcMatch[1];
      const splittedSrc = srcValue.split("/");
      const urn = splittedSrc[7];

      const tokenService = new GetSecondaryTokenService();
      const token = await tokenService.GetByStringAsync(jsonLoginInfo.uid);

      // Generate MongoDB compatible ID
      const newId = new ObjectID().toHexString();

      console.log('Creating new camera view with coordinates:', {
        position: cameraState.position,
        target: cameraState.target
      });

      // Create new view with fresh camera state data
      const newView = {
        _id: newId,
        name: `View ${cameraViews.length + 1}`,
        position: {
          x: cameraState.position.x,
          y: cameraState.position.y,
          z: cameraState.position.z
        },
        target: {
          x: cameraState.target.x,
          y: cameraState.target.y,
          z: cameraState.target.z
        },
        up: {
          x: cameraState.up.x,
          y: cameraState.up.y,
          z: cameraState.up.z
        },
        cameraMode: cameraState.cameraMode
      };

      // Prepare backend format
      const backendView = {
        _id: newId,
        name: newView.name,
        position: {
          posX: newView.position.x,
          posY: newView.position.y,
          posZ: newView.position.z
        },
        target: {
          posX: newView.target.x,
          posY: newView.target.y,
          posZ: newView.target.z
        },
        up: {
          posX: newView.up.x,
          posY: newView.up.y,
          posZ: newView.up.z
        },
        cameraMode: newView.cameraMode
      };

      console.log('Saving new camera view to backend:', backendView);

      // First save to backend
      const response = await fetch(`${baseUrl}objects/cameraanimation?urn=${urn}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(backendView)
      });

      if (!response.ok) {
        throw new Error('Failed to save camera view');
      }

      // After successful save, update local state and notify iframe
      setCameraViews(prev => {
        const updatedViews = [...prev, newView];
        
        console.log('Adding new camera view to collection:', {
          newView,
          totalViews: updatedViews.length
        });
        
        // Send to iframe
        if (iframeRef.current?.contentWindow) {
          // First notify about the new view
          iframeRef.current.contentWindow.postMessage({
            type: 'CAMERA_VIEW_ADDED',
            view: newView
          }, '*');
          
          // Then send updated list
          setTimeout(() => {
            iframeRef.current?.contentWindow?.postMessage({
              type: 'SET_CAMERA_VIEWS',
              views: updatedViews
            }, '*');
          }, 100);
        }
        return updatedViews;
      });

      // Turn off the flag after successful operation
      setIsAddingCameraView(false);

    } catch (error) {
      console.error('Error adding camera view:', error);
      setIsAddingCameraView(false);
      Swal.fire({
        title: 'Error',
        text: 'Failed to add camera view',
        icon: 'error'
      });
    }
  };

  // Add function to handle camera view name change
  const handleViewNameChange = async (viewId: string, newName: string) => {
    try {
      const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
      if (!srcMatch) return;

      const srcValue = srcMatch[1];
      const splittedSrc = srcValue.split("/");
      const urn = splittedSrc[7];

      const tokenService = new GetSecondaryTokenService();
      const token = await tokenService.GetByStringAsync(jsonLoginInfo.uid);

      // Update local state first for immediate feedback
      setCameraViews(prev => prev.map(view => 
        view._id === viewId 
          ? { ...view, name: newName }
          : view
      ));

      // Prepare the updated view
      const updatedView = cameraViews.find(v => v._id === viewId);
      if (!updatedView) return;

      // Send update to backend
      const response = await fetch(`${baseUrl}objects/cameraanimations?urn=${urn}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify([{
          _id: viewId,
          name: newName,
          position: {
            posX: updatedView.position.x,
            posY: updatedView.position.y,
            posZ: updatedView.position.z
          },
          target: {
            posX: updatedView.target.x,
            posY: updatedView.target.y,
            posZ: updatedView.target.z
          }
        }])
      });

      if (!response.ok) {
        throw new Error('Failed to update camera view');
      }

      setEditingViewId(null);
      setHasUnsavedChanges(true);

    } catch (error) {
      console.error('Error updating camera view:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to update camera view',
        icon: 'error'
      });
    }
  };

  // Add function to handle view deletion
  const handleDeleteView = async (viewId: string) => {
    try {
        const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
        if (!srcMatch) return;

        const srcValue = srcMatch[1];
        const splittedSrc = srcValue.split("/");
        const urn = splittedSrc[7];

        const result = await Swal.fire({
            title: 'Delete Camera View',
            text: 'Are you sure you want to delete this camera view?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#dc2626'
        });

        if (result.isConfirmed) {
            const tokenService = new GetSecondaryTokenService();
            const token = await tokenService.GetByStringAsync(jsonLoginInfo.uid);

            // Delete from backend first
            const response = await fetch(`${baseUrl}objects/cameraanimations?urn=${urn}&cameraAnimationId=${viewId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete camera view');
            }

            // After successful deletion from backend, update local state
            const updatedViews = cameraViews.filter(view => view._id !== viewId);
            setCameraViews(updatedViews);

            // Notify iframe about the deletion
            if (iframeRef.current?.contentWindow) {
                // Send both messages immediately
                iframeRef.current.contentWindow.postMessage({
                    type: 'CAMERA_VIEW_DELETED',
                    viewId: viewId
                }, '*');

                iframeRef.current.contentWindow.postMessage({
                    type: 'SET_CAMERA_VIEWS',
                    views: updatedViews
                }, '*');
            }
        }
    } catch (error) {
        console.error('Error deleting camera view:', error);
        Swal.fire({
            title: 'Error',
            text: 'Failed to delete camera view',
            icon: 'error'
        });
    }
};

  // Update the useEffect for fetching camera views
  useEffect(() => {
    const fetchCameraViews = async () => {
        try {
            const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
            if (!srcMatch) return;

            const srcValue = srcMatch[1];
            const splittedSrc = srcValue.split("/");
            const urn = splittedSrc[7];

            const tokenService = new GetSecondaryTokenService();
            const token = await tokenService.GetByStringAsync(jsonLoginInfo.uid);

            console.log('Fetching camera views for URN:', urn);

            const response = await fetch(`${baseUrl}objects/cameraanimations?urn=${urn}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch camera views');
            }

            const views = await response.json();
            console.log('Raw camera views from backend:', views);
            
            // Convert backend format to frontend format
            const formattedViews = views.map((view: any, index: number) => {
                console.log(`Processing view ${index}:`, view);
                return {
                    _id: view._id,
                    name: view.name || `View ${index + 1}`,
                    position: {
                        x: Number(view.position?.posX || 0),
                        y: Number(view.position?.posY || 0),
                        z: Number(view.position?.posZ || 0)
                    },
                    target: {
                        x: Number(view.target?.posX || 0),
                        y: Number(view.target?.posY || 0),
                        z: Number(view.target?.posZ || 0)
                    },
                    up: {
                        x: Number(view.up?.posX || 0),
                        y: Number(view.up?.posY || 1),
                        z: Number(view.up?.posZ || 0)
                    },
                    cameraMode: view.cameraMode // Preserve original camera mode
                };
            });

            console.log('Formatted camera views:', formattedViews);
            setCameraViews(formattedViews);

            // Send to iframe if it's ready
            if (iframeRef.current?.contentWindow) {
                console.log('Sending initial camera views to iframe');
                sendCameraViewsToIframe(formattedViews);
            }

        } catch (error) {
            console.error('Error fetching camera views:', error);
        }
    };

    fetchCameraViews();
}, [initialEmbedCode, jsonLoginInfo.uid]);

  // Update the sendCameraViewsToIframe function
  const sendCameraViewsToIframe = (views: CameraView[]) => {
    if (!iframeRef.current?.contentWindow || !views.length) return;
    
    console.log('Preparing to send camera views to iframe:', 
        views.map(v => ({
            _id: v._id,
            name: v.name,
            cameraMode: v.cameraMode
        }))
    );

    function sendViews() {
        // Send exact views without modifying camera mode
        iframeRef.current?.contentWindow?.postMessage({
            type: 'SET_CAMERA_VIEWS',
            views: views
        }, '*');
        console.log('Camera views sent to iframe');
    }

    // First send a ping to check if the viewer is ready
    iframeRef.current.contentWindow.postMessage({ type: 'PING' }, '*');

    // Set up a one-time listener for the pong response
    function handlePong(event: MessageEvent) {
        if (event.data.type === 'PONG') {
            console.log('Received PONG from viewer, sending camera views');
            setTimeout(sendViews, 100);
            window.removeEventListener('message', handlePong);
        }
    }

    window.addEventListener('message', handlePong);
};

  // Update the useEffect for handling iframe load
  useEffect(() => {
    const handleIframeLoad = () => {
      console.log('Iframe loaded, camera views:', cameraViews);
      if (cameraViews.length > 0) {
        // When iframe loads, try to send camera views
        sendCameraViewsToIframe(cameraViews);
      }
      setIframeError(false);
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeLoad);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
      }
    };
  }, [cameraViews]);

  const showFeatureWarning = () => {
    const warningDiv = document.createElement('div');
    warningDiv.className = 'fixed top-4 right-4 bg-white border border-gray-200 rounded-lg shadow-lg p-4 max-w-sm animate-fade-in z-50';
    const warningContent = document.createElement('div');
    warningContent.className = 'flex items-start gap-3';
    warningContent.innerHTML = `
      <div class="flex-shrink-0">
        <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
        </svg>
      </div>
      <div class="flex-1">
        <p class="text-sm font-medium text-gray-900">Custom Branding Not Available</p>
        <p class="mt-1 text-sm text-gray-500">This feature requires a premium subscription.</p>
        <button 
          class="mt-3 w-full inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 rounded-md transition-all duration-150 ease-in-out"
        >
          Upgrade Your Plan
        </button>
      </div>
      <button class="flex-shrink-0 text-gray-400 hover:text-gray-500">
        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
        </svg>
      </button>
    `;
    warningDiv.appendChild(warningContent);
    
    // Add event listeners
    const upgradeButton = warningContent.querySelector('button:not(.flex-shrink-0)');
    const closeButton = warningContent.querySelector('button.flex-shrink-0');
    
    if (upgradeButton) {
      upgradeButton.addEventListener('click', () => {
        setIsSubscriptionModalOpen(true);
        warningDiv.remove();
      });
    }
    
    if (closeButton) {
      closeButton.addEventListener('click', () => {
        warningDiv.classList.add('animate-fade-out');
        setTimeout(() => warningDiv.remove(), 300);
      });
    }
    
    document.body.appendChild(warningDiv);
    setTimeout(() => {
      warningDiv.classList.add('animate-fade-out');
      setTimeout(() => warningDiv.remove(), 300);
    }, 5000);
  };

  return (
    <div className="h-screen flex flex-col bg-gray-100">
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white" />
        </div>
      )}

      {tooltipVisible && (
        <div
          ref={tooltipRef}
          className="fixed bg-gray-900 text-white p-2 rounded text-sm z-50 transition-opacity duration-150"
          style={{
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
            width: '200px',
            opacity: tooltipVisible ? 1 : 0,
            pointerEvents: 'none'
          }}
        >
          {tooltipContent}
        </div>
      )}

      <div className="flex flex-col md:flex-row flex-1 overflow-hidden">
        {/* Left side - Customization Panel */}
        <div className="w-full md:w-80 bg-white overflow-y-auto border-r border-gray-100">
          <div className="p-3">
            <div className="flex items-center justify-between mb-3">
              <h2 className="text-base font-medium text-gray-700">Customization</h2>
              <button
                onClick={() => setIsEditingMode(!isEditingMode)}
                className={`px-2 py-1 text-xs rounded transition-colors ${
                  isEditingMode 
                    ? 'bg-gray-100 text-gray-600' 
                    : 'bg-blue-50 text-blue-600'
                }`}
              >
                {isEditingMode ? 'Exit Edit Mode' : 'Edit Mode'}
              </button>
            </div>

            {/* Quick Actions */}
            <div className="grid grid-cols-5 gap-1 mb-3 bg-gray-50 p-1 rounded">
              <button
                onClick={handleSavingSettings}
                className={`flex items-center justify-center p-1.5 rounded text-xs transition-colors ${
                  hasUnsavedChanges 
                    ? 'bg-blue-50 text-blue-600' 
                    : 'bg-white text-gray-400'
                }`}
                title="Save Changes"
              >
                <Save className="w-3 h-3" />
              </button>
              <button
                onClick={handleUpdateIframe}
                className="flex items-center justify-center p-1.5 bg-white text-gray-400 hover:text-gray-600 rounded text-xs transition-colors"
                title="Refresh View"
              >
                <Refresh className="w-3 h-3" />
              </button>
              <button
                onClick={extractContent}
                className="flex items-center justify-center p-1.5 bg-white text-gray-400 hover:text-gray-600 rounded text-xs transition-colors"
                title="Copy SharePoint Urn"
              >
                <Share className="w-3 h-3" />
              </button>
              <button
                onClick={handleCopy}
                className="flex items-center justify-center p-1.5 bg-white text-gray-400 hover:text-gray-600 rounded text-xs transition-colors"
                title="Copy Embed Code"
              >
                <ContentCopy className="w-3 h-3" />
              </button>
              {isQrEnabled && qrCodeData && (
                <button
                  onClick={() => {
                    if (isQrEnabled && qrCodeData) {
                      setIsQrModalOpen(true);
                    }
                  }}
                  className={`flex items-center justify-center p-1.5 ${
                    isQrEnabled && qrCodeData 
                      ? 'bg-white text-gray-400 hover:text-gray-600' 
                      : 'bg-gray-100 text-gray-300 cursor-not-allowed'
                  } rounded text-xs transition-colors`}
                  disabled={!isQrEnabled || !qrCodeData}
                  title={isQrEnabled && qrCodeData ? "Show QR Code" : "QR Code not available"}
                >
                  <QRCodeSVG value={qrCodeData || 'disabled'} size={12} />
                </button>
              )}
            </div>

            {/* Tab Buttons and Undo Changes */}
            <div className="space-y-2 mb-4">
              <div className="flex justify-between bg-gray-50 p-1 rounded">
                {['website', 'size', 'appearance', 'tools', 'markup'].map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    className={`flex flex-col items-center justify-center w-14 h-14 rounded transition-all ${
                      activeTab === tab 
                        ? 'bg-white text-blue-600 shadow-sm' 
                        : 'text-gray-400 hover:text-gray-600'
                    }`}
                    title={tooltips[tab as keyof typeof tooltips]}
                  >
                    {tab === 'website' && <Language className="w-4 h-4 mb-1" />}
                    {tab === 'size' && <AspectRatio className="w-4 h-4 mb-1" />}
                    {tab === 'appearance' && <Palette className="w-4 h-4 mb-1" />}
                    {tab === 'tools' && <Build className="w-4 h-4 mb-1" />}
                    {tab === 'markup' && <PushPin className="w-4 h-4 mb-1" />}
                    <span className="text-[10px]">{tab.charAt(0).toUpperCase() + tab.slice(1)}</span>
                  </button>
                ))}
              </div>
              
              {hasUnsavedChanges && (
                <button
                  onClick={handleUndoAllChanges}
                  className="w-full flex items-center justify-center gap-1 bg-gray-50 text-gray-600 p-2 rounded text-sm hover:bg-gray-100"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                  </svg>
                  <span>Undo All Changes</span>
                </button>
              )}
            </div>

            {/* Menu Container */}
            <div className="space-y-2">
              {/* Website Settings */}
              {activeTab === 'website' && (
                <div className="space-y-2">
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex items-center gap-1 mb-1.5">
                      <Language className="w-3.5 h-3.5 text-gray-400" />
                      <label className="text-sm text-gray-600">Website URL</label>
                    </div>
                    <input
                      className="w-full p-1.5 bg-white border border-gray-200 rounded text-sm"
                      placeholder="Enter website URL"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      disabled={!isEditingMode}
                    />
                  </div>
                  
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex items-center justify-between mb-1.5">
                      <div className="flex items-center gap-1">
                        <ContentCopy className="w-3.5 h-3.5 text-gray-400" />
                        <label className="text-sm text-gray-600">Embed Code</label>
                      </div>
                      <button
                        onClick={handleCopy}
                        className="px-2 py-0.5 text-xs bg-white text-gray-500 rounded hover:text-gray-700"
                      >
                        Copy
                      </button>
                    </div>
                    <textarea
                      className="w-full h-20 p-1.5 bg-gray-900 text-gray-100 font-mono text-xs rounded resize-none"
                      value={newEmbedCode}
                      readOnly
                    />
                  </div>
                </div>
              )}

              {/* Size Settings */}
              {activeTab === 'size' && (
                <div className="grid grid-cols-2 gap-2">
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex items-center gap-1 mb-1.5">
                      <AspectRatio className="w-3.5 h-3.5 text-gray-400" />
                      <label className="text-sm text-gray-600">Width</label>
                    </div>
                    <input
                      type="text"
                      value={width}
                      onChange={(e) => handleWidthChange(e.target.value)}
                      className="w-full p-1.5 bg-white border border-gray-200 rounded text-sm"
                      placeholder="750px, 100%, 50vw"
                      disabled={!isEditingMode}
                    />
                  </div>
                  
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex items-center gap-1 mb-1.5">
                      <AspectRatio className="w-3.5 h-3.5 text-gray-400" />
                      <label className="text-sm text-gray-600">Height</label>
                    </div>
                    <input
                      type="text"
                      value={height}
                      onChange={(e) => handleHeightChange(e.target.value)}
                      className="w-full p-1.5 bg-white border border-gray-200 rounded text-sm"
                      placeholder="500px, 80vh"
                      disabled={!isEditingMode}
                    />
                  </div>
                </div>
              )}

              {/* Appearance Settings */}
              {activeTab === 'appearance' && (
                <div className="space-y-2">
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex items-center gap-1 mb-1.5">
                      <ColorLens className="w-3.5 h-3.5 text-gray-400" />
                      <label className="text-sm text-gray-600">Light Preset</label>
                    </div>
                    <select
                      value={lightPreset}
                      onChange={handleLightPresetChange}
                      className="w-full p-1.5 bg-white border border-gray-200 rounded text-sm"
                      disabled={!isEditingMode}
                    >
                      {Object.keys(lightPresetMap).map((preset) => (
                        <option key={preset} value={preset}>{preset}</option>
                      ))}
                    </select>
                  </div>

                  <div className={`bg-gray-50 p-2 rounded ${
                    !featureAvailability.customBackground ? 'opacity-50 cursor-not-allowed' : ''
                  }`}>
                    <div className="flex items-center gap-1 mb-1.5">
                      <Palette className="w-3.5 h-3.5 text-gray-400" />
                      <label className="text-sm text-gray-600">Background</label>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="color"
                        value={backgroundColor}
                        onChange={handleBackgroundColorChange}
                        className="w-8 h-8 rounded cursor-pointer border-0"
                        disabled={!isEditingMode || !featureAvailability.customBackground}
                      />
                      <input
                        type="text"
                        value={backgroundColor.toUpperCase()}
                        onChange={(e) => handleBackgroundColorChange({ target: { value: e.target.value } } as any)}
                        className="flex-1 p-1.5 border border-gray-200 rounded text-sm bg-white"
                        disabled={!isEditingMode || !featureAvailability.customBackground}
                      />
                      <button
                        className="px-2 py-1 text-xs bg-blue-50 text-blue-600 rounded hover:bg-blue-100 transition-colors"
                        onClick={() => {
                          if (!featureAvailability.customBackground) {
                            Swal.fire({
                              title: 'Feature Not Available',
                              text: 'Custom background is not available in your current plan.',
                              icon: 'warning'
                            });
                            return;
                          }
                          updateProperty('backgroundColor', backgroundColor.replace('#', ''));
                          addChange('Background Color', backgroundColor);
                        }}
                        disabled={!isEditingMode || !featureAvailability.customBackground}
                      >
                        Apply
                      </button>
                    </div>
                  </div>

                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex items-center gap-1 mb-1.5">
                      <Landscape className="w-3.5 h-3.5 text-gray-400" />
                      <label className="text-sm text-gray-600">Effects</label>
                    </div>
                    <div className="space-y-1">
                      <label className="flex items-center gap-2 p-1.5 bg-white rounded border border-gray-200">
                        <input
                          type="checkbox"
                          checked={groundShadow}
                          onChange={handleGroundShadowChange}
                          className="w-3 h-3 rounded text-blue-600"
                          disabled={!isEditingMode}
                        />
                        <span className="text-xs text-gray-600">Ground Shadow</span>
                      </label>
                      <label className="flex items-center gap-2 p-1.5 bg-white rounded border border-gray-200">
                        <input
                          type="checkbox"
                          checked={groundReflection}
                          onChange={handleGroundReflectionChange}
                          className="w-3 h-3 rounded text-blue-600"
                          disabled={!isEditingMode}
                        />
                        <span className="text-xs text-gray-600">Ground Reflection</span>
                      </label>
                      <label className={`flex items-center gap-2 p-1.5 bg-white rounded border border-gray-200 ${
                        !featureAvailability.customBranding ? 'opacity-50 cursor-not-allowed' : ''
                      }`}>
                        <input
                          type="checkbox"
                          checked={isLogoShown}
                          onChange={handleLogoVisibilityChange}
                          className="w-3 h-3 rounded text-blue-600"
                          disabled={!isEditingMode || !featureAvailability.customBranding}
                        />
                        <span className="text-xs text-gray-600">Show Logo</span>
                      </label>
                      <label className={`flex items-center gap-2 p-1.5 bg-white rounded border border-gray-200 ${
                        !featureAvailability.watermarkRemoval ? 'opacity-50 cursor-not-allowed' : ''
                      }`}>
                        <input
                          type="checkbox"
                          checked={showWatermark}
                          onChange={handleWatermarkChange}
                          className="w-3 h-3 rounded text-blue-600"
                          disabled={!isEditingMode || !featureAvailability.watermarkRemoval}
                        />
                        <span className="text-xs text-gray-600">Remove Watermark</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* Tools Settings */}
              {activeTab === 'tools' && (
                <div className="space-y-2">
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex items-center gap-1 mb-2">
                      <Build className="w-3.5 h-3.5 text-gray-400" />
                      <label className="text-sm text-gray-600">Available Tools</label>
                    </div>
                    <div className="grid grid-cols-4 gap-1">
                      {toolIcons.map((tool, index) => (
                        <button
                          key={index}
                          className={`flex items-center justify-center p-2 rounded transition-all ${
                            !tool.active 
                              ? 'bg-white text-gray-600 border border-gray-200' 
                              : 'bg-gray-100 text-gray-400'
                          }`}
                          onClick={() => handleToggleIcon(index)}
                          title={tool.name}
                          disabled={!isEditingMode}
                        >
                          {tool.icon}
                          <span className="sr-only">{tool.name}</span>
                        </button>
                      ))}
                    </div>
                    {/* Hide Bottom Toolbar checkbox */}
                    <div className="mt-2">
                      <label className="flex items-center gap-2 p-1.5 bg-white rounded border border-gray-200">
                        <input
                          type="checkbox"
                          checked={hideBottomToolbar}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setHideBottomToolbar(isChecked);
                            updateProperty('hideBottomToolBar', isChecked.toString());
                            addChange('Bottom Toolbar', isChecked ? 'Hidden' : 'Visible');
                          }}
                          className="w-3 h-3 rounded text-blue-600"
                          disabled={!isEditingMode}
                        />
                        <span className="text-xs text-gray-600">Hide Bottom Toolbar</span>
                      </label>
                    </div>
                  </div>

                  {/* Camera Views Section */}
                  <div className={`bg-gray-50 p-2 rounded ${
                    !featureAvailability.cameraAnimations ? 'opacity-50 cursor-not-allowed' : ''
                  }`}>
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center gap-1">
                        <CameraAlt className="w-3.5 h-3.5 text-gray-400" />
                        <label className="text-sm text-gray-600">Camera Views</label>
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => setReorderingViews(!reorderingViews)}
                          className={`px-2 py-1 text-xs rounded transition-colors ${
                            reorderingViews
                              ? 'bg-gray-100 text-gray-600'
                              : 'bg-gray-50 text-gray-400 hover:text-gray-600'
                          } ${!isEditingMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                          title="Reorder Views"
                          disabled={!isEditingMode}
                        >
                          <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                          </svg>
                        </button>
                        <button
                          onClick={handleAddCameraView}
                          className={`px-2 py-1 text-xs rounded transition-colors ${
                            isAddingCameraView
                              ? 'bg-gray-100 text-gray-600'
                              : 'bg-blue-50 text-blue-600'
                          } ${!isEditingMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                          disabled={!isEditingMode || isAddingCameraView || !featureAvailability.cameraAnimations}
                        >
                          {isAddingCameraView ? 'Adding...' : 'Add View'}
                        </button>
                      </div>
                    </div>
                    
                    <div className="space-y-2">
                      {cameraViews.map((view, index) => (
                        <div
                          key={view._id}
                          className={`bg-white p-2 rounded border border-gray-200 text-xs ${
                            reorderingViews ? 'cursor-move' : ''
                          }`}
                          draggable={reorderingViews}
                          onDragStart={(e) => {
                            e.dataTransfer.setData('text/plain', index.toString());
                            e.currentTarget.classList.add('opacity-50');
                          }}
                          onDragEnd={(e) => {
                            e.currentTarget.classList.remove('opacity-50');
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                            e.currentTarget.classList.add('border-blue-300');
                          }}
                          onDragLeave={(e) => {
                            e.currentTarget.classList.remove('border-blue-300');
                          }}
                          onDrop={(e) => {
                            e.preventDefault();
                            e.currentTarget.classList.remove('border-blue-300');
                            const startIndex = parseInt(e.dataTransfer.getData('text/plain'));
                            const endIndex = index;
                            
                            if (startIndex !== endIndex) {
                              const newViews = Array.from(cameraViews);
                              const [removed] = newViews.splice(startIndex, 1);
                              newViews.splice(endIndex, 0, removed);
                              
                              setCameraViews(newViews);
                              
                              if (iframeRef.current?.contentWindow) {
                                iframeRef.current.contentWindow.postMessage({
                                  type: 'SET_CAMERA_VIEWS',
                                  views: newViews
                                }, '*');
                              }
                              
                              setHasUnsavedChanges(true);
                            }
                          }}
                        >
                          <div className="flex items-center justify-between gap-2">
                            <div className="flex-1 min-w-0">
                              {editingViewId === view._id ? (
                                <input
                                  type="text"
                                  value={view.name || `View ${index + 1}`}
                                  onChange={(e) => handleViewNameChange(view._id, e.target.value)}
                                  onBlur={() => setEditingViewId(null)}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      setEditingViewId(null);
                                    }
                                  }}
                                  className="w-full px-1 py-0.5 text-xs border border-blue-300 rounded"
                                  autoFocus
                                />
                              ) : (
                                <div
                                  className="flex items-center gap-2"
                                  onDoubleClick={() => isEditingMode && setEditingViewId(view._id)}
                                >
                                  {reorderingViews && (
                                    <svg className="w-3 h-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                    </svg>
                                  )}
                                  <span className="font-medium truncate">
                                    {view.name || `View ${index + 1}`}
                                  </span>
                                  <span className={`text-xs px-1.5 py-0.5 rounded ${
                                    view.cameraMode === 'orthographic' 
                                      ? 'bg-blue-50 text-blue-600' 
                                      : 'bg-purple-50 text-purple-600'
                                  }`}>
                                    {view.cameraMode || 'perspective'}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="flex items-center gap-1">
                              <div className="text-gray-400 text-[10px]">
                                {`(${view.position.x.toFixed(1)}, ${view.position.y.toFixed(1)}, ${view.position.z.toFixed(1)})`}
                              </div>
                              <button
                                onClick={() => handleDeleteView(view._id)}
                                className={`p-1 text-gray-400 hover:text-red-600 rounded ${!isEditingMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={!isEditingMode}
                              >
                                <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      
                      {cameraViews.length === 0 && (
                        <div className="text-center text-gray-400 text-xs py-2">
                          No camera views added
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* Markup Points */}
              {activeTab === 'markup' && (
                <div className={`bg-gray-50 p-2 rounded ${
                  !featureAvailability.dataAnnotations ? 'opacity-50 cursor-not-allowed' : ''
                }`}>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center gap-1">
                      <PushPin className="w-3.5 h-3.5 text-gray-400" />
                      <label className="text-sm text-gray-600">Markup Points</label>
                    </div>
                    <button
                      onClick={() => {
                        if (!featureAvailability.dataAnnotations) {
                          Swal.fire({
                            title: 'Feature Not Available',
                            text: 'Data annotations are not available in your current plan.',
                            icon: 'warning'
                          });
                          return;
                        }
                        setIsAddingMarkup(true);
                        if (iframeRef.current?.contentWindow) {
                          iframeRef.current.contentWindow.postMessage({
                            type: 'START_MARKUP_CREATION'
                          }, '*');
                        }
                      }}
                      className={`px-2 py-1 text-xs rounded transition-colors ${
                        isAddingMarkup
                          ? 'bg-gray-100 text-gray-600'
                          : 'bg-blue-50 text-blue-600'
                      }`}
                      disabled={!isEditingMode || isAddingMarkup || !featureAvailability.dataAnnotations}
                    >
                      {isAddingMarkup ? 'Adding...' : 'Add Markup'}
                    </button>
                  </div>

                  {/* Active Camera Mode Display */}
                  <div className="mb-3 p-2 bg-white rounded border border-gray-200">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <CameraAlt className="w-4 h-4 text-gray-400" />
                        <span className="text-xs text-gray-600">Active Camera Mode</span>
                      </div>
                      <span className={`px-2 py-1 rounded text-xs font-medium ${
                        currentCameraMode === 'orthographic' 
                          ? 'bg-blue-50 text-blue-600' 
                          : 'bg-purple-50 text-purple-600'
                      }`}>
                        {currentCameraMode}
                      </span>
                    </div>
                  </div>

                  <div className="space-y-2">
                    {markups.map((markup, index) => (
                      <div
                        key={markup._id}
                        className={`bg-white p-3 rounded border ${markup.isTemp ? 'border-blue-300' : 'border-gray-200'} hover:border-indigo-300 transition-colors`}
                      >
                        {markup.isTemp || markup.isEditing ? (
                          <div className="space-y-2">
                            <input
                              type="text"
                              value={tempEditValues[markup._id]?.title || ''}
                              onChange={(e) => handleMarkupChange(markup._id, 'title', e.target.value)}
                              className="w-full p-2 border rounded text-sm"
                              placeholder="Enter title"
                              autoFocus
                            />
                            <textarea
                              value={tempEditValues[markup._id]?.description || ''}
                              onChange={(e) => handleMarkupChange(markup._id, 'description', e.target.value)}
                              className="w-full p-2 border rounded text-sm"
                              placeholder="Enter a detailed description for this markup point"
                              rows={2}
                            />
                            <div className="flex justify-end gap-2">
                              {!markup.isTemp && (
                                <button
                                  onClick={() => handleCancelEditMarkup(markup)}
                                  className="px-2 py-1 text-xs bg-gray-100 text-gray-600 rounded hover:bg-gray-200 transition-colors"
                                >
                                  Cancel
                                </button>
                              )}
                              {markup.isTemp && (
                                <button
                                  onClick={() => {
                                    setMarkups(prevMarkups => prevMarkups.filter(m => m._id !== markup._id));
                                    if (iframeRef.current?.contentWindow) {
                                      iframeRef.current.contentWindow.postMessage({
                                        type: 'DELETE_MARKUP',
                                        markupId: markup._id
                                      }, '*');
                                    }
                                    // Also clean up temp values
                                    setTempEditValues(prev => {
                                      const updated = { ...prev };
                                      delete updated[markup._id];
                                      return updated;
                                    });
                                  }}
                                  className="px-2 py-1 text-xs bg-red-50 text-red-600 rounded hover:bg-red-100 transition-colors"
                                >
                                  Delete
                                </button>
                              )}
                              <button
                                onClick={() => handleSaveMarkup(markup)}
                                className={`px-2 py-1 text-xs rounded transition-colors ${
                                  (tempEditValues[markup._id]?.title || tempEditValues[markup._id]?.description || markup.isTemp)
                                    ? 'bg-blue-50 text-blue-600 hover:bg-blue-100' 
                                    : 'bg-gray-50 text-gray-400 cursor-not-allowed'
                                }`}
                                disabled={!tempEditValues[markup._id]?.title && !tempEditValues[markup._id]?.description && !markup.isTemp}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex items-center justify-between mb-1">
                              <h4 
                                className="text-sm font-medium text-gray-700 cursor-pointer"
                                onDoubleClick={() => handleDoubleClick(markup, 'title')}
                              >
                                {markup.title || `Point ${index + 1}`}
                              </h4>
                              <div className="flex gap-1">
                                <button
                                  onClick={() => handleDoubleClick(markup, 'title')}
                                  className={`p-1 text-gray-400 hover:text-blue-600 rounded ${!isEditingMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                                  disabled={!isEditingMode}
                                >
                                  <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                  </svg>
                                </button>
                                <button
                                  onClick={() => handleDeleteMarkup(markup._id)}
                                  className={`p-1 text-gray-400 hover:text-red-600 rounded ${!isEditingMode ? 'opacity-50 cursor-not-allowed' : ''}`}
                                  disabled={!isEditingMode}
                                >
                                  <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <p 
                              className="text-xs text-gray-500 mb-2 cursor-pointer"
                              onDoubleClick={() => handleDoubleClick(markup, 'description')}
                            >
                              {(markup.description && markup.description.trim() !== '') 
                                ? markup.description 
                                : 'Double-click to add a description'}
                            </p>
                            {editedMarkups[markup._id] && (
                              <div className="flex justify-end gap-2 mb-2">
                                <button
                                  onClick={() => handleUndoMarkup(markup)}
                                  className="px-2 py-1 text-xs bg-gray-100 text-gray-600 rounded hover:bg-gray-200 transition-colors"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() => handleSaveMarkup(markup)}
                                  className="px-2 py-1 text-xs bg-blue-50 text-blue-600 rounded hover:bg-blue-100 transition-colors"
                                >
                                  Save
                                </button>
                              </div>
                            )}
                            <div className="pt-2 border-t border-gray-100">
                              <div className="grid grid-cols-4 gap-2">
                                <div className="text-xs text-gray-500">
                                  <span className="font-medium">X:</span> {Number(positionUpdates[markup._id]?.x || markup.posX).toFixed(2)}
                                </div>
                                <div className="text-xs text-gray-500">
                                  <span className="font-medium">Y:</span> {Number(positionUpdates[markup._id]?.y || markup.posY).toFixed(2)}
                                </div>
                                <div className="text-xs text-gray-500">
                                  <span className="font-medium">Z:</span> {Number(positionUpdates[markup._id]?.z || markup.posZ).toFixed(2)}
                                </div>
                                <div className="text-xs text-gray-500">
                                  <span className="font-medium">Mode:</span> {markup.cameraMode || 'perspective'}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Main iframe container */}
        <div className="flex-1 p-2 relative min-h-[500px] overflow-y-auto bg-gray-50">
          <div className="h-full flex items-center justify-center">
            {showIframe ? (
              <div className="relative">
                {isEditingMode && (
                  <>
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-xs text-gray-500 bg-white px-1.5 rounded shadow">
                      {width}
                    </div>
                    <div className="absolute -left-4 top-1/2 transform -translate-y-1/2 rotate-[-90deg] text-xs text-gray-500 bg-white px-1.5 rounded shadow">
                      {height}
                    </div>
                  </>
                )}
                {isCheckingHealth ? (
                  <div style={{ width, height }} className="flex items-center justify-center bg-gray-100 rounded-xl">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500" />
                  </div>
                ) : isServerHealthy ? (
                  <iframe
                    ref={iframeRef}
                    src={newEmbedCode.match(/src=['"]([^'"]*)['"]/)?.[1] || website}
                    style={{ 
                      width: width,
                      height: height,
                      maxWidth: '100%',
                      maxHeight: '100%'
                    }}
                    loading="eager"
                    className={`transition-all duration-300 ${
                      isEditingMode 
                        ? 'border border-dashed border-gray-300 rounded' 
                        : 'border-0'
                    }`}
                  />
                ) : (
                  <div style={{ width, height }} className="flex items-center justify-center bg-gray-100 rounded-xl">
                    <div className="bg-white p-6 rounded-xl shadow-lg text-center max-w-md mx-4 border border-gray-200">
                      <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-red-50 rounded-full">
                        <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <h3 className="text-xl font-bold text-gray-800 mb-3">
                        VEEWER Unavailable
                      </h3>
                      <p className="text-gray-600 mb-6">
                        {errorType.message}
                      </p>
                      <div className="flex flex-col space-y-3">
                        <button
                          onClick={() => {
                            setIsCheckingHealth(true);
                            setIsServerHealthy(false);
                            axios.get(`${baseUrl}health`)
                              .then(response => {
                                if (response.data.status === "Healthy") {
                                  setIsServerHealthy(true);
                                setIframeError(false);
                                setErrorType({ code: 0, message: '' });
                                }
                              })
                              .catch(() => {
                                setIsServerHealthy(false);
                                setIframeError(true);
                                setErrorType({
                                  code: -1,
                                  message: 'VEEWER service is currently unavailable. Please try again later.'
                                });
                              })
                              .finally(() => {
                                setIsCheckingHealth(false);
                              });
                          }}
                          className="w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition-colors shadow-md flex items-center justify-center gap-2"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                          </svg>
                          Try Again
                        </button>
                        <button
                          onClick={() => window.location.href = 'mailto:contact@veewer.com'}
                          className="w-full bg-gray-100 text-gray-700 px-4 py-3 rounded-lg hover:bg-gray-200 transition-colors flex items-center justify-center gap-2"
                        >
                          Contact Support
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={() => setShowIframe(true)}
                className="px-3 py-1.5 bg-blue-50 text-blue-600 rounded flex items-center gap-1 text-sm"
              >
                <Visibility className="w-4 h-4" />
                <span>Show Iframe</span>
              </button>
            )}
          </div>
        </div>
      </div>
      <QRCodeModal
        open={isQrModalOpen}
        onClose={() => setIsQrModalOpen(false)}
        qrValue={qrCodeData}
      />
      <SubscriptionModal
        isOpen={isSubscriptionModalOpen}
        onClose={() => setIsSubscriptionModalOpen(false)}
        userInfo={{
          fullName: "",
          email: "",
          phone: "",
          company: ""
        }}
      />
    </div>
  );
}