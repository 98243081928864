import React, { useEffect, useState, useRef } from 'react';
import '../../styles/onedrive/upload.css';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import CircleLoading from '../Loading/CircleLoading';
import { resetCreateState } from '../../store/features/object/create.uploadedObject.slice';
import { getUploadedObjectByUserId } from '../../store/features/object/get.list.uploadedObject.slice';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import { SelectableZipFiles, StatusValues } from '../../pages/object/ObjectList';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Checkbox, IconButton, Button, Dialog, DialogTitle, DialogContent, Grid, DialogActions, TextField, Select, MenuItem, CircularProgress, LinearProgress } from '@mui/material';
import { InsertDriveFile, Search, Sort } from '@mui/icons-material';
import FileFormats from './FileFormats';
import { resetCreditError } from '../../store/features/object/check.credit.slice';
import { resetCreditWhenUploadError } from '../../store/features/object/check.credit.when.upload.slice';
import JSZip from "jszip";
import path from "path-browserify"
import { resetStartUploadModelsError } from '../../store/features/object/uploadV2/starting.upload.models.slice';
import { LoginResponse } from '../../models/auth/login.model';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { useUploadStatus } from '../../context/UploadStatusContext';
import { UploadManager, UploadProgress, UploadStage } from '../../services/upload/UploadManager';
import SelectedFilesList from './SelectedFilesList';
import { GetContentRootPathService } from '../../services/file/get.content.root.path.service';
import { ProcessingUploadModelService } from '../../services/uploadedObject/uploadV2/processing.upload.model.service';
import { FinalizeUploadModelsService } from '../../services/uploadedObject/uploadV2/finalize.upload.models.service';
import { CheckManifestUploadedObjectService } from '../../services/uploadedObject/check.manifest.uploadeObject.service';
import { ProcessingUploadModel, ProcessUploadIterationResponseModel } from '../../models/object/uploadedObject/uploadV2/processing.upload.model';
import { getUploadedObjectByUserIdSilent } from '../../store/features/object/get.list.uploadedObject.slice';
import { baseUrl } from '../../config/axios.config';

interface DragAndDropProps {
	handleCloseModal: () => void;
	setIsDragDrop: React.Dispatch<React.SetStateAction<boolean>>;
	selectedFiles: UploadedFile[];
	setSelectedFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
	setZipFile: React.Dispatch<React.SetStateAction<SelectableZipFiles | undefined>>;
	zipFile: SelectableZipFiles | undefined;
	processZipFile: (file: File) => Promise<SelectableZipFiles | undefined>;
	openZipOption: boolean;
	setOpenZipOption: React.Dispatch<React.SetStateAction<boolean>>;
	extractZipStatus: boolean;
	setExtractZipStatus: React.Dispatch<React.SetStateAction<boolean>>;
	creditStatus: string;
	setCreditStatus: React.Dispatch<React.SetStateAction<string>>;
	setManifestStatus: React.Dispatch<React.SetStateAction<string>>;
	setSteppedFileName: React.Dispatch<React.SetStateAction<string>>;
	setStep: React.Dispatch<React.SetStateAction<string>>;
	invalidFileMessage: string;
	setInvalidFileMessage: React.Dispatch<React.SetStateAction<string>>;
	isFinishedToLoading: boolean;
	setIsFinishedToLoading: React.Dispatch<React.SetStateAction<boolean>>;
	afterUploadError: string;
	setAfterUploadError: React.Dispatch<React.SetStateAction<string>>;
	startToGetChunkStatus: boolean;
	setStartToGetChunkStatus: React.Dispatch<React.SetStateAction<boolean>>;
	processToUpload: boolean;
	setProcessToUpload: React.Dispatch<React.SetStateAction<boolean>>;
	isProcessing: boolean;
	setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	waitingForManifest: boolean;
	setWaitingForManifest: React.Dispatch<React.SetStateAction<boolean>>;
	step: string;
	steppedFileName: string;
	manifestStatus: string;
	currentUploadIndex: number;
	isStarted: boolean;
	setIsStarted: React.Dispatch<React.SetStateAction<boolean>>;
	statusValues: StatusValues[];
	setStatusValues: React.Dispatch<React.SetStateAction<StatusValues[]>>;
}

interface SelectedFile {
  fileName: string;
  file: string;
  fileSize: string;
  rootFileName?: string;
}

// Dosya durumunu temsil eden interface
interface FileStatus extends UploadProgress {
	isProcessed: boolean;
	currentOperation?: string;
	error?: string;
}

// Upload progress response interface'i
interface UploadProgressResponse {
	progress: number;
	bytesRead: number;
	totalBytes: number;
	path: string;
	fileName: string;
	completed: boolean;
}

// Add these type definitions
interface ManifestResponse {
  progress: string;
  // Add other properties as needed
}

// Add type definitions
interface FinalizeResponse {
  urns: string[];
  files: string[];
  uid: string;
  // Add other properties as needed
}

const bucketKey = sessionStorage.getItem('b') as string;

const ZipSelectionDialog: React.FC<{
	zipFile: SelectableZipFiles;
	onSelect: (file: any) => void;
	selectedFile: SelectedFile;
	searchTerm: string;
	setSearchTerm: (term: string) => void;
	sortBy: string;
	setSortBy: (sort: string) => void;
	sortOrder: string;
	setSortOrder: (order: string) => void;
	onContinue: () => void;
	setOpenZipOption: (value: boolean) => void;
	currentZipName: string;
	totalZips: number;
	currentZipIndex: number;
}> = ({ zipFile, onSelect, selectedFile, searchTerm, setSearchTerm, sortBy, setSortBy, sortOrder, setSortOrder, onContinue, setOpenZipOption, currentZipName, totalZips, currentZipIndex }) => {
	
	const processedZipCount = currentZipIndex + 1;
	const buttonText = processedZipCount === totalZips ? 'Continue' : `Next ZIP (${processedZipCount}/${totalZips})`;

	const filteredAndSortedFiles = React.useMemo(() => {
		let files = [...zipFile.subFiles];

		if (searchTerm) {
			files = files.filter(file => 
				file.fileName.toLowerCase().includes(searchTerm.toLowerCase())
			);
		}

		files.sort((a, b) => {
			if (sortBy === 'name') {
				const comparison = a.fileName.toLowerCase().localeCompare(b.fileName.toLowerCase());
				return sortOrder === 'asc' ? comparison : -comparison;
			} else {
				const sizeA = parseFloat(a.fileSize.toString());
				const sizeB = parseFloat(b.fileSize.toString());
				return sortOrder === 'asc' ? sizeA - sizeB : sizeB - sizeA;
			}
		});

		return files;
	}, [zipFile.subFiles, searchTerm, sortBy, sortOrder]);

	const formatFileSize = (sizeInKB: number) => {
		if (sizeInKB >= 1048576) { // 1GB = 1048576 KB
			return `${(sizeInKB / 1048576).toFixed(2)} GB`;
		} else if (sizeInKB >= 1024) {
			return `${(sizeInKB / 1024).toFixed(2)} MB`;
		} else {
			return `${sizeInKB.toFixed(2)} KB`;
		}
	};

	return (
		<div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-[60]">
			<div className="bg-white rounded-xl shadow-xl w-full max-w-2xl h-[90vh] flex flex-col my-4">
				{/* Header */}
				<div className="p-4 border-b shrink-0">
					<div className="flex items-center justify-between">
						<div>
							<h2 className="text-lg font-semibold text-gray-900">
								Select Root File
							</h2>
							<div className="mt-1 flex items-center gap-2 text-sm text-gray-500">
								<span className="font-medium truncate max-w-[300px]">
									{currentZipName}
								</span>
								<span className="text-gray-400">•</span>
								<span>ZIP {processedZipCount} of {totalZips}</span>
							</div>
							<p className="mt-1 text-sm text-gray-500">
								Please select the main file from this ZIP archive. Files larger than 1GB cannot be selected.
							</p>
						</div>
						<button
							onClick={() => setOpenZipOption(false)}
							className="p-1.5 hover:bg-gray-100 rounded-lg transition-colors"
						>
							<CloseIcon className="w-5 h-5 text-gray-400" />
						</button>
					</div>
				</div>

				{/* Search & Sort */}
				<div className="p-4 border-b bg-gray-50 shrink-0">
					<div className="flex items-center gap-3">
						<div className="flex-1 relative">
							<Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
							<input
								type="text"
								placeholder="Search files..."
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								className="w-full pl-10 pr-4 py-2 bg-white border border-gray-200 rounded-lg"
							/>
						</div>
						<select
							value={sortBy}
							onChange={(e) => setSortBy(e.target.value)}
							className="px-3 py-2 bg-white border border-gray-200 rounded-lg"
						>
							<option value="name">Name</option>
							<option value="size">Size</option>
						</select>
						<button
							onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
							className="p-2 bg-white border border-gray-200 rounded-lg"
						>
							<Sort className={`transform ${sortOrder === 'desc' ? 'rotate-180' : ''}`} />
						</button>
					</div>
				</div>

				{/* File List */}
				<div className="flex-1 overflow-y-auto min-h-0 relative">
					<div className="absolute inset-0 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 scrollbar-track-gray-100">
						<div className="divide-y">
							{filteredAndSortedFiles.map((file, i) => {
								const isOversized = file.isOversized;
								return (
									<div
										key={i}
										onClick={() => !isOversized && onSelect(file)}
										className={`flex items-center px-4 py-3 ${
											isOversized ? 'cursor-not-allowed bg-red-50' : 'cursor-pointer hover:bg-gray-50'
										} ${selectedFile.fileName === file.fileName ? 'bg-blue-50' : ''}`}
									>
										<Checkbox
											checked={selectedFile.fileName === file.fileName}
											disabled={isOversized}
											className={isOversized ? 'text-red-500' : 'text-blue-500'}
										/>
										<div className="ml-3 flex-1">
											<div className="flex items-center justify-between">
												<p className={`text-sm font-medium ${isOversized ? 'text-red-600' : 'text-gray-900'}`}>
													{file.fileName}
												</p>
												<span className={`text-xs ${isOversized ? 'text-red-500 font-medium' : 'text-gray-500'}`}>
													{formatFileSize(file.fileSize)}
												</span>
											</div>
											{isOversized && (
												<p className="text-xs text-red-500 mt-1">
													File exceeds 1GB limit
												</p>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				{/* Actions */}
				<div className="p-4 border-t bg-gray-50 shrink-0">
					<div className="flex justify-end gap-3">
						<button
							onClick={() => setOpenZipOption(false)}
							className="px-4 py-2 text-sm border border-gray-200 rounded-lg hover:bg-gray-100"
						>
							Cancel
						</button>
						<button
							onClick={onContinue}
							disabled={!selectedFile.fileName}
							className="px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
						>
							{buttonText}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const ErrorDisplay: React.FC<{
	error: string | null;
	onCancel: () => void;
}> = ({ error, onCancel }) => (
	<div className="text-center p-6 bg-red-50 rounded-lg">
		<ErrorOutlineIcon className="text-red-500 w-12 h-12 mb-4" />
		<p className="text-red-600 mb-4">{error}</p>
		<button
			onClick={onCancel}
			className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
		>
			Close
		</button>
	</div>
);

const modalStyles = {
	overlay: "fixed inset-0 bg-black/30 flex items-center justify-center p-4 z-50",
	container: "bg-white rounded-xl shadow-xl w-full max-w-3xl min-h-[400px] h-auto max-h-[95vh] flex flex-col",
	content: "flex flex-col h-full overflow-hidden",
	header: "flex items-center justify-between p-4 border-b shrink-0",
	body: "flex-1 overflow-y-auto p-4 scrollbar-thin scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 scrollbar-track-transparent"
};

const DragDropArea: React.FC<{
	handleFileInput: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
	handleFileInputWithDrop: (e: React.DragEvent<HTMLDivElement>) => Promise<void>;
	isDisabled: boolean;
}> = ({ handleFileInput, handleFileInputWithDrop, isDisabled }) => {
	const fileInputRef = React.useRef<HTMLInputElement>(null);

	return (
		<div
			onDrop={(e) => handleFileInputWithDrop(e)}
			onDragOver={(e) => e.preventDefault()}
			className="relative group border-2  border-dashed border-gray-200 hover:border-blue-400 rounded-2xl bg-gray-50/50 hover:bg-blue-50/50 transition-all duration-300"
		>
			<input
				ref={fileInputRef}
				type="file"
				onChange={handleFileInput}
				disabled={isDisabled}
				hidden
				multiple
			/>
			<div className="p-8 text-center">
				<div className="w-20 h-20 bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl mx-auto flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300 shadow-lg shadow-blue-500/20">
					<CloudUploadIcon className="text-white w-10 h-10" />
				</div>
				<h3 className="text-xl font-semibold text-gray-900 mb-2">
					Drag & Drop Files
				</h3>
				<p className="text-gray-600 mb-6">
					or click to browse from your computer
				</p>
				<button 
					onClick={() => fileInputRef.current?.click()}
					className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl hover:from-blue-600 hover:to-blue-700 transition-all duration-300 shadow-lg shadow-blue-500/20 hover:shadow-xl hover:shadow-blue-500/30"
				>
					<FileUploadIcon className="w-5 h-5 mr-2" />
					Choose Files
				</button>
				<p className="mt-4 text-sm text-gray-500">
					Supported formats: IPT, DWG, DWFX, RVT and more
				</p>
				<p className="text-xs text-gray-400 italic">
					Note: You can drag and drop more files here while others are uploading. Files will be processed in the order they are added.
				</p>
			</div>
		</div>
	);
};

const isFileSizeValid = (file: File): boolean => {
	const MAX_FILE_SIZE_GB = 1;
	const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_GB * 1024 * 1024 * 1024;
	return file.size <= MAX_FILE_SIZE_BYTES;
};

const generateGUID = (): string => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

const MultipleUploadModal: React.FC<DragAndDropProps> = ({
	handleCloseModal,
	setIsDragDrop,
	selectedFiles,
	setSelectedFiles,
	setZipFile,
	zipFile,
	processZipFile,
	openZipOption,
	setOpenZipOption,
	extractZipStatus,
	setExtractZipStatus,
	creditStatus,
	setCreditStatus,
	setManifestStatus,
	setSteppedFileName,
	setStep,
	invalidFileMessage,
	setInvalidFileMessage,
	isFinishedToLoading,
	setIsFinishedToLoading,
	afterUploadError,
	setAfterUploadError,
	startToGetChunkStatus,
	setStartToGetChunkStatus,
	processToUpload,
	setProcessToUpload,
	isProcessing,
	setIsProcessing,
	waitingForManifest,
	setWaitingForManifest,
	step,
	steppedFileName,
	manifestStatus,
	currentUploadIndex,
	isStarted,
	setIsStarted,
	statusValues,
	setStatusValues
}) => {
	const dispatch = useAppDispatch();
	const uploadedFilesSelector = useAppSelector((s: RootState) => s.createUploadedObject);
	const startUploadModelsSelector = useAppSelector((s: RootState) => s.startUploadModelsSlice)
	const { uploadStatuses, updateUploadStatus } = useUploadStatus();
	const [selectedFile, setSelectedFile] = useState<SelectedFile>({
		fileName: "",
		file: "",
		fileSize: "",
		rootFileName: "",
	});
	const [currentZipIndex, setCurrentZipIndex] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortBy, setSortBy] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');
	const [showSupportedFormats, setShowSupportedFormats] = useState(false);
	const checkCreditWhenUploadSelector = useAppSelector(x => x.checkCreditUploadedObjectWhenUploadSlice)
	const [lastAddedMainFile, setLastAddedMainFile] = useState<SelectedFile>({
		fileName: "",
		file: "",
		fileSize: "",
		rootFileName: '',
	});
	const [processingZipFile, setProcessingZipFile] = useState<string>('');
	const [currentZipQueue, setCurrentZipQueue] = useState<File[]>([]);
	const [fileStatuses, setFileStatuses] = useState<Map<string, FileStatus>>(new Map());
	const [processedCount, setProcessedCount] = useState<number>(0);
	const [activeUploads, setActiveUploads] = useState<Set<string>>(new Set());
	const uploadManager = UploadManager.getInstance();
	const [uploadProgress, setUploadProgress] = useState<Map<string, UploadProgress>>(new Map());
	const [totalZipCount, setTotalZipCount] = useState(0);
	const [isProcessingFile, setIsProcessingFile] = useState(false);
	const [isProcessingQueue, setIsProcessingQueue] = useState(false);
	const [isProcessingCancelled, setIsProcessingCancelled] = useState(false);
	const processingQueue = useRef<UploadedFile[]>([]);
	const [completedFiles, setCompletedFiles] = useState<Set<string>>(new Set());

	useEffect(() => {
		const subscription = uploadManager.getProgress().subscribe(progress => {
			setUploadProgress(progress);
			
			setCompletedFiles(prevCompletedFiles => {
				const newCompletedFiles = new Set(prevCompletedFiles);
				let hasChanged = false;
				
				progress.forEach((fileProgress, fileName) => {
					if (fileProgress.stage === UploadStage.COMPLETED && !prevCompletedFiles.has(fileName)) {
						newCompletedFiles.add(fileName);
						hasChanged = true;
					}
				});
				
				return hasChanged ? newCompletedFiles : prevCompletedFiles;
			});
		});

		return () => subscription.unsubscribe();
	}, []);

	useEffect(() => {
		if (step && steppedFileName) {
			try {
				if (typeof step === 'string' && step.startsWith('{')) {
					const response = JSON.parse(step);
					if ('bytesRead' in response) {
						uploadManager.handleRootPathResponse(response, steppedFileName);
					}
				} else if (step === 'preparing') {
					uploadManager.updateProgress(steppedFileName, {
						stage: UploadStage.PREPARING,
						message: 'Preparing file for upload...'
					});
				}
			} catch (error) {
				console.error('Error handling upload progress:', error);
			}
		}
	}, [step, steppedFileName]);

	useEffect(() => {
		if (manifestStatus && steppedFileName) {
			try {
				const response = typeof manifestStatus === 'string' ? 
					JSON.parse(manifestStatus) : manifestStatus;
				uploadManager.handleManifestResponse(response, steppedFileName);
			} catch (error) {
				console.error('Error handling manifest status:', error);
			}
		}
	}, [manifestStatus, steppedFileName]);

	const FinishedSection = () =>{
	
		return  (
			<div>
			  <span>
				<h3 style={{ color: "green" }}>Finished.</h3>
			  </span>
			</div>
		  );
	}
	  
	const hasUnfinishedUploads = () => {
		// If there are no selected files, return false
		if (selectedFiles.length === 0) return false;

		// Check if any file is actively being processed
		return selectedFiles.some(file => {
			const progress = uploadProgress.get(file.file.name);
			// Only return true if the file is in an active processing state
			return progress && (
				progress.stage === UploadStage.UPLOADING ||
				progress.stage === UploadStage.PROCESSING ||
				progress.stage === UploadStage.MANIFEST_PROCESSING ||
				progress.stage === UploadStage.PREPARING
			);
		});
	};

	const handleCloseAttempt = () => {
		// Check if there are any active uploads
		const hasActiveUploads = selectedFiles.some(file => {
			const progress = uploadProgress.get(file.file.name);
			return progress && (
				progress.stage === UploadStage.UPLOADING ||
				progress.stage === UploadStage.PROCESSING ||
				progress.stage === UploadStage.MANIFEST_PROCESSING ||
				progress.stage === UploadStage.PREPARING
			);
		});

		// If there are active uploads, show warning
		if (hasActiveUploads) {
			Swal.fire({
				title: 'Warning!',
				text: 'There are unfinished uploads. Are you sure you want to close the modal?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, close',
				cancelButtonText: 'No, keep uploading',
				confirmButtonColor: '#dc2626',
				cancelButtonColor: '#4f46e5'
			}).then((result) => {
				if (result.isConfirmed) {
					// Cancel all active uploads
					selectedFiles.forEach(file => {
						const progress = uploadProgress.get(file.file.name);
						if (progress && (
							progress.stage === UploadStage.UPLOADING ||
							progress.stage === UploadStage.PROCESSING ||
							progress.stage === UploadStage.MANIFEST_PROCESSING ||
							progress.stage === UploadStage.PREPARING
						)) {
							// Update status to ERROR for each file
							uploadManager.updateProgress(file.file.name, {
								stage: UploadStage.ERROR,
								error: 'Upload cancelled by user',
								message: 'Upload cancelled'
							});
						}
					});

					// Clear processing queue and set flags
					setIsProcessingCancelled(true);
					processingQueue.current = [];
					setIsProcessingQueue(false);
					setIsProcessingFile(false);

					// Create and trigger abort controller for all active requests
					const controller = new AbortController();
					controller.abort();

					// Force terminate all active uploads in UploadManager
					uploadManager.abortAllUploads();
					uploadManager.clearAll();

					// Reset all states and close modal
					resetModalState();
					handleCloseModal();

					// Dispatch Redux actions to reset states
					dispatch(resetCreateState());
					dispatch(resetCreditError());
					dispatch(resetCreditWhenUploadError());
					dispatch(resetStartUploadModelsError());
					
					// Force refresh the object list to ensure UI is up to date
					if (jsonLoginInfo && jsonLoginInfo.uid) {
						dispatch(getUploadedObjectByUserId(jsonLoginInfo.uid));
					}
				}
			});
		} else {
			// No active uploads, just reset and close
			resetModalState();
			handleCloseModal();
		}
	};

	// Debugging fonksiyonu - sadece geliştirme ortamında çalışır
	const debug = (message: any, ...args: any[]) => {
		if (process.env.NODE_ENV === 'development') {
			console.log(message, ...args);
		}
	};

	// Function to reset all modal state
	const resetModalState = () => {
		debug("🔄 Resetting modal state completely");
		
		const uploadManager = UploadManager.getInstance();
		
		// Always clear UploadManager when resetting state
		uploadManager.clearAll();
		
		// Reset all state variables
		setIsStarted(false);
		setIsProcessing(false);
		setWaitingForManifest(false);
		setSelectedFiles([]);
		setUploadProgress(new Map());
		setZipFile(undefined);
		setOpenZipOption(false);
		setExtractZipStatus(false);
		setCreditStatus("");
		setManifestStatus("");
		setSteppedFileName("");
		setStep("");
		setInvalidFileMessage("");
		setIsFinishedToLoading(false);
		setAfterUploadError("");
		setStartToGetChunkStatus(false);
		setProcessToUpload(false);
		setStatusValues([]);
		
		// Reset file selection states
		setSelectedFile({
			fileName: "",
			file: "",
			fileSize: "",
			rootFileName: "",
		});
		
		setLastAddedMainFile({
			fileName: "",
			file: "",
			fileSize: "",
			rootFileName: "",
		});
		
		// Reset other UI states
		setCurrentZipIndex(0);
		setSearchTerm('');
		setSortBy('name');
		setSortOrder('asc');
		setShowSupportedFormats(false);
		setProcessingZipFile('');
		setCurrentZipQueue([]);
		setFileStatuses(new Map());
		setProcessedCount(0);
		setActiveUploads(new Set());
		setTotalZipCount(0);
		
		// Reset Redux state
		dispatch(resetCreateState());
		dispatch(resetCreditError());
		dispatch(resetCreditWhenUploadError());
		dispatch(resetStartUploadModelsError());
	};

	// Add a new useEffect to handle modal mount/unmount
	useEffect(() => {
		debug("🔄 Modal mounted - Resetting UploadManager state");
		// Reset UploadManager when the modal mounts
		const uploadManager = UploadManager.getInstance();
		
		// Always clear when mounting
		uploadManager.clearAll();
		setUploadProgress(new Map());
		
		// This cleanup function will run when the modal unmounts
		return () => {
			debug("🔄 Modal unmounting - Cleaning up UploadManager");
			resetModalState();
		};
	}, []); // Empty dependency array means this runs once on mount and cleanup on unmount

	useEffect(() => {
		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			if (isProcessing || isStarted || selectedFiles.length > 0) {
				e.preventDefault();
				e.returnValue = '';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, [isProcessing, isStarted, selectedFiles]);

	// Make sure the selected files trigger our upload process correctly
	useEffect(() => {
		if (isStarted && processingQueue.current.length > 0) {
			debug("isStarted changed to true, starting upload for", selectedFiles.length, "files");
			
			// Process queue in order
			processingQueue.current.forEach(file => {
				debug("Queuing file for upload:", file.file.name);
				updateFileStatus(file.file.name, {
					stage: UploadStage.QUEUED,
					currentOperation: 'Ready to upload'
				});
			});
			
			// ... existing code ...
		}
	}, [isStarted]);

	const handleselectedFile = (file: any) => {
		if (!zipFile) return;

		setSelectedFile({
			fileName: file.fileName,
			file: file.file,
			fileSize: file.fileSize.toString(),
			rootFileName: file.fileName
		});
	};

	const handleContinueRootFileSelection = async () => {
		if (!zipFile || !selectedFile.fileName) return;

		// Mevcut zip için root file'ı kaydet
		await handleSelectFileAsRoot(zipFile.mainZipFile, selectedFile.fileName);

		// Kuyrukta bekleyen zip varsa işle
		if (currentZipQueue.length > 0) {
			const nextZip = currentZipQueue[0];
			const remainingZips = currentZipQueue.slice(1);
			setCurrentZipQueue(remainingZips);
			setCurrentZipIndex(prev => prev + 1);
			await processZipFile(nextZip);
		} else {
			// Tüm zip'ler tamamlandı
			setOpenZipOption(false);
			setZipFile(undefined);
			setCurrentZipIndex(0);
			setTotalZipCount(0);
			setSelectedFile({
				fileName: "",
				file: "",
				fileSize: "",
				rootFileName: "",
			});
		}
	};

	const handleSelectFileAsRoot = async (file: File, rootFileName: string) => {
		setSelectedFiles(prev => [
			...prev,
			{ file, rootFileName }
		]);
		
		updateFileStatus(file.name, {
			stage: UploadStage.QUEUED,
			currentOperation: 'Ready to upload'
		});
	};

	const loginInfo = sessionStorage.getItem('login') as string;
	const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);  

	useEffect(() => {
		console.log(selectedFiles);
	}, [selectedFiles])

	const handleCancel = () => {
		debug(selectedFiles);
		dispatch(resetCreateState());
		
		dispatch(resetCreditError());
		dispatch(resetCreditWhenUploadError());
		setInvalidFileMessage("");
		setAfterUploadError("");
		
		dispatch(resetStartUploadModelsError());
	};

	const error = React.useMemo(() => {
		if (uploadedFilesSelector.error) return uploadedFilesSelector.error;
		if (checkCreditWhenUploadSelector.error) return checkCreditWhenUploadSelector.error.message;
																		if (startUploadModelsSelector.error) {
																			return typeof startUploadModelsSelector.error === 'object' && startUploadModelsSelector.error.error
																				? startUploadModelsSelector.error.error
																				: startUploadModelsSelector.error;
																		}
																		if (invalidFileMessage) return invalidFileMessage;
																		if (afterUploadError) return afterUploadError;
		return null;
	}, [
		uploadedFilesSelector.error,
		checkCreditWhenUploadSelector.error,
		startUploadModelsSelector.error,
		invalidFileMessage,
		afterUploadError
	]);

	const handleRemoveFile = (index: number) => {
		if (index === -1) {
			// Remove all files
			setSelectedFiles([]);
			uploadManager.clearAll();
			setFileStatuses(new Map());
			setProcessedCount(0);
		} else {
			setSelectedFiles(prev => {
				const newFiles = [...prev];
				const removedFile = newFiles[index];
				
				// Remove the file's progress from UploadManager
				uploadManager.removeProgress(removedFile.file.name);
				
				// Remove the file from the list
				newFiles.splice(index, 1);
				return newFiles;
			});
		}
	};

	const handleStartNewUpload = () => {
		// Keep completed files in selectedFiles but remove them from active upload management
		setSelectedFiles(prev => prev.filter(file => completedFiles.has(file.file.name)));
		
		// Clear only non-completed files from UploadManager
		const currentProgress = new Map(uploadProgress);
		Array.from(currentProgress.entries()).forEach(([fileName, progress]) => {
			if (!completedFiles.has(fileName)) {
				uploadManager.removeProgress(fileName);
			}
		});
		
		// Reset all states except completed files
		setIsStarted(false);
		setIsProcessing(false);
		setWaitingForManifest(false);
		setZipFile(undefined);
		setOpenZipOption(false);
		setExtractZipStatus(false);
		setCreditStatus("");
		setManifestStatus("");
		setSteppedFileName("");
		setStep("");
		setInvalidFileMessage("");
		setAfterUploadError("");
		setStartToGetChunkStatus(false);
		setProcessToUpload(false);
		
		// Reset file selection states
		setSelectedFile({
			fileName: "",
			file: "",
			fileSize: "",
			rootFileName: "",
		});
		
		setLastAddedMainFile({
			fileName: "",
			file: "",
			fileSize: "",
			rootFileName: "",
		});
		
		// Ensure the modal stays open for new uploads
		setIsDragDrop(true);
	};

	const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		const files = Array.from(e.target.files || []);
		
		// Önce ZIP olmayan dosyaları kontrol et
		const nonZipFiles = files.filter(file => !file.name.endsWith('.zip'));
		const oversizedNonZipFiles = nonZipFiles.filter(file => !isFileSizeValid(file));
		if (oversizedNonZipFiles.length > 0) {
			Swal.fire({
				title: 'Files Too Large',
				html: `The following files exceed the maximum size limit of 1GB:<br/><br/>` +
						oversizedNonZipFiles.map(f => `- ${f.name}`).join('<br/>'),
				icon: 'error',
				confirmButtonText: 'OK'
			});
			e.target.value = '';
			return;
		}

		// ZIP olmayan dosyaları ekle
		for (const file of nonZipFiles) {
			const newFile = { file, rootFileName: "" };
			setSelectedFiles(prev => [...prev, newFile]);
			
			const uploadManager = UploadManager.getInstance();
			uploadManager.updateProgress(file.name, {
				stage: UploadStage.QUEUED,
				message: 'Ready to upload',
				currentOperation: 'Waiting to start'
			});
		}

		// ZIP dosyalarını işle
		const zipFiles = files.filter(file => file.name.endsWith('.zip'));
		for (const file of zipFiles) {
			await processZipFile(file);
			setOpenZipOption(true);
		}

		e.target.value = '';
	};

	const handleFileInputWithDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
		e.preventDefault();
		const files = Array.from(e.dataTransfer.files || []);
		
		// Önce ZIP olmayan dosyaları kontrol et
		const nonZipFiles = files.filter(file => !file.name.endsWith('.zip'));
		const oversizedNonZipFiles = nonZipFiles.filter(file => !isFileSizeValid(file));
		if (oversizedNonZipFiles.length > 0) {
			Swal.fire({
				title: 'Files Too Large',
				html: `The following files exceed the maximum size limit of 1GB:<br/><br/>` +
						oversizedNonZipFiles.map(f => `- ${f.name}`).join('<br/>'),
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return;
		}

		// ZIP olmayan dosyaları ekle
		for (const file of nonZipFiles) {
			const newFile = { file, rootFileName: "" };
			setSelectedFiles(prev => [...prev, newFile]);
			
			const uploadManager = UploadManager.getInstance();
			uploadManager.updateProgress(file.name, {
				stage: UploadStage.QUEUED,
				message: 'Ready to upload',
				currentOperation: 'Waiting to start'
			});
		}

		// ZIP dosyalarını işle
		const zipFiles = files.filter(file => file.name.endsWith('.zip'));
		for (const file of zipFiles) {
			await processZipFile(file);
			setOpenZipOption(true);
		}

		e.dataTransfer.clearData();
	};

	const updateFileStatus = (
		fileName: string,
		update: Partial<FileStatus>
	) => {
		debug('Updating file status:', fileName, update);
		uploadManager.updateProgress(fileName, {
			stage: update.stage,
			progress: update.progress,
			message: update.message,
			currentOperation: update.currentOperation,
			bytesUploaded: update.bytesUploaded,
			totalBytes: update.totalBytes
		});
	};

	const handleUploadProgress = (fileName: string, progressData: UploadProgressResponse) => {
		debug('Handling upload progress:', fileName, progressData);
		uploadManager.updateProgress(fileName, {
			stage: UploadStage.UPLOADING,
			progress: progressData.progress,
			bytesUploaded: progressData.bytesRead,
				totalBytes: progressData.totalBytes,
				message: `Uploading: ${(progressData.bytesRead / (1024 * 1024)).toFixed(1)}MB / ${(progressData.totalBytes / (1024 * 1024)).toFixed(1)}MB`,
				currentOperation: `Uploading: ${progressData.progress}%`
		});
	};

	const handleManifestProgress = (fileName: string, manifestStatus: string) => {
		debug('Handling manifest progress:', fileName, manifestStatus);
		if (manifestStatus === 'complete') {
			uploadManager.updateProgress(fileName, {
				stage: UploadStage.COMPLETED,
				progress: 100,
				message: 'Successfully uploaded',
				currentOperation: 'Complete'
			});
					} else {
			const progressMatch = manifestStatus.match(/(\d+)%/);
			if (progressMatch) {
				const progress = parseInt(progressMatch[1]);
				uploadManager.updateProgress(fileName, {
					stage: UploadStage.MANIFEST_PROCESSING,
					progress,
					message: `Processing: ${progress}%`,
					currentOperation: 'Processing manifest'
				});
			}
		}
	};

	useEffect(() => {
		if (steppedFileName) {
			try {
				// Upload progress
				if (step.startsWith('{')) {
					const progressData: UploadProgressResponse = JSON.parse(step);
					handleUploadProgress(steppedFileName, progressData);
				} 
				// Diğer durumlar
				else if (step === 'preparing') {
					updateFileStatus(steppedFileName, {
						stage: UploadStage.PREPARING,
						currentOperation: 'Preparing file for upload...'
					});
				} else if (step === 'processing') {
					updateFileStatus(steppedFileName, {
						stage: UploadStage.PROCESSING,
						currentOperation: 'Processing file on server...'
					});
				}
			} catch (error) {
				console.error('Error handling step update:', error);
			}
		}
	}, [step, steppedFileName]);

	useEffect(() => {
		if (steppedFileName && manifestStatus) {
			handleManifestProgress(steppedFileName, manifestStatus);
		}
	}, [manifestStatus, steppedFileName]);

	useEffect(() => {
		if (isProcessing && steppedFileName) {
			updateFileStatus(steppedFileName, {
				stage: UploadStage.PROCESSING,
				currentOperation: 'Processing file on server...'
			});
		}
	}, [isProcessing, steppedFileName]);

	useEffect(() => {
		if (isStarted && selectedFiles.length > 0) {
			selectedFiles.forEach(file => {
				updateFileStatus(file.file.name, {
					stage: UploadStage.QUEUED,
					currentOperation: 'Ready to upload'
				});
			});
		}
	}, [isStarted]);

	// Root path response handler
	const handleRootPathResponse = (response: any, fileName: string) => {
		updateUploadStatus(fileName, {
			rootPathResponse: response
		});

		updateFileStatus(fileName, {
			stage: UploadStage.UPLOADING,
			progress: response.progress,
			bytesUploaded: response.bytesRead,
			totalBytes: response.totalBytes,
			currentOperation: `Uploading: ${(response.bytesRead / (1024 * 1024)).toFixed(1)}MB / ${(response.totalBytes / (1024 * 1024)).toFixed(1)}MB`
		});
	};

	// Finalize response handler
	const handleFinalizeResponse = (response: any, fileName: string) => {
		updateUploadStatus(fileName, {
			finalizeResponse: response
		});

		if (response.isSuccess) {
			updateFileStatus(fileName, {
				stage: UploadStage.MANIFEST_PROCESSING,
				currentOperation: 'Starting manifest processing...'
			});
		}
	};

	// Manifest response handler
	const handleManifestResponse = (response: any, fileName: string) => {
		updateUploadStatus(fileName, {
			manifestResponse: response
		});

		if (response.progress === 'complete') {
			updateFileStatus(fileName, {
				stage: UploadStage.COMPLETED,
				progress: 100,
				currentOperation: 'Upload completed successfully'
			});
			setProcessedCount(prev => prev + 1);
		} else {
			const progressMatch = response.progress.match(/(\d+)%/);
			if (progressMatch) {
				const progress = parseInt(progressMatch[1]);
				updateFileStatus(fileName, {
					stage: UploadStage.MANIFEST_PROCESSING,
					progress,
					currentOperation: `Processing: ${progress}%`
				});
			}
		}
	};

	// useEffect güncellemeleri
	useEffect(() => {
		if (step && steppedFileName) {
			try {
				if (typeof step === 'string' && step.startsWith('{')) {
					const response = JSON.parse(step);
					if ('bytesRead' in response) {
						handleRootPathResponse(response, steppedFileName);
					}
				}
			} catch (error) {
				console.error('Error parsing step:', error);
			}
		}
	}, [step, steppedFileName]);

	useEffect(() => {
		if (manifestStatus && steppedFileName) {
			try {
				const response = typeof manifestStatus === 'string' ? JSON.parse(manifestStatus) : manifestStatus;
				handleManifestResponse(response, steppedFileName);
			} catch (error) {
				console.error('Error parsing manifest status:', error);
			}
		}
	}, [manifestStatus, steppedFileName]);

	// SelectedFilesList component'ini güncelleyelim
	const getFileStatus = (fileName: string) => {
		return uploadProgress.get(fileName) || {
			stage: UploadStage.QUEUED,
			message: 'Ready to upload'
		};
	};

	useEffect(() => {
		if (selectedFiles.length > 0) {
			selectedFiles.forEach(file => {
				const currentProgress = uploadProgress.get(file.file.name);
				if (!currentProgress || currentProgress.stage === UploadStage.QUEUED) {
					uploadManager.updateProgress(file.file.name, {
						stage: UploadStage.QUEUED,
						message: 'Ready to upload',
						currentOperation: 'Ready to upload'
					});
				}
			});
		}
	}, [selectedFiles, uploadManager]);

	const getTotalZipCount = () => {
		return zipFile ? 1 : 0;
	};

	const getProcessedZipCount = () => {
		return currentZipIndex + 1;
	};

	const handleUploadClick = () => {
		// Reset file selection states
		setSelectedFile({
			fileName: "",
			file: "",
			fileSize: "",
			rootFileName: "",
		});
		
		setLastAddedMainFile({
			fileName: "",
			file: "",
			fileSize: "",
			rootFileName: "",
		});
		
		if (waitingForManifest) {
			setWaitingForManifest(false);
		}
		
		// Process files that are not completed and in QUEUED state
		selectedFiles.forEach(file => {
			if (!completedFiles.has(file.file.name)) {
				const progress = uploadProgress.get(file.file.name);
				if (!progress || progress.stage === UploadStage.QUEUED) {
					debug("📂 Queuing file for upload:", file.file.name);
					uploadManager.updateProgress(file.file.name, {
						stage: UploadStage.QUEUED,
						message: 'Ready to upload',
						currentOperation: 'Ready to upload',
						progress: 0
					});
				}
			}
		});
		
		// Force a component update
		setUploadProgress(new Map(uploadManager.getCurrentProgress()));
		setIsStarted(true);
	};

	const handleFileProcessing = async (file: UploadedFile) => {
		const uploadManager = UploadManager.getInstance();

		try {
			// 2. Prepare file chunks
			uploadManager.updateProgress(file.file.name, {
				stage: UploadStage.PREPARING,
				message: 'Preparing file for upload...',
				progress: 0
			});

			const guid = generateGUID();
			const chunks = await getChunksAsync(file, guid);
			if (!chunks) {
				uploadManager.updateProgress(file.file.name, {
					stage: UploadStage.ERROR,
					error: `Failed to prepare chunks for file: ${file.file.name}`
				});
				throw new Error(`Failed to prepare chunks for file: ${file.file.name}`);
			}
			
			// 4. Upload chunks
			uploadManager.updateProgress(file.file.name, {
				stage: UploadStage.UPLOADING,
				message: 'Starting upload...',
				progress: 0
			});

			const uploadResult = await processToChunksAsync(chunks);
			if (!uploadResult || !uploadResult.response) {
				uploadManager.updateProgress(file.file.name, {
					stage: UploadStage.ERROR,
					error: `Failed to process upload for file: ${file.file.name}`
				});
				throw new Error(`Failed to process upload for file: ${file.file.name}`);
			}

			uploadManager.updateProgress(file.file.name, {
				stage: UploadStage.PROCESSING,
				message: 'Finalizing upload...',
				currentOperation: 'Finalizing upload'
			});

			try {
				const finalizeResponse = await finalizeUploadAsync(uploadResult);
				
				uploadManager.handleFinalizeResponse(finalizeResponse, uploadResult.response?.objectKey || '');

				uploadManager.updateProgress(file.file.name, {
					stage: UploadStage.MANIFEST_PROCESSING,
					message: 'Processing file...',
					currentOperation: 'Processing file'
				});

				// 6. Start and monitor manifest processing
				if (finalizeResponse && finalizeResponse.urns && finalizeResponse.files && finalizeResponse.uid) {
					await checkManifestStatus(finalizeResponse.urns, finalizeResponse.files, finalizeResponse.uid);
					uploadManager.updateProgress(file.file.name, {
						stage: UploadStage.COMPLETED,
						message: 'File successfully processed',
						progress: 100
					});

					// Update object list after successful upload
					if (jsonLoginInfo && jsonLoginInfo.uid) {
						await dispatch(getUploadedObjectByUserIdSilent(jsonLoginInfo.uid));
					}

				} else {
					throw new Error('Missing required data for manifest processing');
				}

			} catch (error) {
				console.error('Error during finalization or manifest:', error);
				uploadManager.updateProgress(file.file.name, {
					stage: UploadStage.ERROR,
					error: error instanceof Error ? error.message : 'Failed to finalize or process manifest'
				});
				throw error;
			}

		} catch (error) {
			console.error(`Error processing file: ${file.file.name}`, error);
			uploadManager.updateProgress(file.file.name, {
				stage: UploadStage.ERROR,
				error: error instanceof Error ? error.message : "Unknown error occurred"
			});
			setAfterUploadError(
				error instanceof Error ? error.message : "Unknown error occurred."
			);
			throw error;
		}
	};

	const getChunksAsync = async (file: UploadedFile, guid: string): Promise<ProcessingUploadModel | null> => {
		// Upload iptal edildiyse hiçbir işlem yapma
		if (isProcessingCancelled) {
			console.log("Upload cancelled. Skipping getChunksAsync for", file.file.name);
			return null;
		}
		
		if (file !== undefined) {
			const uploadManager = UploadManager.getInstance();
			const service = new GetContentRootPathService();
			const firstChunkSize = 81920;
			let bytesRead = 0;
			const totalBytes = file.file.size;
			let firstFileName = "";
			let firstPath = "";
			let completed = false;

			try {
				while (completed === false) {
					// Upload iptal edildiyse döngüyü kır
					if (isProcessingCancelled) {
						console.log("Upload cancelled during chunk processing");
						throw new Error("Upload cancelled by user");
					}
					
					const startByte = bytesRead;
					const endByte = Math.min(startByte + firstChunkSize, totalBytes);
					if (startByte >= totalBytes) {
						console.error("Start byte exceeds total bytes. Breaking the loop.");
						break;
					}
					const chunkBlob = file.file.slice(startByte, endByte);
					if (chunkBlob.size === 0) {
						console.error("Chunk blob size is zero. Breaking the loop.");
						break;
					}
					const chunkFile = new File([chunkBlob], file.file.name, { type: file.file.type });
				
					// AbortController'ı kullan - bu servis signal parametresi almıyorsa, sadece iptal kontrolü ile yönetiriz
					if (isProcessingCancelled) {
						throw new Error("Upload cancelled by user");
					}
					
					const response = await service.GetContentRoot({
						rootFileName: file.rootFileName || "",
						file: chunkFile,
						bucketName: bucketKey,
						totalBytes: totalBytes,
						bytesRead: bytesRead,
						guid: guid
					});

					// Update progress using the rootPath response handler
					uploadManager.handleRootPathResponse(response, file.file.name);

					bytesRead = response.bytesRead;
					completed = response.completed;
					firstFileName = response.fileName;
					firstPath = response.path;
				}

				const fileName = file.file.name.replace(/[^a-zA-Z0-9]/g, '');
				const cleanFileName = fileName.substring(0, 50);
				const sessionId = `SI${cleanFileName}${Math.floor(Math.random() * 100000)}`;

				const chunkSize = 5 * 1024 * 1024; // 5 MB
				const length = file.file.size;
				const chunkCount = Math.round(0.5 + (length / chunkSize));
				
				return {
					dataLength: length,
					chunkCount: chunkCount,
					dataWrited: 0,
					startByte: 0,
					chunkIndex: 0,
					sessionId: sessionId,
					saveFileName: firstFileName,
					saveFilePath: firstPath,
					rootFileName: file.rootFileName || "",
					bucketKey: bucketKey
				};
			} catch (error) {
				// Eğer error AbortError ise, bu upload'ın iptal edildiği anlamına gelir
				if (error instanceof DOMException && error.name === 'AbortError') {
					console.log("Upload was aborted:", file.file.name);
					uploadManager.updateProgress(file.file.name, {
						stage: UploadStage.ERROR,
						error: 'Upload cancelled by user',
						message: 'Upload cancelled'
					});
				} else {
					console.error("Error in getChunksAsync:", error);
					uploadManager.updateProgress(file.file.name, {
						stage: UploadStage.ERROR,
						error: error instanceof Error ? error.message : 'Unknown error'
					});
				}
				return null;
			}
		}
		return null;
	};

	const processToChunksAsync = async (chunk: ProcessingUploadModel) => {
		// Upload iptal edildiyse hiçbir işlem yapma
		if (isProcessingCancelled) {
			console.log("Upload cancelled. Skipping processToChunksAsync for", chunk.saveFileName);
			throw new Error("Upload cancelled by user");
		}
		
		const uploadManager = UploadManager.getInstance();
		try {
			let iterationParameter: ProcessUploadIterationResponseModel = {
				startByte: chunk.startByte,
				response: undefined,
				progress: 0,
				dataWrited: 0
			};

			for (let i = 0; i < chunk.chunkCount; i++) {
				// Her döngüde iptal kontrolü yap
				if (isProcessingCancelled) {
					console.log("Upload cancelled during chunk processing");
					throw new Error("Upload cancelled by user");
				}
				
				const service = new ProcessingUploadModelService();
				if (i === chunk.chunkIndex) {
					// AbortController'ı doğrudan kullanmak yerine iptal kontrolü yap
					if (isProcessingCancelled) {
						throw new Error("Upload cancelled by user");
					}
					iterationParameter = await service.PostAsync(chunk);
				} else {
					const newChunk: ProcessingUploadModel = {
						startByte: iterationParameter.startByte,
						saveFileName: chunk.saveFileName,
						chunkIndex: chunk.chunkIndex,
						chunkCount: chunk.chunkCount,
						dataWrited: iterationParameter.dataWrited,
						sessionId: chunk.sessionId,
						saveFilePath: chunk.saveFilePath,
						bucketKey: chunk.bucketKey,
						dataLength: chunk.dataLength,
						rootFileName: chunk.rootFileName
					};
					// AbortController'ı doğrudan kullanmak yerine iptal kontrolü yap
					if (isProcessingCancelled) {
						throw new Error("Upload cancelled by user");
					}
					iterationParameter = await service.PostAsync(newChunk);
				}

				// Update progress using the processUpload response handler
				uploadManager.handleProcessUploadResponse(iterationParameter, chunk.saveFileName || '');

				if (iterationParameter.progress === 100) {
					break;
				}
			}

			if (iterationParameter.progress === 100) {
				return iterationParameter;
			}
		} catch (error: any) {
			// Eğer error AbortError ise, bu upload'ın iptal edildiği anlamına gelir
			if (error instanceof DOMException && error.name === 'AbortError') {
				console.log("Upload was aborted:", chunk.saveFileName);
				uploadManager.updateProgress(chunk.saveFileName || '', {
					stage: UploadStage.ERROR,
					error: 'Upload cancelled by user',
					message: 'Upload cancelled'
				});
			} else {
				console.error("Error in processToChunksAsync:", error);
				uploadManager.updateProgress(chunk.saveFileName || '', {
					stage: UploadStage.ERROR,
					error: error.message || error.statusText
				});
			}
			throw error;
		}
	};

	const finalizeUploadAsync = async (list: ProcessUploadIterationResponseModel) => {
		// Upload iptal edildiyse hiçbir işlem yapma
		if (isProcessingCancelled) {
			console.log("Upload cancelled. Skipping finalizeUploadAsync");
			throw new Error("Upload cancelled by user");
		}

		try {
			const service = new FinalizeUploadModelsService();
			// AbortController'ı doğrudan kullanmak yerine iptal kontrolü ile yönet
			if (isProcessingCancelled) {
				throw new Error("Upload cancelled by user");
			}
			const response = await service.PostAsync([list]);
			return response;
		} catch (error) {
			// Eğer hata varsa ve iptal edilmişse
			if (isProcessingCancelled) {
				console.log("Finalize was aborted");
				throw new Error("Upload cancelled by user");
			}
			console.error("Error in finalizeUploadAsync:", error);
			throw error;
		}
	};

	const checkManifestStatus = async (urns: string[], files: string[], uid: string) => {
		// Upload iptal edildiyse hiçbir işlem yapma
		if (isProcessingCancelled) {
			console.log("Upload cancelled. Skipping checkManifestStatus");
			throw new Error("Upload cancelled by user");
		}

		const uploadManager = UploadManager.getInstance();
		
		const getOriginalFileName = (fileName: string) => {
			const cleanName = fileName.replace(/[ -]+Copy\(\d+\)_Guid_[a-f0-9-]+/i, '');
			return cleanName.replace(/_Copy_\d+/g, '')
						   .replace(/_Guid_[a-f0-9-]+/g, '')
						   .replace(/\(\d+\)/g, '')
						   .trim();
		};

		const originalFileName = getOriginalFileName(files[0]);
		const manifestService = new CheckManifestUploadedObjectService();
		let isRequestInProgress = false;
		let requestTimeoutId: NodeJS.Timeout | null = null;
		const REQUEST_TIMEOUT = 60000; // 60 seconds for each request
		const INTERVAL_DELAY = 30000; // Increased to 30 seconds between checks
		let retryCount = 0;
		const MAX_RETRIES = 5; // Increased max retries

		console.log('Starting checkManifestStatus with:', { urns, files, uid });
		console.log('Original file name:', originalFileName);
		console.log('Modified file name:', files[0]);

		// Initial delay before starting manifest checks
		await new Promise(resolve => setTimeout(resolve, 10000));

		return new Promise((resolve, reject) => {
			const intervalId = setInterval(async () => {
				// Global iptal kontrolü
				if (isProcessingCancelled) {
					console.log("Upload cancelled during manifest check");
					clearInterval(intervalId);
					reject(new Error("Upload cancelled by user"));
					return;
				}

				if (!urns || urns.length === 0 || !uid) {
					console.log('Missing required data:', { urns, uid });
					clearInterval(intervalId);
					reject(new Error("Required data is missing"));
					return;
				}

				if (isRequestInProgress) {
					console.log('Request already in progress, skipping this interval');
					return;
				}

				isRequestInProgress = true;
				requestTimeoutId = setTimeout(() => {
					console.log('Individual request timeout reached');
					isRequestInProgress = false;
				}, REQUEST_TIMEOUT);

				try {
					console.log(`Making manifest request attempt ${retryCount + 1} with:`, [urns[0], files[0], uid]);
					// AbortController'ı doğrudan kullanmak yerine iptal kontrolü ile yönet
					if (isProcessingCancelled) {
						throw new Error("Upload cancelled by user");
					}
					const response = await manifestService.GetByStringManyParamsAsync([urns[0], files[0], uid]);
					console.log('Manifest API Response:', response);
					
					// Reset retry count on successful response
					retryCount = 0;
					
					uploadManager.handleManifestResponse(response, originalFileName);
					
					if (response.progress === "complete") {
						console.log('Manifest processing complete');
						clearInterval(intervalId);
						if (requestTimeoutId) clearTimeout(requestTimeoutId);
						resolve(response);
						return;
					}

				} catch (error: any) {
					console.error("Manifest Error:", error);
					retryCount++;
					
					// Handle specific error cases
					if (error.statusCode === 409) {
						console.log(`Conflict error encountered. Retry attempt ${retryCount} of ${MAX_RETRIES}`);
						// Add exponential backoff for retries
						await new Promise(resolve => setTimeout(resolve, Math.min(1000 * Math.pow(2, retryCount), 30000)));
					}
					
					if (retryCount >= MAX_RETRIES) {
						console.log('Max retries reached, stopping manifest checks');
						clearInterval(intervalId);
						if (requestTimeoutId) clearTimeout(requestTimeoutId);
						uploadManager.updateProgress(originalFileName, {
							stage: UploadStage.ERROR,
							error: "Failed to process manifest after multiple attempts"
						});
						reject(new Error("Max retry attempts reached for manifest processing"));
						return;
					}

					uploadManager.updateProgress(originalFileName, {
						stage: UploadStage.MANIFEST_PROCESSING,
						message: `Retrying manifest check (${retryCount}/${MAX_RETRIES})`,
						error: error.message
					});

				} finally {
					isRequestInProgress = false;
					if (requestTimeoutId) {
						clearTimeout(requestTimeoutId);
						requestTimeoutId = null;
					}
				}
			}, INTERVAL_DELAY);

			// No overall timeout - will continue checking until complete or error
		});
	};

	return (
		<div className={modalStyles.overlay}>
			<div className={`${modalStyles.container} ${selectedFiles.length === 0 ? 'h-auto' : 'h-[90vh]'}`}>
				<div className={modalStyles.content}>
					{/* Header */}
					<div className={modalStyles.header}>
						<h2 className="text-xl font-semibold text-gray-900">Upload Files</h2>
						<button
							className="p-2 hover:bg-gray-100 rounded-full transition-colors"
							onClick={handleCloseAttempt}
							hidden={uploadedFilesSelector.loading}
						>
							<CloseIcon className="text-gray-500" />
						</button>
					</div>

					{/* Body */}
					<div className={modalStyles.body}>
						{extractZipStatus ? (
							<div className="flex flex-col items-center justify-center py-12">
								<div className="w-24 h-24 mb-6 relative">
									<div className="absolute inset-0 border-4 border-blue-100 rounded-full animate-pulse"></div>
									<div className="absolute inset-0 border-4 border-blue-500 rounded-full animate-spin" 
										style={{ borderRightColor: 'transparent', animationDuration: '1s' }}>
									</div>
								</div>
								<h3 className="text-xl font-semibold text-gray-900 mb-2">
									Extracting ZIP File
								</h3>
								<p className="text-gray-500 text-center max-w-sm">
									Please wait while we extract and prepare the contents of your ZIP file...
								</p>
							</div>
						) : (
							<div className="flex flex-col space-y-4">
								{zipFile && openZipOption && (
									<ZipSelectionDialog
										zipFile={zipFile}
										onSelect={handleselectedFile}
										selectedFile={selectedFile}
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										sortBy={sortBy}
										setSortBy={setSortBy}
										sortOrder={sortOrder}
										setSortOrder={setSortOrder}
										onContinue={handleContinueRootFileSelection}
										setOpenZipOption={setOpenZipOption}
										currentZipName={zipFile?.mainZipFile.name || ''}
										totalZips={totalZipCount}
										currentZipIndex={getProcessedZipCount() - 1}
									/>
								)}

								{(
									uploadedFilesSelector && uploadedFilesSelector.error !== null) ||
									(checkCreditWhenUploadSelector && checkCreditWhenUploadSelector.error !== null) ||
									(startUploadModelsSelector && startUploadModelsSelector.error !== null) ||
									invalidFileMessage !== "" ? (
									<ErrorDisplay error={error} onCancel={handleCancel} />
								) : (
									<div className="flex flex-col">
										<DragDropArea 
											handleFileInput={handleFileInput}
											handleFileInputWithDrop={handleFileInputWithDrop}
											isDisabled={uploadedFilesSelector.loading}
										/>

										{selectedFiles.length > 0 && (
											<SelectedFilesList 
												files={selectedFiles} 
												onRemove={handleRemoveFile}
												uploadProgress={uploadProgress}
												isProcessing={isProcessing}
												isStarted={isStarted}
											/>
										)}

										<div className="mt-6">
											<div className="flex items-center justify-between">
												<button
													className="text-sm text-blue-600 hover:text-blue-700 flex items-center"
													onClick={() => setShowSupportedFormats(true)}
												>
													<InfoOutlinedIcon className="w-4 h-4 mr-1" />
													See supported formats
												</button>

												<div>
													<button
														className={`px-4 py-2 text-white text-sm rounded-lg ${
															isProcessing ? 'bg-yellow-600 hover:bg-yellow-700' : 'bg-blue-600 hover:bg-blue-700'
														}`}
														onClick={handleUploadClick}
														disabled={isProcessing || selectedFiles.length === 0}
													>
														{isProcessing ? 'Processing...' : 'Upload Files'}
													</button>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						)}
					</div>

					<Dialog
						open={showSupportedFormats}
						onClose={() => setShowSupportedFormats(false)}
						maxWidth="md"
					>
						<DialogContent>
							<FileFormats />
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setShowSupportedFormats(false)}>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</div>
		</div>
	);
};

export default MultipleUploadModal;
