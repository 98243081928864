"use client"

import React, { useState, useEffect, useRef } from 'react'
import { baseUrl } from '../config/axios.config'
import axios from 'axios'

// Import all furniture thumbnails
import luxChairImg from '../assets/furniture/luxChair.png'
import ekenaArmChairImg from '../assets/furniture/ekenaArmChair.png'
import ekanaSofaImg from '../assets/furniture/ekanaSofa.png'
import sofaImg from '../assets/furniture/sofa.png'
import ikeaSofaImg from '../assets/furniture/ikeaSofa.png'
import bed48Img from '../assets/furniture/bed48.png'
import bed178Img from '../assets/furniture/bed178.png'
import woodbed160x200Img from '../assets/furniture/woodbed160x200.png'
import francuzImg from '../assets/furniture/francuz.png'
import ergonomicOfficeImg from '../assets/furniture/ergonomicOffice.png'
import gameChairImg from '../assets/furniture/gameChair.png'
import cadeiraImg from '../assets/furniture/cadeira.png'
import modernChair01Img from '../assets/furniture/modernChair01.png'
import woodenFoldableImg from '../assets/furniture/woodenFoldable.png'
import gamingChair3Img from '../assets/furniture/gamingChair3.png'
import metalChair10Img from '../assets/furniture/metalChair10.png'
import karmanImg from '../assets/furniture/karman.png'
import chairVar111Img from '../assets/furniture/chairVar111.png'
import bookShelfImg from '../assets/furniture/bookShelf.png'
import commode9Img from '../assets/furniture/commode9.png'
import bedsideTableImg from '../assets/furniture/bedsideTable.png'
import abyssDinningImg from '../assets/furniture/abyssDinning.png'
import ballImg from '../assets/furniture/ball.png'
import hourGlass22Img from '../assets/furniture/hourGlass22.png'
import trainMiniatureImg from '../assets/furniture/trainMiniature.png'
import antiqueScaleOfImg from '../assets/furniture/antiqueScaleOf.png'
import sofa4Img from '../assets/furniture/sofa4.png'
import sofa5Img from '../assets/furniture/sofa5.png'
import sofa3Img from '../assets/furniture/sofa3.png'
import maxSofaModel1Img from '../assets/furniture/max-sofa-model-1.png'
import sofa7Img from '../assets/furniture/sofa7.png'
import Footer from '../components/Footer/Footer'

// URN definitions
const luxChair = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2x1eCUyMGNoYWlyX0d1aWRfYTQ0MzJlM2UtYjlhOS00NWYwLTg2YzYtMGEyMmQzODk5NTQyLmlwdA";
const ekenaArmChair = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwYXJtY2hhaXJfR3VpZF9lYmNiZGQzYi05OThhLTRlNDUtYjEwNS1jNjAxZmM4ZDVmNTMuaXB0";
const ekanaSofa = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYV9HdWlkX2RmZWE5YjYzLWI3OTAtNDZjYy1hOTM4LTQyNTQ1NmYyYzIyMC5pcHQ";
const sofa = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL3NvZmFfR3VpZF8yODBjOTMyZS0wZDZmLTRhZWItYmM2NC01MTc1N2ZlM2Q0NmIuaXB0";
const ikeaSofa = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0lLRUElMjBDb3JuZXIlMjBTb2ZhX0d1aWRfYmY0ODMwYzAtYjliYy00NmM3LWI4MWItZDY3NGYyMGYwZWFlLmlwdA";
const bed48 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2JlZC00OF9HdWlkXzgzNjZhMmVkLTBlMWYtNGEzZi04YzdjLTE5NWQ4NTcwYzdiYy56aXA";
const bed178 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2JlZC0xNzhfR3VpZF84ZTNkZDQwYS0xMzIwLTRmZjQtOTUzYS1mMWNmYTI0MWYwNTAuemlw";
const woodbed160x200 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1dvb2QlMjBCZWQlMjAxNjB4MjAwX0d1aWRfZjRlNTE1YjYtZTI0Ny00OGNkLWI2YTUtNzJkODY5NTM3OTkwLnppcA";
const francuz = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0ZyYW5jdXpfR3VpZF9hNGNlN2JkMS04ZmY2LTQ5ODgtOWIwNi05MzMyZmYyY2VkOWYuaXB0";
const ergonomicOffice = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0VyZ29ub21pYyUyME9mZmljZSUyMENoYWlyX0d1aWRfOWMwZjY4NjktZDk1Yy00YzA3LTkxY2ItNTRmOWRhNjM0NzVlLnppcA";
const gameChair = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0dhbWUlMjBDaGFpcl9HdWlkX2M1MTUwNGRmLTljOTgtNDlmYS04NWM3LTAwZjc1ZjI2YjVkYS5pcHQ";
const cadeira = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0NhZGVpcmFfR3VpZF9iNTYyZTc3ZS0xNzE1LTQxNjAtYjk4Yi00YzM3NTE2YjVlZjcuaXB0";
const modernChair01 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL01vZGVybiUyMENoYWlyJTIwMDFfR3VpZF9hYjMyMTc1OC1hNTFlLTQ4ZjYtYWUwNC1jZTgxZWJkODhiOTMubWF4";
const woodenFoldable = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1dvb2RlbiUyMEZvbGRhYmxlJTIwQ2hhaXJfR3VpZF8yZDJhZjViYi01OTdiLTRjYjEtODQ3My01MjNlM2Q4MzliNDMuemlw";
const gamingChair3 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0dhbWluZyUyMENoYWlyJTIwM19HdWlkX2U3ZGVkZWQ1LTcyM2YtNDJkYS04NTY3LWI3YjdhZDE1MmFjZi56aXA";
const metalChair10 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL01ldGFsJTIwQ2hhaXIlMjAxMF9HdWlkXzgzMDkzOTEwLTU2OTItNDY0Zi04M2Y5LWQ3ZjBmOWZjOTUxYy56aXA";
const karman = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0thcm1hbl9HdWlkXzgwZjYzMmI5LTFhOWQtNGQxNC04ZjNjLTJiZWU4YmEzMzVlMy54X2I";
const chairVar111 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0NoYWlyJTIwVmFyMS0xLTFfR3VpZF83MjQ2YzEyYi00MTFhLTQ5YTQtYmM2Yi05YjViMzRjMDc3MmMuc3RlcA";
const bookShelf = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2Jvb2tzaGVsZl9HdWlkXzA5Yjc5NGJkLTJiNzktNDcyNS1iMGNlLWYwYzUyMWQwZjRmZS56aXA";
const commode9 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0NvbW1vZGUlMjA5X0d1aWRfNzA2ZmQyNWUtMWQ4ZS00NWMyLWJiYjMtMjFmZmI3MjM4ODdlLnppcA";
const bedsideTable = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0JlZHNpZGUlMjBUYWJsZV9HdWlkXzUyOTgwZjAzLTA1ZjYtNDg0MS1hODUzLTQ0Y2Y1NjE2OGU4NS5pcHQ";
const abyssDining = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0FCWVNTJTIwRElOTklORyUyMFNFVFMlMjBCWSUyMENPX0d1aWRfMGFhYmRiM2EtODk4Yy00ZmUwLTlmODQtMzAyNzhmMDZkZTcwLjNkbQ";
const ball = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0JBTExfR3VpZF85NWI4MzhhZS02MzhiLTRhNjAtODc0NS03MjZiYjYxODc0NWEuaXB0";
const hourGlass22 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0hvdXIlMjBHbGFzcyUyMDIyX0d1aWRfYTVmOTQ5OGUtM2VlNS00YjAzLWExNGEtMjQ0Y2UzYjNmOTk2LnppcA";
const trainMiniature = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1RyYWluJTIwTWluaWF0dXJlX0d1aWRfNWYyZWQ2NzUtYTQxNS00MDY3LWIxZGUtNWViMzc4YmZmOWFmLnppcA";
const antiqueScaleOf = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0FudGlxdWUlMjBTY2FsZV9HdWlkXzVkYzM1YjY3LTU2MWMtNDU2Yi04ZjNkLTAyYTIyODFlZDYwMS56aXA";
const sofa4 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1NvZmElMjBNb2RlbCUyMDRfR3VpZF80NGMzNDNiZC00M2Q2LTRiZTctOTQ1OS03NmYxMDViNTI2YTAubWF4";
const sofa5 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1NvZmElMjBNb2RlbCUyMDVfR3VpZF8wNTQ4MmNiMC1mNGEwLTRkMTQtYTAwMi01OGExNTE5OTVjNjMubWF4";
const sofa3 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1NvZmElMjBNb2RlbCUyMDNfR3VpZF80YzA2ZWJhNS03YzcxLTQzMDgtYTM4Zi04YjE1NzZkYWQzZTIubWF4";
const maxSofaModel1 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL01heCUyMFNvZmElMjBNb2RlbCUyMDFfR3VpZF9lMjczOGIxYi1iNThmLTRmY2QtODlmMS01MTdmNjlkYWZlZWYubWF4";
const sofa7 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1NvZmElMjBNb2RlbCUyMDdfR3VpZF9kY2Q3Mjc5OS01ZjZiLTQ2YWMtYjcyNC0xYmMzZGMwN2NkZWEubWF4";

interface Product {
  urn: string;
  title: string;
  image: string;
  category: string;
}

const ProductCard: React.FC<{ 
  product: Product;
  isServerHealthy: boolean;
  isCheckingHealth: boolean;
  errorType: { code: number; message: string };
}> = ({ product, isServerHealthy, isCheckingHealth, errorType }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [iframeError, setIframeError] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const iframeUrl = `${baseUrl}objects/content/${product.urn}/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/hideBottomToolBar=true&showWatermark=false&setGroundShadow=true&setGroundReflection=false&viewcube=true&homeViewWrapper=true&activeTools=toolbar-sectionTool,toolbar-panTool,toolbar-zoomTool,toolbar-propertiesTool,toolbar-orbitTools,toolbar-settingsTool,toolbar-modelStructureTool,toolbar-bimWalkTool,toolbar-cameraSubmenuTool,toolbar-explodeTool,toolbar-measurementSubmenuTool,toolbar-fullscreenTool,viewcube,homeViewWrapper`;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (!entry.isIntersecting) {
          setShowIframe(false);
        }
      },
      {
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isVisible && isServerHealthy) {
      timeoutId = setTimeout(() => {
        setShowIframe(true);
      }, 100);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isVisible, isServerHealthy]);

  const handleIframeLoad = () => {
    setIframeError(false);
  };

  const handleIframeError = () => {
    console.error('Examples/ProductCard - iframe loading error for URL:', iframeUrl);
    setIframeError(true);
  };

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-100 group hover:shadow-lg transition-all duration-300 h-[280px]">
      <div ref={cardRef} className="relative h-[220px] group-hover:h-[220px] transition-all duration-300">
        {isCheckingHealth ? (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500" />
          </div>
        ) : isServerHealthy ? (
          showIframe && !iframeError ? (
            <iframe
              ref={iframeRef}
              src={iframeUrl}
              title={product.title}
              className="absolute inset-0 w-full h-full z-10"
              frameBorder="0"
              loading="eager"
              onLoad={handleIframeLoad}
              onError={handleIframeError}
              allowFullScreen
            ></iframe>
          ) : iframeError ? (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
              <div className="bg-white p-4 rounded-lg shadow-sm text-center max-w-[90%] mx-2 border border-gray-200">
                <div className="inline-flex items-center justify-center w-8 h-8 mb-2 bg-blue-50 rounded-full text-lg">
                  🌐
                </div>
                <h3 className="text-sm font-medium text-gray-800 mb-1">Model Unavailable</h3>
                <button
                  onClick={() => {
                    setIframeError(false);
                    setShowIframe(true);
                  }}
                  className="mt-2 text-xs bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition-colors"
                >
                  Try Again
                </button>
              </div>
            </div>
          ) : null
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
            <div className="bg-white p-4 rounded-lg shadow-sm text-center max-w-[90%] mx-2 border border-gray-200">
              <div className="inline-flex items-center justify-center w-8 h-8 mb-2 bg-red-50 rounded-full">
                <svg className="w-4 h-4 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <h3 className="text-sm font-medium text-gray-800 mb-1">VEEWER Unavailable</h3>
              <p className="text-xs text-gray-500 mb-2">{errorType.message}</p>
            </div>
          </div>
        )}
        <img
          src={product.image}
          alt={product.title}
          className="absolute inset-0 w-full h-full object-cover z-20 pointer-events-none group-hover:opacity-0 transition-all duration-300"
          loading="lazy"
        />
        <a
          href={iframeUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-2 right-2 z-30 bg-white/90 hover:bg-white text-blue-600 p-2 rounded-lg shadow-sm transition-all duration-200"
          title="Open in new tab"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg>
        </a>
      </div>
      <div className="p-3 bg-white h-[60px]">
        <h3 className="text-sm font-medium truncate text-gray-800">{product.title}</h3>
        <p className="text-xs text-gray-500 capitalize">{product.category}</p>
      </div>
    </div>
  );
};

const Examples = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isServerHealthy, setIsServerHealthy] = useState<boolean>(false);
  const [isCheckingHealth, setIsCheckingHealth] = useState<boolean>(true);
  const [errorType, setErrorType] = useState({ code: 0, message: '' });
  const productsPerPage = 8;

  // Add single health check for the entire Examples component
  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        setIsCheckingHealth(true);
        const response = await axios.get(`${baseUrl}health`);
        if (response.data.status === "Healthy") {
          setIsServerHealthy(true);
          setErrorType({ code: 0, message: '' });
        } else {
          setIsServerHealthy(false);
          setErrorType({
            code: -1,
            message: 'VEEWER service is currently unavailable. Please try again later.'
          });
        }
      } catch (error) {
        setIsServerHealthy(false);
        setErrorType({
          code: -1,
          message: 'VEEWER service is currently unavailable. Please try again later.'
        });
      } finally {
        setIsCheckingHealth(false);
      }
    };

    checkServerHealth();
  }, []);

  const products: Product[] = [
    // Armchairs
    {
      urn: luxChair,
      title: "Lux Chair",
      image: luxChairImg,
      category: "armchair"
    },
    {
      urn: ekenaArmChair,
      title: "Ekena Armchair",
      image: ekenaArmChairImg,
      category: "armchair"
    },
    // Sofas
    {
      urn: ekanaSofa,
      title: "Ekana Sofa",
      image: ekanaSofaImg,
      category: "sofa"
    },
    {
      urn: sofa,
      title: "Sofa",
      image: sofaImg,
      category: "sofa"
    },
    {
      urn: ikeaSofa,
      title: "IKEA Corner Sofa",
      image: ikeaSofaImg,
      category: "sofa"
    },
    {
      urn: sofa4,
      title: "Sofa 4",
      image: sofa4Img,
      category: "sofa"
    },
    {
      urn: sofa5,
      title: "Sofa 5",
      image: sofa5Img,
      category: "sofa"
    },
    {
      urn: sofa3,
      title: "Sofa 3",
      image: sofa3Img,
      category: "sofa"
    },
    {
      urn: maxSofaModel1,
      title: "Max Sofa Model 1",
      image: maxSofaModel1Img,
      category: "sofa"
    },
    {
      urn: sofa7,
      title: "Sofa 7",
      image: sofa7Img,
      category: "sofa"
    },
    
    // Beds
    {
      urn: bed48,
      title: "Bed 48",
      image: bed48Img,
      category: "bed"
    },
    {
      urn: bed178,
      title: "Bed 178",
      image: bed178Img,
      category: "bed"
    },
    {
      urn: woodbed160x200,
      title: "Wood Bed 160x200",
      image: woodbed160x200Img,
      category: "bed"
    },
    {
      urn: francuz,
      title: "Francuz",
      image: francuzImg,
      category: "bed"
    },
    // Chairs
    {
      urn: ergonomicOffice,
      title: "Ergonomic Office Chair",
      image: ergonomicOfficeImg,
      category: "chair"
    },
    {
      urn: gameChair,
      title: "Game Chair",
      image: gameChairImg,
      category: "chair"
    },
    {
      urn: cadeira,
      title: "Cadeira",
      image: cadeiraImg,
      category: "chair"
    },
    {
      urn: modernChair01,
      title: "Modern Chair 01",
      image: modernChair01Img,
      category: "chair"
    },
    {
      urn: woodenFoldable,
      title: "Wooden Foldable Chair",
      image: woodenFoldableImg,
      category: "chair"
    },
    {
      urn: gamingChair3,
      title: "Gaming Chair 3",
      image: gamingChair3Img,
      category: "chair"
    },
    {
      urn: metalChair10,
      title: "Metal Chair 10",
      image: metalChair10Img,
      category: "chair"
    },
    {
      urn: karman,
      title: "Karman",
      image: karmanImg,
      category: "chair"
    },
    {
      urn: chairVar111,
      title: "Chair Var1-1-1",
      image: chairVar111Img,
      category: "chair"
    },
    // Other
    {
      urn: bookShelf,
      title: "Book Shelf",
      image: bookShelfImg,
      category: "other"
    },
    {
      urn: commode9,
      title: "Commode 9",
      image: commode9Img,
      category: "other"
    },
    {
      urn: bedsideTable,
      title: "Bedside Table",
      image: bedsideTableImg,
      category: "other"
    },
    // Dinning Sets
    {
      urn: abyssDining,
      title: "Abyss Dining Set",
      image: abyssDinningImg,
      category: "dining"
    },
    // Accessories
    {
      urn: ball,
      title: "Ball",
      image: ballImg,
      category: "accessories"
    },
    {
      urn: hourGlass22,
      title: "Hour Glass 22",
      image: hourGlass22Img,
      category: "accessories"
    },
    {
      urn: trainMiniature,
      title: "Train Miniature",
      image: trainMiniatureImg,
      category: "accessories"
    },
    {
      urn: antiqueScaleOf,
      title: "Antique Scale",
      image: antiqueScaleOfImg,
      category: "accessories"
    }
  ];

  const categories = [
    { id: 'all', label: 'All' },
    { id: 'armchair', label: 'Armchairs' },
    { id: 'sofa', label: 'Sofas' },
    { id: 'bed', label: 'Beds' },
    { id: 'chair', label: 'Chairs' },
    { id: 'other', label: 'Other' },
    { id: 'dining', label: 'Dining Sets' },
    { id: 'accessories', label: 'Accessories' },
  ];

  useEffect(() => {
    if (selectedCategory === 'all') {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(products.filter(product => product.category === selectedCategory));
    }
    // Kategori değiştiğinde sayfa 1'e dön
    setCurrentPage(1);
  }, [selectedCategory]);

  // Mevcut sayfadaki ürünleri hesapla
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Sayfa değiştirme fonksiyonu
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    // Sayfayı yukarı kaydır
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="max-w-screen-xl mt-[-80px] mx-auto h-full flex flex-col px-6">
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Furniture Gallery</h2>
      
      {/* Category Filter */}
      <div className="flex flex-wrap justify-center gap-2 mb-4">
        {categories.map(category => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category.id)}
            className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 ${
              selectedCategory === category.id
                ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-700'
                : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
            }`}
          >
            {category.label}
          </button>
        ))}
      </div>

      {/* Product Grid */}
      <div className="flex-1">
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 pb-16 auto-rows-[280px] max-w-6xl mx-auto">
          {currentProducts.map((product, index) => (
            <ProductCard 
              key={index} 
              product={product}
              isServerHealthy={isServerHealthy}
              isCheckingHealth={isCheckingHealth}
              errorType={errorType}
            />
          ))}
        </div>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex mb-10 left-0 right-0 flex justify-center items-center gap-1.5">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 ${
              currentPage === 1
                ? 'bg-gray-50 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
            }`}
          >
            Previous
          </button>
          
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 ${
                currentPage === index + 1
                  ? 'bg-blue-600 text-white shadow-sm'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
              }`}
            >
              {index + 1}
            </button>
          ))}

          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 ${
              currentPage === totalPages
                ? 'bg-gray-50 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
            }`}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default Examples;