import axios from 'axios';
export enum Environment {
  DEV = 'DEV',
  PRODUCTION = 'PRODUCTION',
  PRODUCTION_BETA = 'PRODUCTION_BETA',
  PRODUCTION_DEV = 'PRODUCTION_DEV',
}

export const CurrentEnvironment: Environment = Environment.PRODUCTION;

export const getEnvironmentUrl = (
  environment: Environment
): string => {
  switch (environment) {
    case Environment.DEV:
      return 'http://localhost:5057/api/v1/';
    case Environment.PRODUCTION:
      return 'https://server.veewer.com/api/v1/';
    case Environment.PRODUCTION_BETA:
      return 'https://veewerbeta.azurewebsites.net/api/v1/'
    case Environment.PRODUCTION_DEV:
      return 'https://veewerdev.azurewebsites.net/api/v1/';
    default:
      throw new Error('Invalid Environment');
  }
};

export const baseUrl = getEnvironmentUrl(
  CurrentEnvironment,

);

export const http = (
  controllerName: string,
  endpoint: string,
  contentType: string = 'application/json;charset=UTF-8'
) => {
  const url: string = `${baseUrl}${controllerName}/${endpoint}`;
  const instance = axios.create({
    baseURL: url,
    withCredentials: true,
    headers: {
      'Content-Type': contentType,
      'Access-Control-Allow-Origin': 'react',
    },
  });
  return instance;
};
