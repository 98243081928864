import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { fetchUserInfo } from '../../store/features/user/get.user.slice';
import { LoginResponse } from '../../models/auth/login.model';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentIcon from '@mui/icons-material/Payment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingScreen from '../../utils/LoadingScreen';
import Footer from '../../components/Footer/Footer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, IconButton, Tooltip, Alert, Snackbar } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../config/axios.config';
import SubscriptionModal from '../../components/SubscriptionModal/SubscriptionModal';
import { CheckFeatureService } from '../../services/subscription/check.feature.service';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CloseIcon from '@mui/icons-material/Close';

interface InfoItemProps {
  icon: React.ReactNode;
  label: string;
  value: string;
}

function InfoItem({ icon, label, value }: InfoItemProps) {
  return (
    <div className="flex items-center gap-3 p-3 border rounded-lg bg-gray-50">
      <div className="text-blue-500">
        {icon}
      </div>
      <div className="min-w-0">
        <p className="text-xs text-gray-500">{label}</p>
        <p className="text-sm font-medium text-gray-900 truncate">{value}</p>
      </div>
    </div>
  );
}

export default function UserProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [hasCustomBranding, setHasCustomBranding] = useState(false);
  const [companyImage, setCompanyImage] = useState<string | null>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const userInfoSlice = useAppSelector((state: RootState) => state.userInfoSlice);
  const userInfo = userInfoSlice.data;
  const login = sessionStorage.getItem('login') as string;
  const jsonLogin = JSON.parse(login) as LoginResponse;
  
  // Alert states for payment status
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');
  const [isCancellingSubscription, setIsCancellingSubscription] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteLoadingProgress, setDeleteLoadingProgress] = useState(0);
  const [deleteLoadingText, setDeleteLoadingText] = useState('');

  useEffect(() => {
    dispatch(fetchUserInfo(jsonLogin.uid));
    fetchCompanyImage();

    // Check for payment status in URL params
    const params = new URLSearchParams(location.search);
    const paymentStatus = params.get('status');
    const sessionId = params.get('session_id');
    
    if (paymentStatus === 'success') {
      setAlertSeverity('success');
      setAlertMessage('Payment successful! Your subscription has been updated.');
      setAlertOpen(true);
      
      // Remove status params from URL without reloading
      navigate('/profile', { replace: true });
      
      // Refresh user info to get updated subscription status
      dispatch(fetchUserInfo(jsonLogin.uid));
    } else if (paymentStatus === 'cancel') {
      setAlertSeverity('warning');
      setAlertMessage('Payment was cancelled. Your subscription has not been changed.');
      setAlertOpen(true);
      
      // Remove status params from URL without reloading
      navigate('/profile', { replace: true });
    }

    const checkCustomBranding = async () => {
      if (!login) return;

      const { uid } = JSON.parse(login);
      const checkFeatureService = new CheckFeatureService();
      const hasFeature = await checkFeatureService.GetByStringManyParamsAsync([uid, 'Custom branding']);
      setHasCustomBranding(hasFeature);
    };

    checkCustomBranding();
  }, [dispatch, jsonLogin.uid, location.search, navigate]);

  const fetchCompanyImage = async () => {
    try {
      const imageUrlPath = `${baseUrl}objects/get-company-image/${jsonLogin.uid}`;
      setCompanyImage(imageUrlPath);
    } catch (error) {
      console.error('Error setting company image:', error);
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!hasCustomBranding) {
      const warningDiv = document.createElement('div');
      warningDiv.className = 'fixed top-4 right-4 bg-white border border-gray-200 rounded-lg shadow-lg p-4 max-w-sm animate-fade-in z-50';
      const warningContent = document.createElement('div');
      warningContent.className = 'flex items-start gap-3';
      warningContent.innerHTML = `
        <div class="flex-shrink-0">
          <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
          </svg>
        </div>
        <div class="flex-1">
          <p class="text-sm font-medium text-gray-900">Custom Branding Not Available</p>
          <p class="mt-1 text-sm text-gray-500">Company logo upload requires a premium subscription.</p>
          <button 
            class="mt-3 w-full inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 rounded-md transition-all duration-150 ease-in-out"
          >
            Upgrade Your Plan
          </button>
        </div>
        <button class="flex-shrink-0 text-gray-400 hover:text-gray-500">
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
          </svg>
        </button>
      `;
      warningDiv.appendChild(warningContent);
      
      // Add event listeners
      const upgradeButton = warningContent.querySelector('button:not(.flex-shrink-0)');
      const closeButton = warningContent.querySelector('button.flex-shrink-0');
      
      if (upgradeButton) {
        upgradeButton.addEventListener('click', () => {
          setIsSubscriptionModalOpen(true);
          warningDiv.remove();
        });
      }
      
      if (closeButton) {
        closeButton.addEventListener('click', () => {
          warningDiv.classList.add('animate-fade-out');
          setTimeout(() => warningDiv.remove(), 300);
        });
      }
      
      document.body.appendChild(warningDiv);
      setTimeout(() => {
        warningDiv.classList.add('animate-fade-out');
        setTimeout(() => warningDiv.remove(), 300);
      }, 5000);
      return;
    }

    // Check file type
    const fileType = file.type;
    if (!['image/png', 'image/jpeg', 'image/jpg'].includes(fileType)) {
      const warningDiv = document.createElement('div');
      warningDiv.className = 'fixed top-4 right-4 bg-white border border-gray-200 rounded-lg shadow-lg p-4 max-w-sm animate-fade-in z-50';
      warningDiv.innerHTML = `
        <div class="flex items-start gap-3">
          <div class="flex-shrink-0">
            <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="flex-1">
            <p class="text-sm font-medium text-gray-900">Invalid File Type</p>
            <p class="mt-1 text-sm text-gray-500">Only PNG, JPG and JPEG files are allowed.</p>
          </div>
          <button class="flex-shrink-0 text-gray-400 hover:text-gray-500" onclick="this.parentElement.parentElement.remove()">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </button>
        </div>
      `;
      document.body.appendChild(warningDiv);
      setTimeout(() => {
        warningDiv.classList.add('animate-fade-out');
        setTimeout(() => warningDiv.remove(), 300);
      }, 5000);
      return;
    }

    setImageLoading(true);
    const formData = new FormData();
    formData.append('image', file);
    formData.append('userId', jsonLogin.uid);

    try {
      await axios.post(`${baseUrl}objects/upload-company-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        },
        withCredentials: false
      });
      
      setCompanyImage(null);
      setTimeout(() => {
        const timestamp = new Date().getTime();
        setCompanyImage(`${baseUrl}objects/get-company-image/${jsonLogin.uid}?t=${timestamp}`);
      }, 1000);
    } catch (error: any) {
      console.error('Error uploading image:', error);
      const warningDiv = document.createElement('div');
      warningDiv.className = 'fixed top-4 right-4 bg-white border border-gray-200 rounded-lg shadow-lg p-4 max-w-sm animate-fade-in z-50';
      warningDiv.innerHTML = `
        <div class="flex items-start gap-3">
          <div class="flex-shrink-0">
            <svg class="w-5 h-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="flex-1">
            <p class="text-sm font-medium text-gray-900">Upload Failed</p>
            <p class="mt-1 text-sm text-gray-500">${error.response?.data?.error || 'Failed to upload image. Please try again.'}</p>
          </div>
          <button class="flex-shrink-0 text-gray-400 hover:text-gray-500" onclick="this.parentElement.parentElement.remove()">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </button>
        </div>
      `;
      document.body.appendChild(warningDiv);
      setTimeout(() => {
        warningDiv.classList.add('animate-fade-out');
        setTimeout(() => warningDiv.remove(), 300);
      }, 5000);
    } finally {
      setImageLoading(false);
    }
  };

  if (!userInfo || userInfoSlice.error) {
    return <LoadingScreen />;
  }

  const creditUsagePercentage = (userInfo.usedCredit / (userInfo.usedCredit + userInfo.availableCredit)) * 100;
  const isLowCredit = creditUsagePercentage >= 90; // Consider credits low when usage is 90% or more

  const handleCancelSubscription = async () => {
    try {
      setIsCancellingSubscription(true);
      
      const response = await axios.post(
        `${baseUrl}stripe/cancel-subscription`, 
        JSON.stringify(jsonLogin.uid), 
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      
      if (response.data) {
        setAlertSeverity('success');
        setAlertMessage('Your subscription has been cancelled successfully.');
        setAlertOpen(true);
        setIsManageModalOpen(false);
        // Refresh user info to get updated subscription status
        dispatch(fetchUserInfo(jsonLogin.uid));
      }
    } catch (error: any) {
      setAlertSeverity('error');
      setAlertMessage(error.response?.data?.message || 'Failed to cancel subscription.');
      setAlertOpen(true);
    } finally {
      setIsCancellingSubscription(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setIsDeletingAccount(true);
      setDeleteLoadingText('Verifying credentials...');
      
      // Simulate a longer loading process with multiple steps
      let progress = 0;
      const loadingInterval = setInterval(() => {
        progress += 5;
        setDeleteLoadingProgress(progress);
        
        if (progress === 20) {
          setDeleteLoadingText('Checking account status...');
        } else if (progress === 40) {
          setDeleteLoadingText('Preparing account data...');
        } else if (progress === 60) {
          setDeleteLoadingText('Validating request...');
        } else if (progress === 80) {
          setDeleteLoadingText('Finalizing operation...');
        } else if (progress >= 100) {
          clearInterval(loadingInterval);
        }
      }, 300);
      
      // Add artificial delay before actual API call (3 seconds minimum)
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      const response = await axios.delete(`${baseUrl}user/deleteUser`, {
        data: {
          userId: jsonLogin.uid,
          password: password
        }
      });

      // Make sure we've shown at least the full loading sequence
      if (deleteLoadingProgress < 100) {
        await new Promise(resolve => {
          const remainingTime = (100 - deleteLoadingProgress) * 300;
          setTimeout(resolve, remainingTime);
        });
      }
      
      clearInterval(loadingInterval);

      if (response.data.isSuccess) {
        // Show success message if needed
        if (response.data.message) {
          alert(response.data.message);
        }
        // Clear all storage and authentication data
        sessionStorage.clear();
        localStorage.clear();
        // Redirect to login page
        navigate('/login', { replace: true });
      } else {
        setError(response.data.message || 'Failed to delete account');
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'An error occurred while deleting the account';
      if (error.response?.status === 401) {
        setError('Incorrect password. Please try again.');
      } else if (error.response?.status === 404) {
        setError('User not found.');
      } else {
        setError(errorMessage);
      }
    } finally {
      setIsDeletingAccount(false);
      setDeleteLoadingProgress(0);
      setDeleteLoadingText('');
    }
  };

  return (
    <div className="h-[calc(100vh-80px)] overflow-y-auto bg-white">
      {/* Alert for payment status */}
      <Snackbar 
        open={alertOpen} 
        autoHideDuration={6000} 
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setAlertOpen(false)} 
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <div className="max-w-3xl mx-auto px-4 py-6">
        {/* Company Logo Section */}
        <div className="mb-8 text-center">
          <div className="relative inline-block">
            <div className="w-32 h-32 mx-auto bg-white rounded-xl shadow-md flex items-center justify-center overflow-hidden border-2 border-gray-100">
              {imageLoading ? (
                <div className="flex flex-col items-center justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  <p className="text-xs text-gray-500 mt-2">Uploading...</p>
                </div>
              ) : companyImage ? (
                <img 
                  src={companyImage} 
                  alt="Company Logo" 
                  className="w-full h-full object-contain p-2"
                  onError={(e) => {
                    console.error('Image failed to load:', companyImage);
                    e.currentTarget.src = '';
                    setCompanyImage(null);
                  }}
                  crossOrigin="anonymous"
                  key={companyImage}
                />
              ) : (
                <BusinessIcon className="w-16 h-16 text-gray-400" />
              )}
            </div>
            <label htmlFor="company-image-upload" className="absolute bottom-0 right-0 bg-white rounded-full p-1 shadow-md cursor-pointer hover:bg-gray-50 transition-colors">
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
                id="company-image-upload"
                disabled={imageLoading}
              />
              <Tooltip title="Upload Company Logo">
                <CloudUploadIcon className={`w-6 h-6 ${imageLoading ? 'text-gray-400' : 'text-blue-500'}`} />
              </Tooltip>
            </label>
          </div>
          <div className="mt-4">
            <h1 className="text-2xl font-semibold text-gray-900">{userInfo.userName}</h1>
            <p className="text-sm text-gray-500 mt-1">{userInfo.email}</p>
            <span className="inline-block mt-2 px-4 py-1.5 bg-blue-50 text-blue-600 text-sm font-medium rounded-full">
              {userInfo.subscriptionName || 'Free Plan'}
            </span>
            <p className="text-xs text-gray-500 mt-2">Upload company logo (PNG, JPG or JPEG only)</p>
          </div>
        </div>

        {/* Info Grid */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <InfoItem icon={<EmailIcon />} label="Email" value={userInfo.email} />
          <InfoItem icon={<PhoneIcon />} label="Phone" value={userInfo.phoneNumber || "Not provided"} />
          <InfoItem icon={<BusinessIcon />} label="Company" value={userInfo.companyName} />
          <div className="flex items-center gap-3 p-3 border rounded-lg bg-gray-50">
            <div className="text-blue-500">
              <PaymentIcon />
            </div>
            <div className="min-w-0 flex-1">
              <p className="text-xs text-gray-500">Plan</p>
              <p className="text-sm font-medium text-gray-900 truncate">{userInfo.subscriptionName || 'Free Plan'}</p>
            </div>
            <Tooltip title="Manage Subscription">
              <IconButton 
                size="small" 
                className="text-gray-600 hover:text-blue-600"
                onClick={() => setIsManageModalOpen(true)}
              >
                <ManageAccountsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {/* Credit Usage */}
        <div className="border rounded-lg p-4 mb-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-sm font-medium text-gray-900">Credit Usage</h2>
            <span className="text-sm text-blue-600">{creditUsagePercentage.toFixed(1)}%</span>
          </div>

          {/* Progress Bar */}
          <div className="h-2 bg-gray-100 rounded-full mb-4">
            <div 
              className={`h-full rounded-full transition-all duration-500 ${
                isLowCredit ? 'bg-red-500' : 'bg-blue-500'
              }`}
              style={{ width: `${creditUsagePercentage}%` }}
            />
          </div>

          {/* Credit Details */}
          <div className="flex justify-between text-sm mb-4">
            <div>
              <span className="text-gray-500">Used: </span>
              <span className="font-medium text-gray-900">{userInfo.usedCredit}</span>
            </div>
            <div>
              <span className="text-gray-500">Available: </span>
              <span className="font-medium text-gray-900">{userInfo.availableCredit}</span>
            </div>
          </div>

          {/* Upgrade Button - Always visible */}
          <Button
            variant="contained"
            fullWidth
            onClick={() => setIsSubscriptionModalOpen(true)}
            sx={{
              background: isLowCredit 
                ? 'linear-gradient(to right, #ef4444, #dc2626)'
                : 'linear-gradient(to right, #3b82f6, #2563eb)',
              '&:hover': {
                background: isLowCredit
                  ? 'linear-gradient(to right, #dc2626, #b91c1c)'
                  : 'linear-gradient(to right, #2563eb, #1d4ed8)',
              },
              textTransform: 'none',
              fontSize: '0.95rem',
              py: 1.5,
              display: 'flex',
              gap: 1
            }}
          >
            {isLowCredit ? 'Upgrade Now - Low Credits!' : 'Upgrade Your Plan'}
          </Button>
        </div>

        {/* Subscription Modal */}
        <SubscriptionModal
          isOpen={isSubscriptionModalOpen}
          onClose={() => setIsSubscriptionModalOpen(false)}
          userInfo={{
            fullName: userInfo?.fullName || "",
            email: userInfo?.email || "",
            phone: userInfo?.phoneNumber || "",
            company: userInfo?.companyName || ""
          }}
        />

        {/* Manage Subscription Modal - Tailwind Version */}
        {isManageModalOpen && (
          <div className="fixed inset-0 z-50 overflow-y-auto">
            {/* Backdrop */}
            <div 
              className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
              onClick={() => setIsManageModalOpen(false)}
            />
            
            {/* Modal */}
            <div className="relative min-h-screen flex items-center justify-center p-4">
              <div className="relative bg-white rounded-lg w-full max-w-md mx-auto shadow-xl">
                {/* Header */}
                <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
                  <div className="flex items-center gap-3">
                    <PaymentIcon className="text-blue-500" />
                    <h3 className="text-lg font-semibold text-gray-900">Manage Account</h3>
                  </div>
                  <button 
                    onClick={() => setIsManageModalOpen(false)}
                    className="text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <CloseIcon fontSize="small" />
                  </button>
                </div>
                
                {/* Content */}
                <div className="px-6 py-4">
                  <div className="space-y-6">
                    {/* Subscription Info */}
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">Current Plan</h3>
                      <p className="text-lg font-semibold text-gray-900 mb-2">{userInfo.subscriptionName || 'Free Plan'}</p>
                      
                      {/* Add Cancel Subscription button here if user has a plan */}
                      {userInfo.subscriptionName && userInfo.subscriptionName !== 'Free Plan' && (
                        <>
                          <p className="text-xs text-gray-500 mb-3">
                            Cancelling your subscription will allow you to use your plan until the end of your current billing period.
                          </p>
                          <button
                            onClick={handleCancelSubscription}
                            disabled={isCancellingSubscription}
                            className="text-xs text-gray-400 hover:text-gray-500 transition-colors focus:outline-none ml-auto mr-0 flex items-center gap-1"
                          >
                            {isCancellingSubscription ? (
                              <span className="flex items-center justify-center gap-1">
                                <svg className="animate-spin h-3 w-3 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <span>Processing...</span>
                              </span>
                            ) : <span className="underline decoration-dotted">Cancel subscription</span>}
                          </button>
                        </>
                      )}
                    </div>
                    
                    {/* Account Information */}
                    <div className="border-t pt-4">
                      <h3 className="text-sm font-medium text-gray-500 mb-3">Account Information</h3>
                      <div className="grid grid-cols-2 gap-3 text-sm">
                        <div>
                          <p className="text-xs text-gray-500">Name</p>
                          <p className="font-medium text-gray-900">{userInfo.userName || 'Not available'}</p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-500">Email</p>
                          <p className="font-medium text-gray-900">{userInfo.email}</p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-500">Company</p>
                          <p className="font-medium text-gray-900">{userInfo.companyName || 'Not available'}</p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-500">Phone</p>
                          <p className="font-medium text-gray-900">{userInfo.phoneNumber || 'Not available'}</p>
                        </div>
                      </div>
                    </div>
                    
                    {/* Delete Account */}
                    <div className="border-t pt-4">
                      {!showDeleteConfirm ? (
                        <>
                          <h3 className="text-sm font-medium text-gray-500 mb-2">Account Management</h3>
                          <p className="text-xs text-gray-500 mb-4">
                            Manage your account settings and preferences.
                          </p>
                          <div className="mt-2 pt-3 flex justify-end">
                            <button
                              onClick={() => setShowDeleteConfirm(true)}
                              className="text-xs text-gray-400 hover:text-gray-500 transition-all duration-200 focus:outline-none flex items-center gap-1"
                            >
                              <span className="font-normal underline decoration-dotted">Delete account</span>
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <h3 className="text-sm font-medium text-gray-500 mb-2">Account Security</h3>
                          <p className="text-xs text-gray-500 mb-4">
                            Please enter your password to confirm your action.
                          </p>
                          
                          {error && (
                            <div className="mb-4 p-2.5 bg-red-50 border border-red-200 rounded text-xs text-red-600">
                              {error}
                            </div>
                          )}
                          
                          <div className="mb-4">
                            <label htmlFor="password" className="block text-xs font-medium text-gray-700 mb-1">
                              Password
                            </label>
                            <input
                              type="password"
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="w-full px-3 py-2.5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                              placeholder="Enter your password"
                              autoFocus
                            />
                          </div>
                          
                          <div className="flex gap-3">
                            <button
                              onClick={() => {
                                setShowDeleteConfirm(false);
                                setPassword('');
                                setError('');
                              }}
                              className="flex-1 py-2.5 px-4 bg-white border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-sm font-medium"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleDeleteAccount}
                              disabled={!password || isDeletingAccount}
                              className="text-xs py-1.5 px-2 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition-colors focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              {isDeletingAccount ? (
                                <span className="flex flex-col items-center justify-center gap-1">
                                  <div className="w-full bg-gray-200 rounded-full h-1.5 mb-1">
                                    <div className="bg-gray-400 h-1.5 rounded-full transition-all duration-300" style={{ width: `${deleteLoadingProgress}%` }}></div>
                                  </div>
                                  <div className="flex items-center gap-1">
                                    <svg className="animate-spin h-3 w-3 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>{deleteLoadingText}</span>
                                  </div>
                                </span>
                              ) : 'Confirm'}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                
                {/* Footer */}
                <div className="px-6 py-4">
                  <button
                    onClick={() => setIsManageModalOpen(false)}
                    className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-sm font-medium"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}