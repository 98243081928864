import { baseUrl } from "../../config/axios.config";

interface TokenResponse {
    token: string;
}

export class GetSecondaryTokenService {
    public async GetByStringAsync(uid: string): Promise<string> {
        const response = await fetch(`${baseUrl}auth/secondaryToken/${uid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to get secondary token');
        }

        const data: TokenResponse = await response.json();
        return data.token;
    }
} 