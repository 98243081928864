import React, { useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeIcon from '@mui/icons-material/QrCode';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

interface QRCodeModalProps {
    open: boolean;
    onClose: () => void;
    qrValue: string;
}

export const QRCodeModal: React.FC<QRCodeModalProps> = ({ open, onClose, qrValue }) => {
    const qrRef = useRef<SVGSVGElement>(null);

    const handleDownload = () => {
        if (!qrRef.current) return;
        
        const svg = qrRef.current;
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        
        img.onload = () => {
            canvas.width = img.width * 2; // 2x resolution for better quality
            canvas.height = img.height * 2;
            ctx?.scale(2, 2); // Scale up for better resolution
            ctx?.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL('image/png');
            
            const downloadLink = document.createElement('a');
            downloadLink.download = 'veewer-ar-qrcode.png';
            downloadLink.href = pngFile;
            downloadLink.click();
        };
        
        img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    };

    const handleGoToAR = () => {
        window.open(qrValue, '_blank');
    };

    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            {/* Backdrop */}
            <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" onClick={onClose} />

            {/* Modal */}
            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-white rounded-2xl shadow-xl max-w-md w-full p-6 overflow-hidden">
                    {/* Close Button */}
                    <button
                        onClick={onClose}
                        className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
                    >
                        <CloseIcon className="w-6 h-6" />
                    </button>

                    {/* Header */}
                    <div className="flex items-center gap-3 mb-6">
                        <div className="bg-blue-50 p-2 rounded-xl">
                            <QrCodeIcon className="w-8 h-8 text-blue-600" />
                        </div>
                        <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
                            AR View QR Code
                        </h2>
                    </div>

                    {/* QR Code Container */}
                    <div className="bg-gray-50 p-6 rounded-xl mb-6 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg shadow-sm flex justify-center items-center">
                            <QRCodeSVG
                                ref={qrRef}
                                value={qrValue}
                                size={400}
                                level="L"
                                includeMargin={true}
                                bgColor="#FFFFFF"
                                fgColor="#000000"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '400px'
                                }}
                            />
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex gap-4 mb-6">
                        <button
                            onClick={handleGoToAR}
                            className="flex-1 flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-3 rounded-xl font-semibold hover:bg-blue-700 transition-colors shadow-lg shadow-blue-100"
                        >
                            <ViewInArIcon className="w-5 h-5" />
                            Go to AR
                        </button>
                        <button
                            onClick={handleDownload}
                            className="flex-1 flex items-center justify-center gap-2 bg-white text-blue-600 px-4 py-3 rounded-xl font-semibold border-2 border-blue-100 hover:bg-blue-50 transition-colors"
                        >
                            <FileDownloadIcon className="w-5 h-5" />
                            Download
                        </button>
                    </div>

                    {/* Description */}
                    <p className="text-sm text-gray-500 text-center">
                        Scan the QR code with your mobile device or click "Go to AR" to view the model in augmented reality
                    </p>
                </div>
            </div>
        </div>
    );
}; 