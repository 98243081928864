import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { jwtDecode } from 'jwt-decode';
import { decode } from 'punycode';
import { baseUrl } from '../../config/axios.config';
import Swal from 'sweetalert2';
// Import your Google auth action here when backend is ready
// import { fetchGoogleAuth } from '../../store/features/auth/google.auth.slice';

interface GoogleLoginResponse {
  isSuccess?: boolean;
  userId?: string;
  result?: string | null;
  google_auth?: boolean;
  microsoft_auth?: boolean;
  isAdmin?: boolean;
  isUser?: boolean;
  isOwner?: boolean;
  uid?: string;
  isAuthenticated?: boolean;
  secondExpiresAt?: string;
  errors?: Record<string, string[]>;
}

const GoogleLoginButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      const token = credentialResponse.credential;
      const decoded: any = jwtDecode(credentialResponse.credential);
      
      try {
        const response = await fetch(`${baseUrl}google/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token })
        });

        const data: GoogleLoginResponse = await response.json();

        if (!response.ok) {
          if (data.errors) {
            const errorMessages = Object.values(data.errors).flat();
            Swal.fire({
              title: 'Error',
              text: errorMessages.join('\n'),
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: 'An error occurred during Google login',
              icon: 'error'
            });
          }
          return;
        }

        if (data.google_auth) { // Login case
          if (!data.uid) {
            throw new Error('UserId is missing from login response');
          }

          const authData = JSON.stringify({
            isAuthenticated: data.isAuthenticated,
            expiresAt: data.secondExpiresAt,
            isAdmin: data.isAdmin || false,
            isUser: data.isUser || false,
            isOwner: data.isOwner || false,
            uid: data.uid,
            google_auth: data.google_auth,
            microsoft_auth: data.microsoft_auth || false
          });
          
          sessionStorage.setItem('login', authData);
          localStorage.setItem(`isAnyOpenSession-${decoded.email}`, "true");
          
          const userInfo = {
            email: decoded.email,
            name: decoded.name,
            picture: decoded.picture,
            userId: data.uid
          };
          localStorage.setItem('userInfo', JSON.stringify(userInfo));

          try {
            const refreshResponse = await fetch(`${baseUrl}auth/refresh/${data.uid}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              }
            });

            if (refreshResponse.ok) {
              const refreshData = await refreshResponse.json();
              localStorage.setItem('token', refreshData.token || '');
              localStorage.setItem('refreshToken', refreshData.refreshToken || '');
            }
          } catch (refreshError) {
            Swal.fire({
              title: 'Warning',
              text: 'Failed to get refresh token',
              icon: 'warning'
            });
          }
          
          navigate('/');
        } else if (data.isSuccess && data.userId) { // Register case
          const authData = JSON.stringify({
            isAuthenticated: true,
            expiresAt: new Date(decoded.exp * 1000).toISOString(),
            isAdmin: false,
            isUser: false,
            isOwner: false,
            uid: data.userId,
            google_auth: true,
            microsoft_auth: false
          });
          
          sessionStorage.setItem('login', authData);
          localStorage.setItem(`isAnyOpenSession-${decoded.email}`, "true");
          
          const userInfo = {
            email: decoded.email,
            name: decoded.name,
            picture: decoded.picture,
            userId: data.userId
          };
          localStorage.setItem('userInfo', JSON.stringify(userInfo));

          try {
            const refreshResponse = await fetch(`${baseUrl}auth/refresh/${data.userId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              }
            });

            if (refreshResponse.ok) {
              const refreshData = await refreshResponse.json();
              localStorage.setItem('token', refreshData.token || '');
              localStorage.setItem('refreshToken', refreshData.refreshToken || '');
            }
          } catch (refreshError) {
            Swal.fire({
              title: 'Warning',
              text: 'Failed to get refresh token',
              icon: 'warning'
            });
          }
          
          navigate('/');
        } else {
          throw new Error('Unexpected response format or missing userId');
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Failed to authenticate with Google',
          icon: 'error'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Google login failed',
        icon: 'error'
      });
    }
  };

  const handleGoogleError = () => {
    Swal.fire({
      title: 'Error',
      text: 'Google login failed',
      icon: 'error'
    });
  };

  return (
    <div className="w-full flex justify-center my-4">
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleError}
        useOneTap
        theme="outline"
        size="large"
        text="continue_with"
        shape="rectangular"
        locale="en"
      />
    </div>
  );
};

export default GoogleLoginButton; 