import React, { useState, useEffect, useRef } from 'react';
import { baseUrl } from '../config/axios.config';
import { motion, AnimatePresence } from 'framer-motion';
import { QRCodeModal } from '../components/Modals/QRCodeModal';
import { QRCodeSVG } from 'qrcode.react';
import axios from 'axios';

// QR kod importları
import canvasGrayGlossyQR from '../assets/qrCodes/canvas-gray-glossy-black.png';
import canvasGrayMapleQR from '../assets/qrCodes/canvas-gray-silver-maple-natural.png';
import canvasGrayCardboardQR from '../assets/qrCodes/canvas-gray-cardboard.png';
import canvasGrayPlywoodQR from '../assets/qrCodes/canvas-gray-plywood-weathered.png';

import tweedSilverGlossyQR from '../assets/qrCodes/tweed-glossy-black.png';
import tweedSilverMapleQR from '../assets/qrCodes/tweed-silver-maple-natural.png';
import tweedSilverCardboardQR from '../assets/qrCodes/tweed-cardboard.png';
import tweedSilverPlywoodQR from '../assets/qrCodes/tweed-plywood-weathered.png';

import stripesGlossyQR from '../assets/qrCodes/stripes-black-blue-gray-glossy-black.png';
import stripesMapleQR from '../assets/qrCodes/stripes-black-blue-gray-silver-maple-natural.png';
import stripesCardboardQR from '../assets/qrCodes/stripes-black-blue-gray-cardboard.png';
import stripesPlywoodQR from '../assets/qrCodes/stripes-black-blue-gray-plywood-weathered.png';

import canvasBrownGlossyQR from '../assets/qrCodes/canvas-brown-glossy-black.png';
import canvasBrownMapleQR from '../assets/qrCodes/canvas-brown-silver-maple-natural.png';
import canvasBrownCardboardQR from '../assets/qrCodes/canvas-brown-cardboard.png';
import canvasBrownPlywoodQR from '../assets/qrCodes/canvas-brown-plywood-weathered.png';
import Footer from '../components/Footer/Footer';

type CushionType = 'Canvas Gray' | 'Tweed Silver' | 'Stripes Black Blue' | 'Canvas Brown';
type LegType = 'Glossy Black' | 'Silver Maple Natural' | 'Cardboard' | 'Plywood Weathered';

type ModelUrnsType = {
  [key in CushionType]: {
    [key in LegType]: string;
  };
};

type CushionMaterial = {
  name: CushionType;
  hex: string;
  texture: string;
};

type LegMaterial = {
  name: LegType;
  price: string;
};

const ProductExplorer: React.FC = () => {
  const [selectedCushion, setSelectedCushion] = useState<CushionType>('Canvas Brown');
  const [selectedLeg, setSelectedLeg] = useState<LegType>('Cardboard');
  const [showHint, setShowHint] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [iframeError, setIframeError] = useState(false);
  const [isServerHealthy, setIsServerHealthy] = useState<boolean>(false);
  const [isCheckingHealth, setIsCheckingHealth] = useState<boolean>(true);
  const [errorType, setErrorType] = useState({ code: 0, message: '' });
  const hintTimeoutRef = useRef<NodeJS.Timeout>();

  // URL mapping object for all combinations
  const modelUrns: ModelUrnsType = {
    'Canvas Gray': {
      'Glossy Black': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYV9HdWlkX2I4YzQ3ZDY3LTNlYjUtNDUyMC1hNWQzLWM1OTc2NTUyYjdhNC5pcHQ',
      'Silver Maple Natural': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYV9HdWlkX2I4YzQ3ZDY3LTNlYjUtNDUyMC1hNWQzLWM1OTc2NTUyYjdhNC5pcHQ',
      'Cardboard': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYV9HdWlkX2I4YzQ3ZDY3LTNlYjUtNDUyMC1hNWQzLWM1OTc2NTUyYjdhNC5pcHQ',
      'Plywood Weathered': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYV9HdWlkX2I4YzQ3ZDY3LTNlYjUtNDUyMC1hNWQzLWM1OTc2NTUyYjdhNC5pcHQ'
    },
    'Tweed Silver': {
      'Cardboard': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBUd2VlZCUyMC0lMjBDYXJkYm9hcmRfR3VpZF9lNjBjODE3ZC04YzFjLTQ4NzMtYWY3My0yNjUzNmU2M2MyMjAuaXB0',
      'Glossy Black': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBUd2VlZCUyMC0lMjBHbG9zc3klMjBCbGFja19HdWlkXzNkOGFhZDc0LTM1N2QtNGY2Yy05NzM0LWFhMDMxOTEyOTE3YS5pcHQ',
      'Plywood Weathered': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBUd2VlZCUyMC0lMjBQbHl3b29kJTIwV2VhdGhlcmVkX0d1aWRfMTJhYzU1NjAtMDRmYi00NDk3LWEwOTAtYjcyNGI0N2FiZTFkLmlwdA',
      'Silver Maple Natural': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBUd2VlZCUyMC0lMjBTaWx2ZXIlMjBNYXBsZSUyME5hdHVyYWxfR3VpZF82NzRlZDEzMC0wNjYyLTQzMGQtYTg2OS1kYzA3ZTcxZjY4MjEuaXB0'
    },
    'Stripes Black Blue': {
      'Cardboard': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBTdHJpcGVzJTIwQmxhY2slMjBCbHVlJTIwLSUyMENhcmRib2FyZF9HdWlkXzgwNjc1MDM3LWMxMzYtNGQyZi04Mzg3LTE3ZTM1OTFjZmQ1Zi5pcHQ',
      'Glossy Black': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBTdHJpcGVzJTIwQmxhY2slMjBCbHVlJTIwLSUyMEdsb3NzeSUyMEJsYWNrX0d1aWRfMTQxMzkzMWEtMDkxMy00MGE3LTg2YzAtYTZkODU2NzA4ZTM3LmlwdA',
      'Plywood Weathered': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBTdHJpcGVzJTIwQmxhY2slMjBCbHVlJTIwLSUyMFBseXdvb2QlMjBXZWF0aGVyZWRfR3VpZF8zYWUxMWQ1My03OTM0LTRlNTAtYWRkYS00ZGZiNWYzYzMzNjcuaXB0',
      'Silver Maple Natural': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBTdHJpcGVzJTIwQmxhY2slMjBCbHVlJTIwLSUyMFNpbHZlciUyME1hcGxlJTIwTmF0dXJhbF9HdWlkXzg0NzA2ZTI0LTdmZTktNGE2OC1hYTkzLThiYzRiOTRhNjJhOS5pcHQ'
    },
    'Canvas Brown': {
      'Cardboard': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBDYW52YXMlMjBCcm93biUyMC0lMjBDYXJkYm9hcmRfR3VpZF84OGQwY2I4OS0zN2FlLTQ3ZjEtYjcyZS04YzdkNjljNmM2ZDEuaXB0',
      'Glossy Black': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBDYW52YXMlMjBCcm93biUyMC0lMjBHbG9zc3klMjBCbGFja19HdWlkX2IyMDQ0MzFkLWM4YjUtNDY1Mi1iOGYwLTk0NmUwNmUzYTExMS5pcHQ',
      'Plywood Weathered': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBDYW52YXMlMjBXaGl0ZSUyMC0lMjBQbHl3b29kJTIwV2VhdGhlcmVkX0d1aWRfZGRjMzA2NTUtNGEyOS00MTE1LWI0OTUtNWRhYTUxNTE2NWJhLmlwdA',
      'Silver Maple Natural': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL2VrZW5hJTIwc29mYSUyMC0lMjBDYW52YXMlMjBXaGl0ZSUyMC0lMjBTaWx2ZXIlMjBNYXBsZSUyME5hdHVyYWxfR3VpZF8xYTk1N2YyMC04MThmLTQyNGQtYTQ4Zi1kNWFlYWNiMmZkOTMuaXB0'
    }
  };

  // Get the current URN based on selections
  const getCurrentUrn = () => {
    return modelUrns[selectedCushion]?.[selectedLeg] || 
      'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYV9HdWlkX2I4YzQ3ZDY3LTNlYjUtNDUyMC1hNWQzLWM1OTc2NTUyYjdhNC5pcHQ';
  };

  // Generate viewer URL with current URN
  const viewerUrl = `${baseUrl}objects/content/${getCurrentUrn()}/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/backgroundColor=fcfcfd&isAnimated=false&hideBottomToolBar=true&activeTools=viewcube,homeViewWrappe&lightPreset=1&showWatermark=false&setGroundShadow=true&setGroundReflection=false`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Single health check when component mounts
  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        setIsCheckingHealth(true);
        const response = await axios.get(`${baseUrl}health`);
        if (response.data.status === "Healthy") {
          setIsServerHealthy(true);
          setIframeError(false);
          setErrorType({ code: 0, message: '' });
        } else {
          setIsServerHealthy(false);
          setIframeError(true);
          setErrorType({
            code: -1,
            message: 'VEEWER service is currently unavailable. Please try again later.'
          });
        }
      } catch (error) {
        setIsServerHealthy(false);
        setIframeError(true);
        setErrorType({
          code: -1,
          message: 'VEEWER service is currently unavailable. Please try again later.'
        });
      } finally {
        setIsCheckingHealth(false);
      }
    };

    checkServerHealth();
  }, []);

  useEffect(() => {
    if (isModelLoaded && !showHint) {
      setShowHint(true);
      hintTimeoutRef.current = setTimeout(() => {
        setShowHint(false);
      }, 3000);
    }

    return () => {
      if (hintTimeoutRef.current) {
        clearTimeout(hintTimeoutRef.current);
      }
    };
  }, [isModelLoaded]);

  // Remove the separate iframe URL check
  useEffect(() => {
    if (isServerHealthy) {
      setIframeError(false);
    }
  }, [viewerUrl, isServerHealthy]);

  const handleIframeLoad = () => {
    setIsModelLoaded(true);
    setIframeError(false);
  };
  
  const handleIframeError = () => {
    console.error('ProductExplorer - iframe loading error for URL:', viewerUrl);
    setIframeError(true);
    setIsModelLoaded(false);
  };

  const cushionMaterials: CushionMaterial[] = [
    { name: 'Canvas Brown', hex: '#7f5e40', texture: 'Canvas' },
    { name: 'Canvas Gray', hex: '#b7baba', texture: 'Canvas' },
    { name: 'Tweed Silver', hex: '#1d1a15', texture: 'Tweed' },
    { name: 'Stripes Black Blue', hex: '#a3bac9', texture: 'Stripes' },
  ];

  const legMaterials: LegMaterial[] = [
    { name: 'Cardboard', price: '+$0' },
    { name: 'Glossy Black', price: '+$199' },
    { name: 'Plywood Weathered', price: '+$299' },
    { name: 'Silver Maple Natural', price: '+$399' }
  ];

  const features = [
    { title: 'Dimensions', value: '121cm x 76cm x 78cm', icon: '📏' },
    { title: 'Seating', value: '1-2 People', icon: '👥' },
    { title: 'Frame', value: 'Solid Wood', icon: '🪑' },
    { title: 'Warranty', value: '5 Years', icon: '✨' }
  ];

  // QR kodları için mapping
  const qrCodes: ModelUrnsType = {
    'Canvas Gray': {
      'Glossy Black': canvasGrayGlossyQR,
      'Silver Maple Natural': canvasGrayMapleQR,
      'Cardboard': canvasGrayCardboardQR,
      'Plywood Weathered': canvasGrayPlywoodQR
    },
    'Tweed Silver': {
      'Glossy Black': tweedSilverGlossyQR,
      'Silver Maple Natural': tweedSilverMapleQR,
      'Cardboard': tweedSilverCardboardQR,
      'Plywood Weathered': tweedSilverPlywoodQR
    },
    'Stripes Black Blue': {
      'Glossy Black': stripesGlossyQR,
      'Silver Maple Natural': stripesMapleQR,
      'Cardboard': stripesCardboardQR,
      'Plywood Weathered': stripesPlywoodQR
    },
    'Canvas Brown': {
      'Glossy Black': canvasBrownGlossyQR,
      'Silver Maple Natural': canvasBrownMapleQR,
      'Cardboard': canvasBrownCardboardQR,
      'Plywood Weathered': canvasBrownPlywoodQR
    }
  };

  // Mevcut QR kodunu al
  const getCurrentQR = () => {
    return qrCodes[selectedCushion]?.[selectedLeg] || canvasBrownCardboardQR; // default QR
  };

  // Get current AR URL
  const getCurrentARUrl = () => {
    return `https://ar.veewer.com/?userId=27b241fc-0aa1-4f78-8339-1072735f1472&modelId=${modelUrns[selectedCushion]?.[selectedLeg]}`;
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1 bg-[#F8F7F4]">
        <div className="container max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-12 py-4 sm:py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-x-16 mb-4">
            {/* Left Column - 3D Viewer */}
            <div className="h-[40vh] md:h-auto flex items-center">
              <div className="relative w-full h-full rounded-2xl md:rounded-[2rem] overflow-hidden bg-white shadow-[0_8px_40px_rgb(0,0,0,0.08)]">
                <AnimatePresence>
                  {showHint && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="absolute inset-0 flex items-center justify-center pointer-events-none z-20"
                    >
                      <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-black/70 backdrop-blur-sm rounded-xl p-4 lg:p-8 text-white text-center max-w-md mx-4 border border-white/10"
                      >
                        <motion.div 
                          animate={{ rotate: [0, -10, 10, -10, 0] }}
                          transition={{ duration: 2 }}
                          className="text-2xl lg:text-4xl mb-4 flex justify-center gap-6"
                        >
                          <span>🖱️</span>
                          <span>🔄</span>
                          <span>🔍</span>
                        </motion.div>
                        <p className="text-lg lg:text-xl font-medium mb-2 lg:mb-3">Interact with 3D Model</p>
                        <p className="text-sm lg:text-base opacity-90">
                          • Drag to rotate<br/>
                          • Scroll to zoom in/out<br/>
                          • Right-click to pan
                        </p>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
                
                {isCheckingHealth ? (
                  <div className="absolute inset-0 flex items-center justify-center bg-white">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500" />
                  </div>
                ) : isServerHealthy ? (
                  <iframe
                    title="Ekana Sofa 3D Model Viewer"
                    src={viewerUrl}
                    className="w-full h-full border-0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    loading="eager"
                    onLoad={handleIframeLoad}
                    onError={handleIframeError}
                  />
                ) : (
                  <div className="absolute inset-0 flex items-center justify-center bg-white">
                    <div className="bg-white p-6 rounded-xl shadow-lg text-center max-w-md mx-4 border border-gray-200">
                      <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-red-50 rounded-full">
                        <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <h3 className="text-xl font-bold text-gray-800 mb-3">
                        VEEWER Unavailable
                      </h3>
                      <p className="text-gray-600 mb-6">
                        {errorType.message}
                      </p>
                      <div className="flex flex-col space-y-3">
                        <button
                          onClick={() => {
                            setIsCheckingHealth(true);
                            setIsServerHealthy(false);
                            axios.get(`${baseUrl}health`)
                              .then(response => {
                                if (response.data.status === "Healthy") {
                                  setIsServerHealthy(true);
                                  setIframeError(false);
                                  setErrorType({ code: 0, message: '' });
                                }
                              })
                              .catch(() => {
                                setIsServerHealthy(false);
                                setIframeError(true);
                                setErrorType({
                                  code: -1,
                                  message: 'VEEWER service is currently unavailable. Please try again later.'
                                });
                              })
                              .finally(() => {
                                setIsCheckingHealth(false);
                              });
                          }}
                          className="w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition-colors shadow-md flex items-center justify-center gap-2"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                          </svg>
                          Try Again
                        </button>
                        <button
                          onClick={() => window.location.href = 'mailto:contact@veewer.com'}
                          className="w-full bg-gray-100 text-gray-700 px-4 py-3 rounded-lg hover:bg-gray-200 transition-colors flex items-center justify-center gap-2"
                        >
                          Contact Support
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Right Column - Product Details */}
            <div className="flex flex-col h-auto">
              <div className="flex-1 bg-white rounded-2xl md:rounded-[2rem] p-4 md:p-8 shadow-[0_8px_40px_rgb(0,0,0,0.03)]">
                <div className="h-full flex flex-col">
                  <div className="space-y-3 md:space-y-4">
                    <div className="flex justify-between items-start">
                      <div>
                        <span className="inline-block px-3 py-1 bg-amber-100 text-amber-800 text-[10px] md:text-xs font-medium rounded-full">Premium Collection</span>
                        <h1 className="mt-2 text-2xl md:text-3xl font-bold text-gray-900">Ekana Sofa</h1>
                        <div className="mt-1 flex items-baseline">
                          <span className="text-xl md:text-2xl font-light text-gray-900">$2,499</span>
                          <span className="ml-2 md:ml-3 text-[10px] md:text-xs text-gray-500">or $208/mo</span>
                        </div>
                      </div>

                      {/* QR Code Section */}
                      <div className="flex flex-col items-center">
                        <a 
                          href={getCurrentARUrl()}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="group"
                        >
                          {/* Desktop QR Code Button */}
                          <div className="hidden md:block w-32 h-32 bg-gradient-to-r from-amber-50 to-orange-50 rounded-lg p-2 shadow-sm">
                            <div className="w-full h-full bg-white rounded-md flex items-center justify-center">
                              <QRCodeSVG 
                                value={getCurrentARUrl()}
                                size={120}
                                level="L"
                                includeMargin={true}
                                bgColor="#FFFFFF"
                                fgColor="#000000"
                              />
                            </div>
                          </div>
                          {/* Mobile/Tablet AR Button */}
                          <div className="md:hidden">
                            <div className="w-24 h-24 bg-gradient-to-br from-amber-500 to-amber-600 rounded-lg flex flex-col items-center justify-center gap-2 transition-all duration-200 active:scale-95 hover:shadow-lg">
                              <svg className="w-8 h-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 22V12h6v10" />
                              </svg>
                              <span className="text-white text-sm font-medium">View in AR</span>
                            </div>
                          </div>
                          <p className="mt-1 text-[10px] text-center text-gray-500 hidden md:block">
                            Scan QR for AR View
                          </p>
                        </a>
                      </div>
                    </div>

                    <p className="text-sm md:text-base text-gray-600 leading-relaxed border-b border-gray-100 pb-3">
                      Experience unparalleled comfort with the Ekana Sofa. Meticulously crafted with premium materials, 
                      making it the perfect centerpiece for your living space.
                    </p>

                    {/* Cushion Material Selector */}
                    <div className="space-y-2">
                      <h3 className="text-xs md:text-sm font-medium text-gray-900">Select Cushion Material</h3>
                      <div className="flex items-start space-x-3 md:space-x-4">
                        {cushionMaterials.map((material) => (
                          <button
                            key={material.name}
                            className={`group flex flex-col items-center ${
                              selectedCushion === material.name ? 'relative' : ''
                            }`}
                            onClick={() => setSelectedCushion(material.name as CushionType)}
                          >
                            <span
                              className={`h-8 w-8 md:h-10 md:w-10 rounded-lg md:rounded-xl border-2 transition-transform group-hover:scale-110 duration-200 ${
                                selectedCushion === material.name ? 'border-amber-500 shadow-md' : 'border-transparent'
                              }`}
                              style={{ backgroundColor: material.hex }}
                            />
                            <span className="mt-1 text-[8px] md:text-[10px] font-medium text-gray-700">{material.name}</span>
                            <span className="text-[8px] md:text-[10px] text-gray-500">{material.texture}</span>
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Leg Material Selector */}
                    <div className="space-y-2">
                      <h3 className="text-xs md:text-sm font-medium text-gray-900">Select Leg Material</h3>
                      <div className="grid grid-cols-2 gap-2 md:gap-3">
                        {legMaterials.map((material) => (
                          <button
                            key={material.name}
                            className={`p-2 md:p-3 text-left rounded-lg md:rounded-xl transition-all duration-200 ${
                              selectedLeg === material.name
                                ? 'bg-amber-50 border-2 border-amber-500'
                                : 'bg-gray-50 hover:bg-gray-100 border-2 border-transparent'
                            }`}
                            onClick={() => setSelectedLeg(material.name as LegType)}
                          >
                            <span className="block text-xs md:text-sm font-medium text-gray-900">{material.name}</span>
                            <span className="text-xs md:text-sm text-amber-600">{material.price}</span>
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Features */}
                    <div className="grid grid-cols-2 gap-3 md:gap-4">
                      {features.map((feature) => (
                        <div key={feature.title} className="flex items-start space-x-2">
                          <span className="text-base md:text-lg">{feature.icon}</span>
                          <div>
                            <dt className="text-[10px] md:text-xs font-medium uppercase text-gray-500">{feature.title}</dt>
                            <dd className="text-xs md:text-sm font-medium text-gray-900">{feature.value}</dd>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Bottom Section */}
                  <div className="mt-auto space-y-2 md:space-y-3 pt-3 md:pt-4">
                    <div className="flex space-x-2 md:space-x-3">
                      <button
                        type="button"
                        className="flex-1 bg-amber-500 rounded-lg md:rounded-xl py-2.5 md:py-3 px-4 md:px-6 flex items-center justify-center text-xs md:text-sm font-medium text-white hover:bg-amber-600 transition-colors shadow-lg shadow-amber-500/20"
                      >
                        Add to Cart
                      </button>
                      <button
                        type="button"
                        className="w-10 h-10 md:w-12 md:h-12 flex items-center justify-center rounded-lg md:rounded-xl border-2 border-gray-200 hover:border-amber-500 transition-colors"
                      >
                        <svg className="w-4 h-4 md:w-5 md:h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                      </button>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-1">
                        <svg className="h-3 w-3 md:h-4 md:w-4 text-amber-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="text-xs md:text-sm text-gray-600">Free White Glove Delivery</span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <svg className="h-3 w-3 md:h-4 md:w-4 text-amber-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="text-xs md:text-sm text-gray-600">30-Day Home Trial</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QRCodeModal 
        open={showQRModal}
        onClose={() => setShowQRModal(false)}
        qrValue={getCurrentARUrl()}
      />
    </div>
  );
};

export default ProductExplorer;