import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Modal } from 'react-bootstrap';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CardItem from '../../components/CardItem';
import ConfirmModal from '../../components/Modals/ConfirmDeleteModal';
import MultipleUploadModal from '../../components/MultipleUploadModal/MultipleUploadModal';
import TextAreaModal from '../../components/Modals/TextAreaModal';
import Unauthorized from '../../components/Unauthorized';
import { GetSubUploadedObjectResponseModel, GetUploadedObjectResponseModel } from '../../models/object/uploadedObject/get.uploadedObject.response.model';
import { GetUploadedObjectHtmlContentService } from '../../services/uploadedObject/get.uploadedObject.html.content.service';
import { GetUploadedObjectModelViewService } from '../../services/uploadedObject/get.uploadedObject.model.view.service';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { getUploadedObjectByUserId, getUploadedObjects, updateWithDeleteObject, getUploadedObjectByUserIdSilent } from '../../store/features/object/get.list.uploadedObject.slice';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import DWFX from '../../assets/DWFX.png'
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import MODEL from '../../assets/MODEL.png';
import MAX from '../../assets/MAX.png';
import STL from '../../assets/STL.png'
import STP from '../../assets/STP.png'
import SLDASM from '../../assets/SLDASM.png'
import RVT from '../../assets/RVT.jpg'
import DWF from '../../assets/DWF.png'
import F3D from '../../assets/f3d.png'
import GLB from '../../assets/GLB.png'
import OBJ from '../../assets/obj.png'
import PSM from '../../assets/psm.png'
import STEP from "../../assets/STEP.png"
import IDW from "../../assets/IDW.png"
import UNDEFINEDIMAGE from "../../assets/undefined.png"
import { baseUrl } from '../../config/axios.config';
import '../../styles/objectList/objectList.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { LoginResponse } from '../../models/auth/login.model';
import { deleteUploadedObject } from '../../store/features/object/delete.object.slice';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import JSZip from 'jszip';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Typography, CircularProgress } from '@mui/material';
import CircleLoading from '../../components/Loading/CircleLoading';
import { checkCreditUploadedObject, resetCreditError } from '../../store/features/object/check.credit.slice';
import { checkCreditUploadedObjectWhenUpload } from '../../store/features/object/check.credit.when.upload.slice';
import ChangeNameModal from '../../components/Modals/ChangeNameModal';
import { updateUploadedObjectName } from '../../store/features/object/update.uploaded.object.name.slice';
import { DeleteModelsService } from '../../services/file/delete.models.service';
import { CheckManifestUploadedObjectService } from '../../services/uploadedObject/check.manifest.uploadeObject.service';
import { ProcessingUploadModel, ProcessUploadIterationResponseModel } from '../../models/object/uploadedObject/uploadV2/processing.upload.model';
import { FinalizeUploadModelsService } from '../../services/uploadedObject/uploadV2/finalize.upload.models.service';
import { ProcessingUploadModelService } from '../../services/uploadedObject/uploadV2/processing.upload.model.service';
import { GetContentRootPathService } from '../../services/file/get.content.root.path.service';
import { resetCreateState } from '../../store/features/object/create.uploadedObject.slice';
import path from 'path-browserify';
import { SearchAndFilter } from '../../components/SearchAndFilter';
import { UploadManager, UploadStage, UploadProgress } from '../../services/upload/UploadManager';
import LittleCircleLoading from '../../components/Loading/LittleCircleLoading';
import Footer from '../../components/Footer/Footer';
import { GetQrSourceCodeService } from '../../services/uploadedObject/get.qr.source.code.service';
import { QRCodeModal } from '../../components/Modals/QRCodeModal';
import { resetCreditWhenUploadError } from '../../store/features/object/check.credit.when.upload.slice';
import { resetStartUploadModelsError } from '../../store/features/object/uploadV2/starting.upload.models.slice';


export interface SelectableZipFiles {
  mainZipFile: File
  subFiles: SelectableSubFiles[]

}

export interface SelectableSubFiles {
  fileName: string
  fileSize: number,
  file: File
  isOversized: boolean
}

export interface StatusValues {
  fileName: string,
  status: string,
}

const ObjectList: React.FC = () => {
  const [uploadModal, setUploadModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [webSite, setWebSite] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedObjectId, setSelectedObjectId] = useState('');
  const [textAreaModal, setTextAreaModal] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState('');
  const [fileLimit, setFileLimit] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');
  const [selectedDeleteValue, setSelectedDeleteValue] = useState('');
  const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>(undefined)
  const [openZipOption, setOpenZipOption] = useState(false);
  const [extractZipFileStatus, setExtractZipFileStatus] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const objectListSelectorByUserId = useAppSelector((s: RootState) => s.getUploadedObjectByUserId)
  const selectedObject = useAppSelector((s: RootState) => s.getUploadedObject);
  const selectedObjectByBucket = useAppSelector((s: RootState) => s.getUploadedObjectByBucketAndName);
  const deleteSelectedObject = useAppSelector((s: RootState) => s.deleteObjectSlice)

  const bucketKey = sessionStorage.getItem('b') as string;
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse
  const [openSupportedFormats, setOpenSupportedFormats] = useState(false);
  
  const supportedFormats = [
    "3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
    "DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG*", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
    "GLB", "GLTF", "IAM", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
    "NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT", "SAB", "SAT",
    "SCH", "SESSION", "SKP", "SLDASM", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
  ];
  const actualSupportedFormats = [
    "3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
    "DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
    "GLB", "GLTF", "ZIP", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
    "NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT", "SAB", "SAT",
    "SCH", "SESSION", "SKP", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
  ];
  const [invalidFileErrorMessage, setInvalidFileErrorMessage] = useState("")
  const MAX_FILE_SIZE_GB = 1;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_GB * 1024 * 1024 * 1024;

  const isFileSizeValid = (file: File): boolean => {
    return file.size <= MAX_FILE_SIZE_BYTES;
  };

  const checkFileExtension = (file: File): boolean => {
    const fileExtension = file.name.split('.').pop()?.toUpperCase();
    return fileExtension ? actualSupportedFormats.includes(fileExtension) : false;
  };

  const MAX_COUNT = 15;
  const UploadFilesSwal = withReactContent(Swal);
  const [creditWarnings, setCreditWarnings] = useState("")
  const [openChangeModal, setOpenChangeModal] = useState(false)
  const [waitingForManifest, setWaitingForManifest] = useState(true); // Manifest bekleme durumu
  const [currentUploadIndex, setCurrentUploadIndex] = useState(0); //
  const [isFinishedToLoading, setIsFinishedToLoading] = useState(false);
  const [startToGetChunkStatus, setStartToGetChunkStatus] = useState(false);
  const [processToUpload, setProcessToUpload] = useState(false);
  const [steppedFileName, setSteppedFileName] = useState("")
  const [afterUploadError, setAfterUploadError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false); // Mevcut işlem kontrolü
  const startUploadModelsSelector = useAppSelector((s: RootState) => s.startUploadModelsSlice)
  const [chunks, setChunks] = useState<ProcessingUploadModel[]>([])
  const [manifestStatus, setManifestStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState("");
  const [isStarted, setIsStarted] = useState(false);
  const [processedFiles, setProcessedFiles] = useState<UploadedFile[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [statusValues, setStatusValues] = useState<StatusValues[]>([]);
  const [isProcessingCancelled, setIsProcessingCancelled] = useState(false);
  const processingLock = useRef(new Set<string>());
  const processingQueue = useRef<UploadedFile[]>([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [pausedFiles, setPausedFiles] = useState<UploadedFile[]>([]);
  const [isPaused, setIsPaused] = useState(false);
  const [isQrEnabled, setIsQrEnabled] = useState(false);
  const [qrCodeData, setQrCodeData] = useState('');
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);

  // Upload işlemleri için global abort controller ve durumu
  const [isUploadCancelled, setIsUploadCancelled] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  
  // Component mount olduğunda yeni bir AbortController oluştur
  useEffect(() => {
    abortControllerRef.current = new AbortController();
    
    // Component unmount olduğunda abort işlemi yap
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  // Upload işleminde olan modeller var mı kontrolü için yardımcı fonksiyon
  const hasActiveUploads = (): boolean => {
    const uploadManager = UploadManager.getInstance();
    const currentProgress = uploadManager.getCurrentProgress();
    
    // Upload işleminde olan dosyalar var mı kontrol et
    // Array.from ile Map.entries() dönüşümü yapıp kontrol ediyoruz
    return Array.from(currentProgress.entries()).some(([fileName, progress]) => (
      progress.stage === UploadStage.UPLOADING ||
      progress.stage === UploadStage.PROCESSING ||
      progress.stage === UploadStage.PREPARING ||
      progress.stage === UploadStage.MANIFEST_PROCESSING
    ));
  };

  const handleCloseModal = async () => {
    // Upload işleminde dosya var mı kontrol et
    if (hasActiveUploads()) {
      // Kullanıcıya onay alert'i göster
      const userConfirmed = window.confirm(
        "You have uploads in progress. If you close this modal, all uploads will be cancelled. Are you sure you want to continue?"
      );
      
      // Kullanıcı onay vermediyse modalı kapatma
      if (!userConfirmed) {
        return;
      }
      
      // Kullanıcı onay verdiyse tüm upload işlemlerini iptal et
      setIsUploadCancelled(true); // Global flag'i true yap - yeni istekleri engeller
      
      // Abort controller'ı tetikle - tüm fetch işlemlerini iptal eder
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        // Yeni bir controller oluştur (ileride kullanılabilecek işlemler için)
        abortControllerRef.current = new AbortController();
      }
      
      // UploadManager'dan tüm aktif upload işlemlerini iptal et
      const uploadManager = UploadManager.getInstance();
      uploadManager.abortAllUploads();
    }
    
    // Reset all upload-related states
    const uploadManager = UploadManager.getInstance();
    uploadManager.clearAll();
    
    setSelectedFiles([]);
    setIsStarted(false);
    setIsPaused(false);
    setPausedFiles([]);
    setUploadModal(false);
    setZipFile(undefined);
    setOpenZipOption(false);
    setExtractZipFileStatus(false);
    setCreditWarnings("");
    setManifestStatus("");
    setSteppedFileName("");
    setStep("");
    setInvalidFileErrorMessage("");
    setIsFinishedToLoading(false);
    setAfterUploadError("");
    setStartToGetChunkStatus(false);
    setProcessToUpload(false);
    setIsProcessing(false);
    setWaitingForManifest(false);
    setStatusValues([]);
    
    // Reset Redux states
    dispatch(resetCreateState());
    dispatch(resetCreditError());
    dispatch(resetCreditWhenUploadError());
    dispatch(resetStartUploadModelsError());
    
    // Modal kapatıldıktan sonra cancellation flag'ini temizle
    setIsUploadCancelled(false);
    
    // Force a re-fetch of the object list - silent versiyonu kullanarak Welcome Back mesajını göstermiyoruz
    dispatch(getUploadedObjectByUserIdSilent(jsonLoginInfo.uid));
  };

  // Browser'ın beforeunload event listener'ını ekle
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const uploadManager = UploadManager.getInstance();
      const allProgress = uploadManager.getCurrentProgress();
      const hasActiveUploads = Array.from(allProgress.values()).some(p => 
        p.stage !== UploadStage.COMPLETED && 
        p.stage !== UploadStage.ERROR && 
        p.stage !== UploadStage.PAUSED
      );

      if (hasActiveUploads) {
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  function generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const processZipFile = async (file: File): Promise<SelectableZipFiles | undefined> => {
    try {
      setExtractZipFileStatus(true);
      const zip = new JSZip();
      const zipContents = await zip.loadAsync(file);
      const subFiles: SelectableSubFiles[] = [];
      let hasLargeFile = false;
      let largeFiles: string[] = [];
      let totalSize = 0;

      for (const [fileName, zipEntry] of Object.entries(zipContents.files)) {
        if (!zipEntry.dir) {
          const fileData = await zipEntry.async('blob');
          const subFile = new File([fileData], fileName);
          
          // Boyut hesaplamaları (bytes -> KB -> GB)
          const fileSizeKB = subFile.size / 1024;
          const fileSizeGB = fileSizeKB / (1024 * 1024); // KB -> GB
          totalSize += fileSizeKB;
          
          // 1GB kontrolü (1048576 KB = 1 GB)
          const isOversized = fileSizeKB > 1048576;
          if (isOversized) {
            hasLargeFile = true;
            largeFiles.push(`${fileName} (${fileSizeGB.toFixed(2)} GB)`);
          }

          subFiles.push({
            fileName: fileName,
            fileSize: fileSizeKB,
            file: subFile,
            isOversized: isOversized
          });
        }
      }

      if (hasLargeFile) {
        setExtractZipFileStatus(false);
        const totalSizeGB = (totalSize / (1024 * 1024)).toFixed(2);
        Swal.fire({
          title: 'Large Files Detected',
          html: `<div class="text-left">
                  <p class="mb-3">Some files in the ZIP archive exceed the 1GB size limit:</p>
                  <ul class="list-disc pl-5 mb-3">
                    ${largeFiles.map(f => `<li>${f}</li>`).join('')}
                  </ul>
                  <p class="mb-2">Total ZIP contents size: ${totalSizeGB} GB</p>
                  <p class="text-sm text-gray-600">Please ensure all files are under 1GB before uploading.</p>
                </div>`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
        return undefined;
      }

      const selectableZipFiles: SelectableZipFiles = {
        mainZipFile: file,
        subFiles: subFiles
      };

      setZipFile(selectableZipFiles);
      setExtractZipFileStatus(false);
      return selectableZipFiles;
    } catch (error) {
      console.error('Error processing zip file:', error);
      setExtractZipFileStatus(false);
      Swal.fire({
        title: 'Error Processing ZIP',
        text: 'Failed to process the ZIP file. Please ensure the file is not corrupted and try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return undefined;
    }
  };

  useEffect(() => {
    if (!isStarted || isProcessingCancelled) return;

    const processUnprocessedFiles = async () => {
      if (isProcessingFile) return;

      const uploadManagerInstance = UploadManager.getInstance();
      const currentProgress = uploadManagerInstance.getCurrentProgress();

      // İşlenmemiş dosyaları filtrele ve sıralı işle
      const unprocessedFiles = selectedFiles.filter(file => {
        const progress = currentProgress.get(file.file.name);
        // Dosya henüz işlenmemişse veya QUEUED durumundaysa
        return !progress || progress.stage === UploadStage.QUEUED;
      });

      if (unprocessedFiles.length > 0) {
        setIsProcessingFile(true);
        
        try {
          // İlk dosyayı işle
          const nextFile = unprocessedFiles[0];
          const currentFileProgress = currentProgress.get(nextFile.file.name);
            
          // Dosya zaten işleniyorsa veya hata aldıysa atla
          if (!(currentFileProgress?.stage === UploadStage.ERROR || 
              currentFileProgress?.stage === UploadStage.PAUSED ||
              currentFileProgress?.stage === UploadStage.COMPLETED)) {
            await processFiles([nextFile]);
          }
        } catch (error) {
          console.error('Error processing files:', error);
        } finally {
          setIsProcessingFile(false);
        }
      }
    };

    processUnprocessedFiles();
  }, [isStarted, selectedFiles, isProcessingCancelled, isProcessingFile]);

  const processFiles = async (files: UploadedFile[]) => {
    if (isProcessingCancelled || !isStarted || isProcessingFile) return;

    const uploadManager = UploadManager.getInstance();
    
    // First, validate all files
    const validFiles = files.filter(file => {
      const isValid = checkFileExtension(file.file);
      if (!isValid) {
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.ERROR,
          error: `Invalid file format: ${file.file.name}`,
          message: 'Unsupported file format'
        });
        console.error(`Invalid file format: ${file.file.name}`);
        return false;
      }
      return true;
    });

    try {
      // Process files sequentially
      for (const file of validFiles) {
        if (isProcessingCancelled || !isStarted) {
          break;
        }

        const fileExtension = file.file.name.split('.').pop()?.toUpperCase() || '';
        
        // Check credits
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.PREPARING,
          message: 'Checking available credits...'
        });

        try {
          const creditCheckResponse = await fetch(
            `${baseUrl}objects/creditStatus/${jsonLoginInfo.uid}/${fileExtension}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          if (!creditCheckResponse.ok) {
            const errorData = await creditCheckResponse.json().catch(() => ({}));
            uploadManager.updateProgress(file.file.name, {
              stage: UploadStage.ERROR,
              error: errorData.message || 'Failed to check credits',
              message: 'Credit check failed'
            });
            continue;
          }

          const creditStatus = await creditCheckResponse.json();
          if (!creditStatus.status) {
            uploadManager.updateProgress(file.file.name, {
              stage: UploadStage.PAUSED,
              error: creditStatus.message || 'Insufficient credits',
              message: 'Upload paused - Insufficient credits'
            });
            
            setPausedFiles(prev => [...prev, file]);
            setAfterUploadError(creditStatus.message || 'Insufficient credits');
            continue;
          }

          // Process file if credit check passed
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.PREPARING,
            message: 'Credit check successful, starting upload...'
          });

          await handleFileProcessing(file);
          setProcessedFiles(prev => [...prev, file]);

        } catch (error) {
          console.error(`Error processing file ${file.file.name}:`, error);
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.ERROR,
            error: error instanceof Error ? error.message : "Processing failed",
            message: 'Failed to process file'
          });
          continue;
        }
      }

      // Tüm dosyalar işlendiyse durumu güncelle
      const allProgress = uploadManager.getCurrentProgress();
      const remainingFiles = Array.from(allProgress.values()).filter(p => 
        p.stage !== UploadStage.COMPLETED && 
        p.stage !== UploadStage.ERROR && 
        p.stage !== UploadStage.PAUSED
      );

      if (remainingFiles.length === 0) {
        setIsStarted(false);
        setIsPaused(false);
      }

    } catch (error) {
      console.error('Error in processFiles:', error);
    }
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = Array.from(e.target.files || []);
    
    // Önce ZIP olmayan dosyaları kontrol et
    const nonZipFiles = files.filter(file => !file.name.endsWith('.zip'));
    const oversizedNonZipFiles = nonZipFiles.filter(file => !isFileSizeValid(file));
    if (oversizedNonZipFiles.length > 0) {
      Swal.fire({
        title: 'Files Too Large',
        html: `The following files exceed the maximum size limit of 1GB:<br/><br/>` +
              oversizedNonZipFiles.map(f => `- ${f.name}`).join('<br/>'),
        icon: 'error',
        confirmButtonText: 'OK'
      });
      e.target.value = '';
      return;
    }

    const uploadManager = UploadManager.getInstance();
    let newFilesAdded = false;

    // ZIP olmayan dosyaları ekle
    for (const file of nonZipFiles) {
      const newFile = { file, rootFileName: "" };
      setSelectedFiles(prev => [...prev, newFile]);
      newFilesAdded = true;
      
      uploadManager.updateProgress(file.name, {
        stage: UploadStage.QUEUED,
        message: 'Ready to upload',
        currentOperation: 'Waiting to start'
      });
    }

    // ZIP dosyalarını işle
    const zipFiles = files.filter(file => file.name.endsWith('.zip'));
    for (const file of zipFiles) {
      await processZipFile(file);
      setOpenZipOption(true);
    }

    e.target.value = '';

    if (newFilesAdded) {
      if (!isStarted) {
        setIsStarted(true);
      }
      setIsProcessingFile(false);
    }
  };

  const handleFileInputWithDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files || []);
    
    // Önce ZIP olmayan dosyaları kontrol et
    const nonZipFiles = files.filter(file => !file.name.endsWith('.zip'));
    const oversizedNonZipFiles = nonZipFiles.filter(file => !isFileSizeValid(file));
    if (oversizedNonZipFiles.length > 0) {
      Swal.fire({
        title: 'Files Too Large',
        html: `The following files exceed the maximum size limit of 1GB:<br/><br/>` +
              oversizedNonZipFiles.map(f => `- ${f.name}`).join('<br/>'),
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    // ZIP olmayan dosyaları ekle
    for (const file of nonZipFiles) {
      const newFile = { file, rootFileName: "" };
      setSelectedFiles(prev => [...prev, newFile]);
      
      const uploadManager = UploadManager.getInstance();
      uploadManager.updateProgress(file.name, {
        stage: UploadStage.QUEUED,
        message: 'Ready to upload',
        currentOperation: 'Waiting to start'
      });
    }

    // ZIP dosyalarını işle
    const zipFiles = files.filter(file => file.name.endsWith('.zip'));
    for (const file of zipFiles) {
      await processZipFile(file);
      setOpenZipOption(true);
    }

    e.dataTransfer.clearData();
  };

  const processNextInQueue = async () => {
    if (processingQueue.current.length === 0 || isProcessingQueue) {
      setIsProcessingQueue(false);
      
      // Kuyruk boşaldığında ve tüm dosyalar işlendiyse, listeyi güncelle
      const uploadManager = UploadManager.getInstance();
      const allProgress = uploadManager.getCurrentProgress();
      const allCompleted = Array.from(allProgress.values()).every(p => 
        p.stage === UploadStage.COMPLETED || p.stage === UploadStage.ERROR
      );
      
      if (allCompleted && allProgress.size > 0) {
        try {
          console.log('All files in queue processed, refreshing object list');
          await dispatch(getUploadedObjectByUserId(jsonLoginInfo.uid));
        } catch (error) {
          console.error('Error refreshing object list:', error);
        }
      }
      
      return;
    }

    setIsProcessingQueue(true);
    const nextFile = processingQueue.current[0];
    
    try {
      await handleFileProcessing(nextFile);
    } catch (error) {
      console.error('Error processing file:', error);
    }

    processingQueue.current.shift();
    setIsProcessingQueue(false);
    
    // Process next file if queue is not empty
    if (processingQueue.current.length > 0) {
      await processNextInQueue();
    }
  };

  const handleFileProcessing = async (file: UploadedFile) => {
    const uploadManager = UploadManager.getInstance();

    try {
      // 2. Prepare file chunks
      uploadManager.updateProgress(file.file.name, {
        stage: UploadStage.PREPARING,
        message: 'Preparing file for upload...',
        progress: 0
      });

      const guid = generateGUID();
      const chunks = await getChunksAsync(file, guid);
      if (!chunks) {
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.ERROR,
          error: `Failed to prepare chunks for file: ${file.file.name}`
        });
        throw new Error(`Failed to prepare chunks for file: ${file.file.name}`);
      }
      
      // 4. Upload chunks
      uploadManager.updateProgress(file.file.name, {
        stage: UploadStage.UPLOADING,
        message: 'Starting upload...',
        progress: 0
      });

      const uploadResult = await processToChunksAsync(chunks);
      if (!uploadResult || !uploadResult.response) {
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.ERROR,
          error: `Failed to process upload for file: ${file.file.name}`
        });
        throw new Error(`Failed to process upload for file: ${file.file.name}`);
      }

      uploadManager.updateProgress(file.file.name, {
        stage: UploadStage.PROCESSING,
        message: 'Finalizing upload...',
        currentOperation: 'Finalizing upload'
      });

      try {
        const finalizeResponse = await finalizeUploadAsync(uploadResult);
        
        uploadManager.handleFinalizeResponse(finalizeResponse, uploadResult.response?.objectKey || '');

        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.MANIFEST_PROCESSING,
          message: 'Processing file...',
          currentOperation: 'Processing file'
        });

        // 6. Start and monitor manifest processing
        if (finalizeResponse && finalizeResponse.urns && finalizeResponse.files && finalizeResponse.uid) {
          await checkManifestStatus(finalizeResponse.urns, finalizeResponse.files, finalizeResponse.uid);
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.COMPLETED,
            message: 'File successfully processed',
            progress: 100
          });
        } else {
          throw new Error('Missing required data for manifest processing');
        }

      } catch (error) {
        console.error('Error during finalization or manifest:', error);
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.ERROR,
          error: error instanceof Error ? error.message : 'Failed to finalize or process manifest'
        });
        throw error;
      }

    } catch (error) {
      console.error(`Error processing file: ${file.file.name}`, error);
      uploadManager.updateProgress(file.file.name, {
        stage: UploadStage.ERROR,
        error: error instanceof Error ? error.message : "Unknown error occurred"
      });
      setAfterUploadError(
        error instanceof Error ? error.message : "Unknown error occurred."
      );
      throw error;
    }
  };
  


  const getChunksAsync = async (file: UploadedFile, guid: string): Promise<ProcessingUploadModel | null> => {
    // Upload iptal edildiyse hiçbir işlem yapma
    if (isUploadCancelled) {
      console.log("Upload cancelled. Skipping getChunksAsync for", file.file.name);
      return null;
    }
    
    if (file !== undefined) {
      const uploadManager = UploadManager.getInstance();
      const service = new GetContentRootPathService();
      const firstChunkSize = 81920;
      let bytesRead = 0;
      const totalBytes = file.file.size;
      let firstFileName = "";
      let firstPath = "";
      let completed = false;

      try {
        while (completed === false) {
          // Upload iptal edildiyse döngüyü kır
          if (isUploadCancelled) {
            console.log("Upload cancelled during chunk processing");
            throw new Error("Upload cancelled by user");
          }
          
          const startByte = bytesRead;
          const endByte = Math.min(startByte + firstChunkSize, totalBytes);
          if (startByte >= totalBytes) {
            console.error("Start byte exceeds total bytes. Breaking the loop.");
            break;
          }
          const chunkBlob = file.file.slice(startByte, endByte);
          if (chunkBlob.size === 0) {
            console.error("Chunk blob size is zero. Breaking the loop.");
            break;
          }
          const chunkFile = new File([chunkBlob], file.file.name, { type: file.file.type });
         
          // AbortController'ı kullan - bu servis signal parametresi almıyorsa, sadece iptal kontrolü ile yönetiriz
          if (isUploadCancelled) {
            throw new Error("Upload cancelled by user");
          }
          
          const response = await service.GetContentRoot({
            rootFileName: file.rootFileName || "",
            file: chunkFile,
            bucketName: bucketKey,
            totalBytes: totalBytes,
            bytesRead: bytesRead,
            guid: guid
          });

          // Update progress using the rootPath response handler
          uploadManager.handleRootPathResponse(response, file.file.name);

          bytesRead = response.bytesRead;
          completed = response.completed;
          firstFileName = response.fileName;
          firstPath = response.path;
        }

        const fileName = file.file.name.replace(/[^a-zA-Z0-9]/g, '');
        const cleanFileName = fileName.substring(0, 50);
        const sessionId = `SI${cleanFileName}${Math.floor(Math.random() * 100000)}`;

        const chunkSize = 5 * 1024 * 1024; // 5 MB
        const length = file.file.size;
        const chunkCount = Math.round(0.5 + (length / chunkSize));
        
        return {
          dataLength: length,
          chunkCount: chunkCount,
          dataWrited: 0,
          startByte: 0,
          chunkIndex: 0,
          sessionId: sessionId,
          saveFileName: firstFileName,
          saveFilePath: firstPath,
          rootFileName: file.rootFileName || "",
          bucketKey: bucketKey
        };
      } catch (error) {
        // Eğer error AbortError ise, bu upload'ın iptal edildiği anlamına gelir
        if (error instanceof DOMException && error.name === 'AbortError') {
          console.log("Upload was aborted:", file.file.name);
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.ERROR,
            error: 'Upload cancelled by user',
            message: 'Upload cancelled'
          });
        } else {
          console.error("Error in getChunksAsync:", error);
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.ERROR,
            error: error instanceof Error ? error.message : 'Unknown error'
          });
        }
        return null;
      }
    }
    return null;
  };

  const processToChunksAsync = async (chunk: ProcessingUploadModel) => {
    // Upload iptal edildiyse hiçbir işlem yapma
    if (isUploadCancelled) {
      console.log("Upload cancelled. Skipping processToChunksAsync for", chunk.saveFileName);
      throw new Error("Upload cancelled by user");
    }
    
    const uploadManager = UploadManager.getInstance();
    try {
      let iterationParameter: ProcessUploadIterationResponseModel = {
        startByte: chunk.startByte,
        response: undefined,
        progress: 0,
        dataWrited: 0
      };

      for (let i = 0; i < chunk.chunkCount; i++) {
        // Her döngüde iptal kontrolü yap
        if (isUploadCancelled) {
          console.log("Upload cancelled during chunk processing");
          throw new Error("Upload cancelled by user");
        }
        
        const service = new ProcessingUploadModelService();
        if (i === chunk.chunkIndex) {
          // AbortController'ı doğrudan kullanmak yerine iptal kontrolü yap
          if (isUploadCancelled) {
            throw new Error("Upload cancelled by user");
          }
          iterationParameter = await service.PostAsync(chunk);
        } else {
          const newChunk: ProcessingUploadModel = {
            startByte: iterationParameter.startByte,
            saveFileName: chunk.saveFileName,
            chunkIndex: chunk.chunkIndex,
            chunkCount: chunk.chunkCount,
            dataWrited: iterationParameter.dataWrited,
            sessionId: chunk.sessionId,
            saveFilePath: chunk.saveFilePath,
            bucketKey: chunk.bucketKey,
            dataLength: chunk.dataLength,
            rootFileName: chunk.rootFileName
          };
          // AbortController'ı doğrudan kullanmak yerine iptal kontrolü yap
          if (isUploadCancelled) {
            throw new Error("Upload cancelled by user");
          }
          iterationParameter = await service.PostAsync(newChunk);
        }

        // Update progress using the processUpload response handler
        uploadManager.handleProcessUploadResponse(iterationParameter, chunk.saveFileName || '');

        if (iterationParameter.progress === 100) {
          break;
        }
      }

      if (iterationParameter.progress === 100) {
        return iterationParameter;
      }
    } catch (error: any) {
      // Eğer error AbortError ise, bu upload'ın iptal edildiği anlamına gelir
      if (error instanceof DOMException && error.name === 'AbortError') {
        console.log("Upload was aborted:", chunk.saveFileName);
        uploadManager.updateProgress(chunk.saveFileName || '', {
          stage: UploadStage.ERROR,
          error: 'Upload cancelled by user',
          message: 'Upload cancelled'
        });
      } else {
        console.error("Error in processToChunksAsync:", error);
        uploadManager.updateProgress(chunk.saveFileName || '', {
          stage: UploadStage.ERROR,
          error: error.message || error.statusText
        });
      }
      throw error;
    }
  };

  const finalizeUploadAsync = async (list: ProcessUploadIterationResponseModel) => {
    // Upload iptal edildiyse hiçbir işlem yapma
    if (isUploadCancelled) {
      console.log("Upload cancelled. Skipping finalizeUploadAsync");
      throw new Error("Upload cancelled by user");
    }

    try {
      const service = new FinalizeUploadModelsService();
      // AbortController'ı doğrudan kullanmak yerine iptal kontrolü ile yönet
      if (isUploadCancelled) {
        throw new Error("Upload cancelled by user");
      }
      const response = await service.PostAsync([list]);
      return response;
    } catch (error) {
      // Eğer hata varsa ve iptal edilmişse
      if (isUploadCancelled) {
        console.log("Finalize was aborted");
        throw new Error("Upload cancelled by user");
      }
      console.error("Error in finalizeUploadAsync:", error);
      throw error;
    }
  };

  const checkManifestStatus = async (
    urns: string[],
    files: string[],
    uid: string,
  ) => {
    // Upload iptal edildiyse hiçbir işlem yapma
    if (isUploadCancelled) {
      console.log("Upload cancelled. Skipping checkManifestStatus");
      throw new Error("Upload cancelled by user");
    }

    const uploadManager = UploadManager.getInstance();
    
    const getOriginalFileName = (fileName: string) => {
      const cleanName = fileName.replace(/[ -]+Copy\(\d+\)_Guid_[a-f0-9-]+/i, '');
      return cleanName.replace(/_Copy_\d+/g, '')
                     .replace(/_Guid_[a-f0-9-]+/g, '')
                     .replace(/\(\d+\)/g, '')
                     .trim();
    };

    const originalFileName = getOriginalFileName(files[0]);
    const manifestService = new CheckManifestUploadedObjectService();
    let isRequestInProgress = false;
    let requestTimeoutId: NodeJS.Timeout | null = null;
    const REQUEST_TIMEOUT = 60000; // 60 seconds for each request
    const INTERVAL_DELAY = 30000; // Increased to 30 seconds between checks
    let retryCount = 0;
    const MAX_RETRIES = 5; // Increased max retries

    console.log('Starting checkManifestStatus with:', { urns, files, uid });
    console.log('Original file name:', originalFileName);
    console.log('Modified file name:', files[0]);

    // Initial delay before starting manifest checks
    await new Promise(resolve => setTimeout(resolve, 10000));

    return new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        // Global iptal kontrolü
        if (isUploadCancelled) {
          console.log("Upload cancelled during manifest check");
          clearInterval(intervalId);
          reject(new Error("Upload cancelled by user"));
          return;
        }

        if (!urns || urns.length === 0 || !uid) {
          console.log('Missing required data:', { urns, uid });
          clearInterval(intervalId);
          reject(new Error("Required data is missing"));
          return;
        }

        if (isRequestInProgress) {
          console.log('Request already in progress, skipping this interval');
          return;
        }

        isRequestInProgress = true;
        requestTimeoutId = setTimeout(() => {
          console.log('Individual request timeout reached');
          isRequestInProgress = false;
        }, REQUEST_TIMEOUT);

        try {
          console.log(`Making manifest request attempt ${retryCount + 1} with:`, [urns[0], files[0], uid]);
          // AbortController'ı doğrudan kullanmak yerine iptal kontrolü ile yönet
          if (isUploadCancelled) {
            throw new Error("Upload cancelled by user");
          }
          const response = await manifestService.GetByStringManyParamsAsync([urns[0], files[0], uid]);
          console.log('Manifest API Response:', response);
          
          // Reset retry count on successful response
          retryCount = 0;
          
          uploadManager.handleManifestResponse(response, originalFileName);
          
          if (response.progress === "complete") {
            console.log('Manifest processing complete');
            clearInterval(intervalId);
            if (requestTimeoutId) clearTimeout(requestTimeoutId);
            
            // Manifest complete olduktan sonra upload-finished endpoint'ine istek at
            try {
              console.log('Calling upload-finished endpoint with URN:', urns[0]);
               fetch(`${baseUrl}objects/upload-finished`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(urns[0])
              });
              
            
            } catch (uploadFinishedError) {
              console.error('Error calling upload-finished endpoint:', uploadFinishedError);
              // Upload-finished API call başarısız olsa bile ana işleme devam et
            }
            
            resolve(response);
            return;
          }

        } catch (error: any) {
          console.error("Manifest Error:", error);
          retryCount++;
          
          // Handle specific error cases
          if (error.statusCode === 409) {
            console.log(`Conflict error encountered. Retry attempt ${retryCount} of ${MAX_RETRIES}`);
            // Add exponential backoff for retries
            await new Promise(resolve => setTimeout(resolve, Math.min(1000 * Math.pow(2, retryCount), 30000)));
          }
          
          if (retryCount >= MAX_RETRIES) {
            console.log('Max retries reached, stopping manifest checks');
            clearInterval(intervalId);
            if (requestTimeoutId) clearTimeout(requestTimeoutId);
            uploadManager.updateProgress(originalFileName, {
              stage: UploadStage.ERROR,
              error: "Failed to process manifest after multiple attempts"
            });
            reject(new Error("Max retry attempts reached for manifest processing"));
            return;
          }

          uploadManager.updateProgress(originalFileName, {
            stage: UploadStage.MANIFEST_PROCESSING,
            message: `Retrying manifest check (${retryCount}/${MAX_RETRIES})`,
            error: error.message
          });

        } finally {
          isRequestInProgress = false;
          if (requestTimeoutId) {
            clearTimeout(requestTimeoutId);
            requestTimeoutId = null;
          }
        }
      }, INTERVAL_DELAY);

      // No overall timeout - will continue checking until complete or error
    });
  };  

  useEffect(() => {
    dispatch(getUploadedObjectByUserId(jsonLoginInfo.uid))
  }, [dispatch, jsonLoginInfo.uid])

  const openObjectDetail = (urn: string) => {
    navigate('/object', {
      state: {
        urn
      },
    });
  };

  const getIconsOrThumbnail = (object: GetSubUploadedObjectResponseModel) => {
    const fileType = object.name.split(".")[1]
    if (object.thumbnail === "") {
      switch (fileType) {
        case "iam":
          return IAM

        case "ipt":
          return IPT

        case "dwfx":
          return DWFX

        case "dwg":
          return DWG

        case "fbx":
          return FBX

        case "zip":
          return ZIP

        case "rvt":
          return RVT

        case "dwf":
          return DWF

        case "sldasm":
          return SLDASM

        case "stl":
          return STL
        case 'IDW':
          return IDW;
        case 'idw':
          return IDW
        case "STL":
          return STL

        case "stp":
          return STP

        case "STP":
          return STP

        case "f3d":
          return F3D

        case "max":
          return MAX

        case "model":
          return MODEL

        case "glb":
          return GLB

        case "obj":
          return OBJ

        case "step":
          return STEP

        case "STEP":
          return STEP


        default:
          return UNDEFINEDIMAGE

      }
    }
    else {
      return `data:image/jpeg;base64,${object.thumbnail}`
    }
  }

  const handleUploadClick = () => {
    const uploadManager = UploadManager.getInstance();
    
    if (isPaused && pausedFiles.length > 0) {
      // Resume paused uploads
      setSelectedFiles(pausedFiles);
      setIsStarted(true);
      setIsPaused(false);
      return;
    }

    // Clear any existing state before starting new upload
    uploadManager.clearAll();
    setSelectedFiles([]);
    setIsStarted(false);
    setUploadModal(true);
  };

  // Upload button text based on state
  const getUploadButtonText = () => {
    if (isPaused) {
      return 'Continue Upload';
    }
    return 'Upload Files';
  };

  const handleQrCode = async (urn: string) => {
    try {
      const qrService = new GetQrSourceCodeService();
      const response = await qrService.GetByStringAsync(urn);
      
      if (response.qrSourceCode) {
        const qrUrl = qrService.generateQrCodeUrl(jsonLoginInfo.uid, urn);
        setQrCodeData(qrUrl);
        setIsQrModalOpen(true);
      }
    } catch (error) {
      console.error('Error getting QR code:', error);
    }
  };

  // Yardımcı fonksiyon
  const getCleanFileName = (fileName: string): string => {
    // Sadece Guid kısmını temizle
    const cleanName = fileName.replace(/_Guid_[a-f0-9-]+/i, '')
                            .trim();
    return cleanName;
  };

  const getTotalZipCount = () => {
    // ... existing code ...
  };

  return loginInfo && jsonLoginInfo.isAuthenticated ? (
    <div className="h-[calc(100vh-80px)] min-h-full z-50 overflow-y-auto">
      <div className="p-6">
        {objectListSelectorByUserId.loading ? (
          <div className='flex flex-col items-center justify-center min-h-[calc(100vh-80px)]  space-y-6'>
            <div className="w-24 h-24 relative">
              <div className="absolute inset-0 border-4 border-blue-100 rounded-full animate-pulse"></div>
              <div className="absolute inset-0 border-4 border-blue-500 rounded-full animate-spin" 
                   style={{ borderRightColor: 'transparent', animationDuration: '1s' }}></div>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">Welcome Back!</h3>
              <p className="text-gray-500 text-base">Loading your models, please wait...</p>
              <div className="mt-2 text-sm text-gray-400">This may take a few moments</div>
            </div>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-9 gap-4 ">
              <div 
                onClick={handleUploadClick}
                className={`relative bg-gradient-to-br ${isPaused ? 'from-yellow-50 to-white' : 'from-blue-50 to-white'} xs:max-w-[165px] max-w-[180px] h-[240px] rounded-xl border-2 border-dashed ${isPaused ? 'border-yellow-200 hover:border-yellow-400' : 'border-blue-200 hover:border-blue-400'} transition-all duration-300 cursor-pointer group flex flex-col items-center justify-center p-6`}
              >
                <div className={`absolute top-0 left-0 w-full h-full ${isPaused ? 'bg-yellow-500' : 'bg-blue-500'} opacity-0 group-hover:opacity-5 transition-opacity duration-300 rounded-xl`} />
                
                <div className={`w-20 h-20 ${isPaused ? 'bg-yellow-100' : 'bg-blue-100'} rounded-full flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300`}>
                  <CloudUploadIcon 
                    className={isPaused ? "text-yellow-500" : "text-blue-500"}
                    sx={{ fontSize: 40 }}
                  />
                </div>
                
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {getUploadButtonText()}
                </h3>
                <p className="text-sm text-gray-500 text-center">
                  {isPaused ? 'Click to continue uploading' : 'Click to upload your 3D models'}
                </p>
                
                <div className="absolute bottom-6 left-0 right-0 flex justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <span className={`${isPaused ? 'bg-yellow-600' : 'bg-blue-600'} text-white px-4 py-2 rounded-full text-sm font-medium`}>
                    {getUploadButtonText()}
                  </span>
                </div>
              </div>

              {/* Mevcut Dosyalar */}
              {objectListSelectorByUserId.data && objectListSelectorByUserId.data.length > 0 && 
                objectListSelectorByUserId.data.map((object) =>{
                  console.log(object)
                  return (
                    <CardItem
                      key={object.name}
                      isActive={object.isActive ?? true}
                      imageName={getIconsOrThumbnail(object)}
                      title={object.name}
                      viewCount={object.viewCount}
                      text=''
                      buttonNames={['Detail', '</>']}
                      buttonEvents={[
                        () => openObjectDetail(object.urn),
                        async () => {
                          setHtmlContent(object.iframeButtonCSS)
                          setSelectedModelId(object.id);
                          setWebSite(object.webSite !== null || object.webSite !== undefined ? object.webSite : "")
                          setSelectedName(object.name)
                          setTextAreaModal(true);
                        }
                      ]}
                      onDelete={() => {
                        setSelectedDeleteValue(object.name);
                        setSelectedObjectId(object.id);
                        setConfirmModal(true);
                      }}
                      onClickToCard={() => {
                        window.open(`${baseUrl}objects/content/${object.urn}/view/${jsonLoginInfo.uid}/properties/`)
                      }}
                      processingMessage={!object.isActive ? "Your model is loading..." : undefined}
                    />
                  )
                })
              }
            </div>

            {/* Modallar */}
            {uploadModal && (
              <MultipleUploadModal
                handleCloseModal={handleCloseModal}
                setIsDragDrop={setUploadModal}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                setZipFile={setZipFile}
                zipFile={zipFile}
                processZipFile={processZipFile}
                openZipOption={openZipOption}
                setOpenZipOption={setOpenZipOption}
                extractZipStatus={extractZipFileStatus}
                setExtractZipStatus={setExtractZipFileStatus}
                creditStatus={creditWarnings}
                setCreditStatus={setCreditWarnings}
                setManifestStatus={setManifestStatus}
                setSteppedFileName={setSteppedFileName}
                setStep={setStep}
                invalidFileMessage={invalidFileErrorMessage}
                setInvalidFileMessage={setInvalidFileErrorMessage}
                isFinishedToLoading={isFinishedToLoading}
                setIsFinishedToLoading={setIsFinishedToLoading}
                afterUploadError={afterUploadError}
                setAfterUploadError={setAfterUploadError}
                startToGetChunkStatus={startToGetChunkStatus}
                setStartToGetChunkStatus={setStartToGetChunkStatus}
                processToUpload={processToUpload}
                setProcessToUpload={setProcessToUpload}
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                waitingForManifest={waitingForManifest}
                setWaitingForManifest={setWaitingForManifest}
                step={step}
                steppedFileName={steppedFileName}
                manifestStatus={manifestStatus}
                currentUploadIndex={currentUploadIndex}
                isStarted={isStarted}
                setIsStarted={setIsStarted}
                statusValues={statusValues}
                setStatusValues={setStatusValues}
              />
            )}

            {
              openSupportedFormats && (
                <Dialog
                  open={openSupportedFormats}
                  onClose={() => setOpenSupportedFormats(false)}
                  aria-labelledby="file-format-dialog-title"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle id="file-format-dialog-title">Supported 2D and 3D file formats</DialogTitle>
                  <DialogContent>
                    <Typography variant="body2" gutterBottom>
                      Veewer can display the following file formats:
                    </Typography>
                    <Grid container spacing={2}>
                      {[0, 1, 2].map((column) => (
                        <Grid item xs={4} key={column}>
                          <List dense>
                            {supportedFormats.slice(column * 20, (column + 1) * 20).map((format) => (
                              <ListItem key={format}>
                                <ListItemText primary={format} />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      ))}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenSupportedFormats(false)}>Close</Button>
                  </DialogActions>
                </Dialog>
              )
            }

            {filterModal && <></>}

            {confirmModal && (
              <Dialog
                open={confirmModal}
                onClose={() => setConfirmModal(false)}
                PaperProps={{
                  style: {
                    borderRadius: '16px',
                    padding: '12px'
                  }
                }}
              >
                <DialogTitle sx={{ 
                  fontSize: '1.5rem', 
                  fontWeight: 600,
                  color: '#EF4444',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <span role="img" aria-label="warning" style={{ fontSize: '1.8rem' }}>⚠️</span>
                  Delete Confirmation
                </DialogTitle>
                
                <DialogContent>
                  <Typography variant="body1" sx={{ mb: 2, color: '#4B5563' }}>
                    Are you sure you want to delete <strong>{selectedDeleteValue}</strong>?
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    This action cannot be undone. The model and all associated data will be permanently removed.
                  </Typography>
                </DialogContent>
                
                <DialogActions sx={{ padding: '16px 24px' }}>
                  <Button
                    onClick={() => setConfirmModal(false)}
                    variant="outlined"
                    sx={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      px: 3,
                      borderColor: '#D1D5DB',
                      color: '#374151',
                      '&:hover': {
                        borderColor: '#9CA3AF',
                        backgroundColor: '#F3F4F6'
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      try {
                        const result = await dispatch(deleteUploadedObject({
                          userId: jsonLoginInfo.uid,
                          objectId: selectedObjectId
                        })).unwrap();
                        
                        if (result) {
                          setConfirmModal(false);
                          await dispatch(getUploadedObjectByUserId(jsonLoginInfo.uid));
                          
                          // Modern başarı bildirimi
                          Swal.fire({
                            title: '<div style="font-size: 24px; font-weight: 600; color: #059669">Successfully Deleted</div>',
                            html: '<div style="font-size: 16px; color: #374151">The model has been permanently removed</div>',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                            backdrop: 'rgba(0,0,0,0.4)',
                            customClass: {
                              popup: 'animated fadeInDown faster',
                              container: 'custom-swal-container'
                            },
                            background: '#ffffff',
                            iconColor: '#059669'
                          });
                        }
                      } catch (error: any) {
                        console.error('Error deleting model:', error);
                        
                        // Modern hata bildirimi
                        Swal.fire({
                          title: '<div style="font-size: 24px; font-weight: 600; color: #DC2626">Delete Failed</div>',
                          html: `<div style="font-size: 16px; color: #374151">${error.message || 'An error occurred while deleting the model'}</div>`,
                          icon: 'error',
                          confirmButtonText: 'Try Again',
                          confirmButtonColor: '#DC2626',
                          backdrop: 'rgba(0,0,0,0.4)',
                          customClass: {
                            popup: 'animated fadeInDown faster',
                            confirmButton: 'custom-swal-confirm-button'
                          },
                          background: '#ffffff',
                          iconColor: '#DC2626'
                        });
                        setConfirmModal(false);
                      }
                    }}
                    variant="contained"
                    disabled={deleteSelectedObject.loading}
                    sx={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      px: 3,
                      backgroundColor: '#EF4444',
                      '&:hover': {
                        backgroundColor: '#DC2626'
                      },
                      '&:disabled': {
                        backgroundColor: '#FDA4AF',
                        color: 'white'
                      }
                    }}
                  >
                    {deleteSelectedObject.loading ? (
                      <CircularProgress size={20} sx={{ color: 'white', mr: 1 }} />
                    ) : null}
                    {deleteSelectedObject.loading ? 'Deleting...' : 'Delete'}
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {textAreaModal && (
              <TextAreaModal
                show={textAreaModal}
                onHide={setTextAreaModal}
                incomingValue={htmlContent}
                secondOptionalValue={webSite}
                thirdOptionalValue={selectedName}
                modelId = {selectedModelId}
              />
            )}

            {
              openChangeModal && (
                <ChangeNameModal
                  show={openChangeModal}
                  onHide={() => setOpenChangeModal(false)}
                  currentName={selectedName}
                  objectId={selectedObjectId}
                />
              )
            }

            <QRCodeModal
              open={isQrModalOpen}
              onClose={() => setIsQrModalOpen(false)}
              qrValue={qrCodeData}
            />
          </>
        )}
      </div>
    </div>

  ) : (
    <Unauthorized />
  );
};

export default ObjectList;


