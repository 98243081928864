import axios from 'axios';
import { baseUrl } from '../../config/axios.config';

export class CheckFeatureService {
  async GetByStringManyParamsAsync(params: string[]): Promise<boolean> {
    try {
      const [userId, featureName] = params;
      const response = await axios.get(`${baseUrl}usersubscriptions/featureavailable`, {
        params: {
          userId,
          featureName
        }
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error checking feature availability:', error);
      return false;
    }
  }
} 