import React, { useState } from 'react';

interface CardItemProps {
  imageName: string | JSX.Element;
  title?: string;
  text?: string;
  viewCount?: number;
  buttonNames: (string | JSX.Element)[];
  buttonEvents: (React.MouseEventHandler<HTMLButtonElement> | undefined)[];
  onDelete?: () => void;
  id?: string;
  onClickToCard?: () => void;
  setDeleteText?: (value: React.SetStateAction<string>) => void;
  tickIcon?: JSX.Element;
  loading?: any;
  isActive: boolean;
  isCloud?: boolean;
  processingMessage?: string;
}

const CardItem: React.FC<CardItemProps> = ({
  title,
  text,
  viewCount,
  setDeleteText,
  imageName,
  buttonNames,
  buttonEvents,
  onDelete,
  onClickToCard,
  tickIcon,
  isActive,
  isCloud = false,
  processingMessage,
}) => {
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showViewerNotification, setShowViewerNotification] = useState(false);
  const [deleteHovered, setDeleteHovered] = useState(false);
  const underscoreIndex = title?.indexOf('_');
  const dotIndex = title?.lastIndexOf('.');
  let name = underscoreIndex !== -1 ? title?.slice(0, underscoreIndex) : title?.slice(0, dotIndex);

  return (
    <div 
      title={name || title}
      className={`relative xs:max-w-[165px] max-w-[180px] h-[240px] overflow-hidden rounded-2xl border transition-all duration-300 ease-in-out bg-white
        ${!isActive ? 'border-gray-200' : 'hover:shadow-xl hover:scale-102'}
      `}
      onMouseEnter={() => isActive && setShowDeleteBtn(true)}
      onMouseLeave={() => {
        isActive && setShowDeleteBtn(false);
        setDeleteHovered(false);
      }}
    >
      {/* Processing Overlay for Inactive Items */}
      {!isActive && processingMessage && (
        <div className="absolute inset-0 z-10 pointer-events-none">
          {/* Subtle overlay */}
          <div className="absolute inset-0 bg-white/10 backdrop-blur-[0.5px]"></div>
          
          {/* Processing badge */}
          <div className="absolute top-3 right-3 bg-blue-600/90 text-white px-2 py-1 rounded-md text-xs font-medium shadow-sm flex items-center">
            <svg className="animate-spin -ml-0.5 mr-1.5 h-3 w-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing
          </div>
        </div>
      )}

      {/* Delete Button */}
      {showDeleteBtn && isActive && !isCloud && onDelete && (
        <button
          title="Delete Model"
          className={`absolute top-2 right-2 z-10 flex items-center overflow-hidden
            ${deleteHovered ? 'w-24 bg-red-100' : 'w-8 bg-white bg-opacity-90'}
            rounded-full p-1.5 transition-all duration-300 shadow-lg hover:shadow-xl
            group
          `}
          onClick={() => {
            if (setDeleteText !== undefined && title !== undefined) {
              setDeleteText(title);
            }
            onDelete();
          }}
          onMouseEnter={() => setDeleteHovered(true)}
          onMouseLeave={() => setDeleteHovered(false)}
        >
          <div className="flex items-center space-x-2">
            <div className={`flex-shrink-0 rounded-full p-1 ${deleteHovered ? 'bg-red-200' : ''} transition-colors duration-300`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-4 w-4 transition-colors duration-300 ${deleteHovered ? 'text-red-600' : 'text-red-500'}`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <span className={`text-sm font-medium text-red-600 whitespace-nowrap transition-opacity duration-300
              ${deleteHovered ? 'opacity-100' : 'opacity-0'}
            `}>
              Delete
            </span>
          </div>
        </button>
      )}

      {/* Image Section */}
      <div 
        className="h-32 overflow-hidden relative cursor-pointer group"
        onMouseEnter={() => setShowViewerNotification(true)}
        onMouseLeave={() => setShowViewerNotification(false)}
        onClick={() => isActive && onClickToCard && onClickToCard()}
      >
        {typeof imageName === 'string' ? (
          <>
            <img
              src={imageName}
              alt={title || 'Card image'}
              className={`w-full h-full object-contain object-center transition-transform duration-300 ${isActive ? 'group-hover:scale-110' : ''}`}
            />
            {showViewerNotification && isActive && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <span className="text-white text-xs font-medium px-3 py-1.5 bg-black bg-opacity-75 rounded-full transform scale-95 group-hover:scale-100 transition-transform duration-300">
                  {isCloud ? "Click to transfer" : "Click to view"}
                </span>
              </div>
            )}
          </>
        ) : (
          <div className="w-full h-full bg-gray-200 animate-pulse rounded-lg"></div>
        )}
      </div>

      {/* Content Section */}
      <div className="p-3">
        {title && (
          <h3 className="text-sm font-semibold mb-1 truncate text-gray-800">{name}</h3>
        )}
        {text && (
          <p className="text-xs text-gray-600 mb-2 overflow-hidden line-clamp-2">{text}</p>
        )}
        
        {isActive ? (
          <div className="mt-auto flex flex-wrap gap-1.5 items-center">
            {buttonNames.map((buttonName, i) => (
              <button
                key={i}
                onClick={buttonEvents[i]}
                className={`px-2.5 py-1 text-xs font-medium rounded-lg transition-all duration-200 
                  ${i === 0 
                    ? 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:from-blue-600 hover:to-indigo-700 hover:shadow-md' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:shadow-sm'}
                `}
              >
                {buttonName}
              </button>
            ))}
            {viewCount !== undefined && (
              <div className="flex items-center gap-1 ml-auto" title="View count">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                </svg>
                <span className="text-xs text-gray-500">{viewCount}</span>
              </div>
            )}
            {tickIcon && (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            )}
          </div>
        ) : (
          <div className="mt-auto">
            <div className="relative bg-gradient-to-r from-gray-900/10 to-gray-800/5 p-2 -mx-5 mt-4 rounded-b-lg">
              {/* Background filigree pattern */}
              <div className="absolute inset-0 opacity-10">
                <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                  <pattern id="filigree" width="20" height="20" patternUnits="userSpaceOnUse">
                    <path d="M 0,10 10,0 20,10 10,20 Z" fill="none" stroke="currentColor" strokeWidth="0.5" />
                  </pattern>
                  <rect width="100%" height="100%" fill="url(#filigree)" />
                </svg>
              </div>
              
              {/* Processing info */}
              <div className="relative z-10">
                <p className="text-xs text-gray-700 font-medium mb-1.5 line-clamp-1">
                  {processingMessage}
                </p>
                
                <div className="flex items-center justify-between">
                  <p className="text-xs text-gray-500 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    Email when ready
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <style>
        {`
          @keyframes indeterminateProgress {
            0% { transform: translateX(-100%); }
            50% { transform: translateX(0); }
            100% { transform: translateX(100%); }
          }
        `}
      </style>
    </div>
  );
}

export default CardItem;

