import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import './App.css';
import './styles/global.scss';
import MainRoutes from './navigators/MainRoutes';
import { LogoutService } from './services/auth/logout.service';
import TawkTo from './components/Tawkto';
import { UploadStatusProvider } from './context/UploadStatusContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <GoogleOAuthProvider clientId="920303349878-0pcfu9ihc47e563opv8dk8loi7ab5k3u.apps.googleusercontent.com">
      <UploadStatusProvider>
        <MainRoutes />
      </UploadStatusProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
