"use client"

import { useState, useEffect, useRef } from 'react'
import { baseUrl } from '../config/axios.config'
import Footer from '../components/Footer/Footer'
import axios from 'axios'

type ModelSize = '1000' | '1300' | '1500'
type ModelColor = 'red' | 'blue' | 'black'

type UrlMapping = {
  [K in ModelColor]: {
    [S in ModelSize]: string
  }
}
// interface ComponentProps {
//   successToken: boolean;
// }

const Component = () => {
  // URL Constants
  const baseURL = baseUrl
  const red1 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1JlZDElMjBOZXdfR3VpZF9mZjAwNjE3MC1hOWFmLTQ5MTAtYjEyNC0yOWYxM2FiYjE0ZGUuemlw/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const red2 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1JlZDIlMjBOZXdfR3VpZF9mZWZmMTk1Ni0wNjBkLTRiNmUtODU0NC0xZDFkNDE3YWIzYTkuemlw/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const red3 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL1JlZDMlMjBOZXdfR3VpZF8wNTcxMTNiZC1kMTJhLTQ2ZWItODQ1OC00M2QzMWNiMmUzYWEuemlw/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;

  const blue1 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0JsdWUxJTIwTmV3X0d1aWRfNjgzNzg3MTEtODJlMS00NTk0LWIwMGMtMzU2MTRlZjhlNGVmLnppcA/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const blue2 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0JsdWUyJTIwTmV3X0d1aWRfMzMzYzBkNWItNjgyYy00MzAzLWI4ZmEtOWQzOTZhNDk3NmZlLnppcA/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const blue3 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0JsdWUzJTIwTmV3X0d1aWRfMTM4N2NkYzctMmMzZC00NGVlLWI2NzgtYTcwZTg1NGM0NDY5LnppcA/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;

  const black1 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0JsYWNrMSUyME5ld19HdWlkX2NiZTFmNTllLTNiNDAtNDVmNS04Y2IyLTc4ZDQ1ZjEyNGEzNS56aXA/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const black2 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0JsYWNrMiUyME5ld19HdWlkXzFlMGFlZmRkLTc3MWEtNDViMC1iODQ5LTU3MTVlYTBiOTYzYi56aXA/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const black3 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6MjdiMjQxZmMtMGFhMS00Zjc4LTgzMzktMTA3MjczNWYxNDcyL0JsYWNrMyUyME5ld19HdWlkXzg3ZGE2NjlmLWQwMDctNGY5NC05ZWUwLTgzMTY5OTFiYjE3MC56aXA/view/27b241fc-0aa1-4f78-8339-1072735f1472/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;

  const [isServerHealthy, setIsServerHealthy] = useState<boolean>(false);
  const [isCheckingHealth, setIsCheckingHealth] = useState<boolean>(true);
  const [errorType, setErrorType] = useState({ code: 0, message: '' });
  const [iframeError, setIframeError] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(red1);
  const [modelSize, setModelSize] = useState<ModelSize>('1000');
  const [modelColor, setModelColor] = useState<ModelColor>('red');

  // URL mapping object
  const urlMapping: UrlMapping = {
    red: {
      '1000': red1,
      '1300': red2,
      '1500': red3
    },
    blue: {
      '1000': blue1,
      '1300': blue2,
      '1500': blue3
    },
    black: {
      '1000': black1,
      '1300': black2,
      '1500': black3
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Single health check when component mounts
  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        setIsCheckingHealth(true);
        const response = await axios.get(`${baseUrl}health`);
        if (response.data.status === "Healthy") {
          setIsServerHealthy(true);
          setIframeError(false);
          setErrorType({ code: 0, message: '' });
        } else {
          setIsServerHealthy(false);
          setIframeError(true);
          setErrorType({
            code: -1,
            message: 'VEEWER service is currently unavailable. Please try again later.'
          });
        }
      } catch (error) {
        setIsServerHealthy(false);
        setIframeError(true);
        setErrorType({
          code: -1,
          message: 'VEEWER service is currently unavailable. Please try again later.'
        });
      } finally {
        setIsCheckingHealth(false);
      }
    };

    checkServerHealth();
  }, []);

  // Remove the separate iframe URL check
  useEffect(() => {
    if (isServerHealthy) {
      setIframeError(false);
    }
  }, [iframeUrl, isServerHealthy]);

  const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setModelSize(event.target.value as ModelSize)
  }

  const handleColorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setModelColor(event.target.value as ModelColor)
  }

  const handleUpdate = () => {
    const newUrl = urlMapping[modelColor][modelSize]
    setIframeUrl(newUrl)
  }

  const handleReset = () => {
    setModelSize('1000')
    setModelColor('red')
    setIframeUrl(red1)
  }

  const handleIframeLoad = () => {
    setIframeError(false);
  };
  
  const handleIframeError = () => {
    console.error('Configurator - iframe loading error for URL:', iframeUrl);
    setIframeError(true);
  };

  return (
    <div className="flex flex-col md:flex-row h-[calc(100vh-80px)] bg-gray-100">
      <div className="w-full md:w-64 p-4 bg-white shadow-md">
        <div className="space-y-4">
          <div>
            <label htmlFor="modelSize" className="block text-sm font-medium text-gray-700 mb-1">
              Model Size(W)
            </label>
            <select
              id="modelSize"
              value={modelSize}
              onChange={handleSizeChange}
              className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              disabled={!isServerHealthy}
            >
              <option value="1000">1000</option>
              <option value="1300">1300</option>
              <option value="1500">1500</option>
            </select>
          </div>

          <div>
            <label htmlFor="modelColor" className="block text-sm font-medium text-gray-700 mb-1">
              Model Color
            </label>
            <select
              id="modelColor"
              value={modelColor}
              onChange={handleColorChange}
              className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              disabled={!isServerHealthy}
            >
              <option value="red">Red</option>
              <option value="blue">Blue</option>
              <option value="black">Black</option>
            </select>
          </div>

          <div className="flex space-x-2">
            <button
              onClick={handleReset}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={!isServerHealthy}
            >
              Reset
            </button>
            <button
              onClick={handleUpdate}
              className="flex-1 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={!isServerHealthy}
            >
              Update
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 p-4">
        {isCheckingHealth ? (
          <div className="w-full h-full flex items-center justify-center bg-white rounded-lg">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500" />
          </div>
        ) : isServerHealthy ? (
          <iframe
            src={iframeUrl}
            className="w-full h-full border-none bg-white"
            title="3D Model Viewer"
            loading="eager"
            onLoad={handleIframeLoad}
            onError={handleIframeError}
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-white rounded-lg">
            <div className="bg-white p-6 rounded-xl shadow-lg text-center max-w-md mx-4 border border-gray-200">
              <div className="inline-flex items-center justify-center w-16 h-16 mb-6 bg-red-50 rounded-full">
                <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold text-gray-800 mb-3">
                VEEWER Unavailable
              </h3>
              <p className="text-gray-600 mb-6">
                {errorType.message}
              </p>
              <div className="flex flex-col space-y-3">
                <button
                  onClick={() => {
                    setIsCheckingHealth(true);
                    setIsServerHealthy(false);
                    axios.get(`${baseUrl}health`)
                      .then(response => {
                        if (response.data.status === "Healthy") {
                          setIsServerHealthy(true);
                          setIframeError(false);
                          setErrorType({ code: 0, message: '' });
                        }
                      })
                      .catch(() => {
                        setIsServerHealthy(false);
                        setIframeError(true);
                        setErrorType({
                          code: -1,
                          message: 'VEEWER service is currently unavailable. Please try again later.'
                        });
                      })
                      .finally(() => {
                        setIsCheckingHealth(false);
                      });
                  }}
                  className="w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition-colors shadow-md flex items-center justify-center gap-2"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                  Try Again
                </button>
                <button
                  onClick={() => window.location.href = 'mailto:contact@veewer.com'}
                  className="w-full bg-gray-100 text-gray-700 px-4 py-3 rounded-lg hover:bg-gray-200 transition-colors flex items-center justify-center gap-2"
                >
                  Contact Support
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Component
