import React, { useEffect, useState } from 'react';
import { SubscriptionPlan } from '../../models/subscription/get.all.subscription.info.model';
import axios from 'axios';
import { baseUrl } from '../../config/axios.config';
import { RootState, useAppDispatch } from '../../store';
import { useAppSelector } from '../../store';
import { getSubscriptions } from '../../store/features/subscription/get.all.subscription.infos.slice';
import ContactModal from '../ContactModal/ContactModal';
import PaymentModal from '../PaymentModal/PaymentModal';

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  userInfo?: {
    fullName?: string;
    email?: string;
    phone?: string;
    company?: string;
  };
}

interface PaymentStatus {
  status: 'idle' | 'loading' | 'success' | 'error';
  message?: string;
  sessionId?: string;
  checkoutUrl?: string;
}

export default function SubscriptionModal({ isOpen, onClose, userInfo }: SubscriptionModalProps) {
  const dispatch = useAppDispatch();
  const subscriptionSlice = useAppSelector((x: RootState) => x.getSubscriptionsSlice);
  const subscriptionsData = subscriptionSlice.data;
  const [subscriptions, setSubscriptions] = useState<SubscriptionPlan[]>(subscriptionsData || []);
  const [loading, setLoading] = useState(true);
  const [isYearly, setIsYearly] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>({
    status: 'idle'
  });

  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);

  useEffect(() => {
    if (subscriptionSlice.data) {
      const filteredPlans = subscriptionSlice.data
        .filter((plan: SubscriptionPlan) => plan.name !== "Starter")
        .sort((a: SubscriptionPlan, b: SubscriptionPlan) => {
          if (a.name === "Enterprise") return 1;
          if (b.name === "Enterprise") return -1;
          return 0;
        });
      setSubscriptions(filteredPlans);
      setLoading(false);
    }
  }, [subscriptionSlice.data]);

  // Add console log for the payment status
  useEffect(() => {
    console.log('Payment status updated:', paymentStatus);
    
    // Auto-redirect to Stripe checkout when URL is available
    if (paymentStatus.status === 'success' && paymentStatus.checkoutUrl) {
      window.location.href = paymentStatus.checkoutUrl;
    }
  }, [paymentStatus]);

  const handleSubscription = async (plan: SubscriptionPlan) => {
    if (plan.name === "Enterprise") {
      setSelectedPlan(plan.name);
      setShowContactModal(true);
      return;
    }

    setPaymentStatus({ status: 'loading' });

    try {
      const login = sessionStorage.getItem('login');
      if (!login) {
        setPaymentStatus({
          status: 'error',
          message: 'User session not found'
        });
        return;
      }
      const jsonLogin = JSON.parse(login);

      const response = await axios({
        method: 'POST',
        url: `${baseUrl}stripe/create-checkout-session`,
        data: {
          PriceId: isYearly ? plan.stripePriceId.yearly : plan.stripePriceId.monthly,
          UserEmail: userInfo?.email,
          UserId: jsonLogin.uid,
          SuccessUrl: window.location.origin + "/profile?status=success&session_id={CHECKOUT_SESSION_ID}",
          CancelUrl: window.location.origin + "/profile?status=cancel"
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Stripe response:', response.data);

      // Check if we have a valid response with url
      if (response.data && response.data.url) {
        setPaymentStatus({
          status: 'success',
          checkoutUrl: response.data.url,
          message: 'Payment session created successfully!'
        });
      } else {
        setPaymentStatus({
          status: 'error',
          message: 'No checkout URL received from server'
        });
      }
    } catch (error: any) {
      console.error('Stripe error:', error);
      setPaymentStatus({
        status: 'error',
        message: error.response?.data?.message || 'Error creating checkout session'
      });
    }
  };

  const handlePaymentModalClose = () => {
    setPaymentStatus({ status: 'idle' });
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        {/* Backdrop */}
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
          onClick={onClose}
        />

        {/* Modal */}
        <div className="relative min-h-screen flex items-center justify-center p-4">
          <div className="relative bg-white rounded-2xl max-w-6xl w-full mx-auto shadow-xl">
            {/* Close button */}
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-500"
            >
              <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Header */}
            <div className="p-4 text-center border-b border-gray-200">
              <h2 className="text-xl font-bold text-gray-900">Upgrade Your Plan</h2>
              <div className="flex items-center justify-center mt-3">
                <span className={`text-sm font-medium mr-2 ${!isYearly ? 'text-gray-900' : 'text-gray-400'}`}>
                  Monthly
                </span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={isYearly}
                    onChange={() => setIsYearly(!isYearly)}
                  />
                  <div className={`relative w-11 h-6 rounded-full transition-colors peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 ${
                    isYearly ? 'bg-indigo-600' : 'bg-gray-200'
                  }`}>
                    <div className={`absolute top-[2px] left-[2px] bg-white w-5 h-5 rounded-full transition-transform ${
                      isYearly ? 'translate-x-5' : 'translate-x-0'
                    }`}></div>
                  </div>
                </label>
                <span className={`text-sm font-medium ml-2 ${isYearly ? 'text-gray-900' : 'text-gray-400'}`}>
                  Yearly
                </span>
              </div>
            </div>

            {/* Content */}
            <div className="p-4">
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-indigo-600"></div>
                </div>
              ) : subscriptions.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                  {subscriptions.map((plan: SubscriptionPlan) => (
                    <div
                      key={plan.name}
                      className={`relative flex flex-col rounded-lg shadow-sm overflow-hidden ${
                        plan.isMostPopular
                          ? 'bg-gradient-to-b from-gray-800 to-gray-900 text-white ring-1 ring-indigo-600'
                          : 'bg-white border border-gray-200'
                      }`}
                    >
                      {plan.isMostPopular && (
                        <div className="absolute top-0 left-1/2 -translate-x-1/2 px-2 py-0.5 bg-indigo-600 text-white text-[10px] font-semibold rounded-b">
                          Most Popular
                        </div>
                      )}
                      
                      <div className="p-3">
                        <div className="space-y-2">
                          <h3 className={`text-base font-semibold ${plan.isMostPopular ? 'text-white' : 'text-gray-900'}`}>
                            {plan.name}
                          </h3>
                          <div className="flex items-baseline">
                            <span className={`text-2xl font-bold tracking-tight ${plan.isMostPopular ? 'text-white' : 'text-gray-900'}`}>
                              {plan.name === "Enterprise" ? "Custom" : `£${isYearly ? plan.pricing.yearly : plan.pricing.monthly}`}
                            </span>
                            {plan.pricing.monthly !== 'Custom' && (
                              <span className={`ml-1 text-[10px] ${plan.isMostPopular ? 'text-gray-300' : 'text-gray-500'}`}>
                                {isYearly ? '/year' : '/month'}
                              </span>
                            )}
                          </div>
                          
                          <div className={`text-xs ${plan.isMostPopular ? 'text-gray-300' : 'text-gray-600'}`}>
                            <div>Uploads: {plan.name === "Enterprise" ? "Custom" : isYearly ? plan.yearlyCredit + "/year" : plan.monthlyCredit + "/month"}</div>
                          </div>
                        </div>

                        <div className="mt-3 space-y-2">
                          <div className={`text-[10px] font-semibold ${plan.isMostPopular ? 'text-gray-200' : 'text-gray-900'}`}>
                            Features included:
                          </div>
                          <ul className="space-y-1.5">
                            {plan.features.map((feature, i) => (
                              <li key={i} className="flex items-start gap-1.5">
                                <div className="flex-shrink-0 h-3 w-3 mt-0.5">
                                  {feature.available ? (
                                    <svg className={`h-3 w-3 ${plan.isMostPopular ? 'text-indigo-400' : 'text-indigo-600'}`} viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M10 15.172l-3.59-3.59-1.415 1.415L10 18 20 8l-1.415-1.415z" />
                                    </svg>
                                  ) : (
                                    <svg className="h-3 w-3 text-gray-400" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M18.3 5.71L12 12.001l-6.29-6.29-1.42 1.42 6.29 6.29-6.29 6.29 1.42 1.42L12 14.421l6.29 6.29 1.42-1.42-6.29-6.29 6.29-6.29z" />
                                    </svg>
                                  )}
                                </div>
                                <span className={`text-[10px] leading-tight ${plan.isMostPopular ? 'text-gray-300' : 'text-gray-600'}`}>
                                  {feature.featureName}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="mt-auto p-3">
                        <button
                          onClick={() => handleSubscription(plan)}
                          className={`w-full py-1.5 px-2 rounded text-xs font-semibold transition-colors ${
                            plan.isMostPopular
                              ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                              : 'bg-gray-900 text-white hover:bg-gray-800'
                          }`}
                        >
                          {plan.name === "Enterprise" 
                            ? "Contact Us" 
                            : `Subscribe ${isYearly ? 'yearly' : 'monthly'}`}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center text-gray-500">
                  No subscription plans available
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Contact Modal for Enterprise Plan */}
      <ContactModal
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
        selectedPlan={selectedPlan}
        userInfo={userInfo}
      />

      {/* Payment Status Modal */}
      <PaymentModal
        isOpen={paymentStatus.status !== 'idle'}
        onClose={handlePaymentModalClose}
        status={paymentStatus.status}
        message={paymentStatus.message}
        sessionId={paymentStatus.sessionId}
        checkoutUrl={paymentStatus.checkoutUrl}
      />
    </>
  );
} 