import { Subject, BehaviorSubject } from 'rxjs';

export enum UploadStage {
  QUEUED = 'QUEUED',
  PREPARING = 'PREPARING',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  MANIFEST_PROCESSING = 'MANIFEST_PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  PAUSED = 'PAUSED'
}

export interface UploadProgress {
  stage: UploadStage;
  progress?: number;
  bytesUploaded?: number;
  totalBytes?: number;
  message?: string;
  error?: string;
  currentOperation?: string;
  objectId?: string;
  location?: string;
  rootFileName?: string;
}

export interface RootPathResponse {
  progress: number;
  bytesRead: number;
  totalBytes: number;
  path: string;
  fileName: string;
  completed: boolean;
}

export interface ProcessUploadResponse {
  response?: {
    bucketKey?: string;
    objectId?: string;
    objectKey?: string;
    size?: number;
    contentType?: string;
    location?: string;
  };
  progress: number;
  startByte: number;
  dataWrited: number;
  rootFileName?: string;
}

export interface FinalizeResponse {
  isSuccess: boolean;
  urns: string[];
  uid: string;
  files: string[];
}

export interface ManifestResponse {
  progress: string;
}

export class UploadManager {
  private static instance: UploadManager;
  private progressSubject = new BehaviorSubject<Map<string, UploadProgress>>(new Map());
  private uploadQueue: string[] = [];
  private lastUpdateTime: Map<string, number> = new Map();
  private readonly UPDATE_THRESHOLD = 500; // ms
  private onCompletionCallbacks: Array<() => void> = [];
  private activeControllers: Map<string, AbortController> = new Map();
  
  // Debug fonksiyonu ekleyelim
  private debug(message: string, ...args: any[]) {
    if (process.env.NODE_ENV === 'development') {
      console.log(message, ...args);
    }
  }
  
  static getInstance() {
    if (!UploadManager.instance) {
      UploadManager.instance = new UploadManager();
    }
    return UploadManager.instance;
  }

  getProgress() {
    return this.progressSubject.asObservable();
  }

  getCurrentProgress(): Map<string, UploadProgress> {
    return this.progressSubject.value;
  }

  updateProgress(fileName: string, progress: Partial<UploadProgress>) {
    const currentProgress = this.progressSubject.value;
    const existing = currentProgress.get(fileName) || { 
      stage: UploadStage.QUEUED,
      progress: 0,
      message: 'Ready to upload',
      currentOperation: 'Ready to upload'
    };
    
    const newProgress = {
      ...existing,
      ...progress,
      message: progress.message || existing.message,
      currentOperation: progress.currentOperation || existing.currentOperation,
      progress: progress.progress !== undefined ? progress.progress : existing.progress,
      bytesUploaded: progress.bytesUploaded || existing.bytesUploaded,
      totalBytes: progress.totalBytes || existing.totalBytes
    };

    let hasChanged = false;
    
    if (
      newProgress.stage !== existing.stage ||
      newProgress.progress !== existing.progress ||
      newProgress.message !== existing.message ||
      newProgress.currentOperation !== existing.currentOperation ||
      newProgress.error !== existing.error ||
      newProgress.bytesUploaded !== existing.bytesUploaded ||
      newProgress.totalBytes !== existing.totalBytes
    ) {
      hasChanged = true;
    }
    
    if (hasChanged) {
      const updatedProgress = new Map(currentProgress);
      updatedProgress.set(fileName, newProgress);
      this.progressSubject.next(updatedProgress);
    }
  }

  handleRootPathResponse(response: RootPathResponse, fileName: string) {
    const currentTime = Date.now();
    const lastUpdate = this.lastUpdateTime.get(fileName) || 0;
    const percentComplete = Math.round((response.bytesRead / response.totalBytes) * 100);

    // Only update if:
    // 1. It's the first update (lastUpdate === 0)
    // 2. It's been more than UPDATE_THRESHOLD ms since last update
    // 3. The file is completed
    // 4. There's been a significant progress change (>5%)
    const lastProgress = this.progressSubject.value.get(fileName)?.progress || 0;
    const significantChange = Math.abs(percentComplete - lastProgress) >= 5;

    if (response.completed || 
        lastUpdate === 0 || 
        currentTime - lastUpdate >= this.UPDATE_THRESHOLD ||
        significantChange) {
      
      this.updateProgress(fileName, {
        stage: UploadStage.UPLOADING,
        progress: percentComplete,
        bytesUploaded: response.bytesRead,
        totalBytes: response.totalBytes,
        message: `Preparing upload: ${(response.bytesRead / (1024 * 1024)).toFixed(1)}MB / ${(response.totalBytes / (1024 * 1024)).toFixed(1)}MB`,
        currentOperation: response.completed ? 'Preparation complete' : 'Preparing file...'
      });

      this.lastUpdateTime.set(fileName, currentTime);
    }
  }

  handleProcessUploadResponse(response: ProcessUploadResponse, fileName: string) {
    if (!response.response || !response.response.size) {
      // Handle in-progress upload
      const percentComplete = response.progress;
      this.updateProgress(fileName, {
        stage: UploadStage.UPLOADING,
        progress: percentComplete,
        message: `Uploading: ${percentComplete}%`,
        currentOperation: 'Uploading file...'
      });
      return;
    }

    // Handle completed upload with response data
    this.updateProgress(fileName, {
      stage: UploadStage.UPLOADING,
      progress: response.progress,
      bytesUploaded: response.dataWrited,
      totalBytes: response.response.size,
      message: `Uploading: ${(response.dataWrited / (1024 * 1024)).toFixed(1)}MB / ${(response.response.size / (1024 * 1024)).toFixed(1)}MB`,
      currentOperation: response.progress === 100 ? 'Upload complete' : 'Uploading file...',
      objectId: response.response.objectId,
      location: response.response.location,
      rootFileName: response.rootFileName
    });
  }

  handleFinalizeResponse(response: FinalizeResponse, fileName: string) {
    if (response.isSuccess) {
      this.updateProgress(fileName, {
        stage: UploadStage.PROCESSING,
        progress: 100,
        message: 'Upload finalized successfully',
        currentOperation: 'Starting processing...'
      });
    } else {
      this.updateProgress(fileName, {
        stage: UploadStage.ERROR,
        error: 'Failed to finalize upload'
      });
    }
  }

  handleManifestResponse(response: ManifestResponse, fileName: string) {
    this.debug('Manifest Response:', response);
    this.debug('Progress Value:', response.progress);
    this.debug('Original File Name:', fileName);
    
    if (!fileName) {
      return;
    }

    if (typeof response.progress === 'string') {
      if (response.progress === 'complete') {
        try {
          // Extract the base name from file paths like "folder/filename.ext"
          const name = fileName.includes('/') ? fileName.split('/').pop() : fileName;
          this.debug('Original name:', name);
          const cleanName = name ?? fileName;
          this.debug('Cleaned name:', cleanName);
          
          // Find the file with this name or one that ends with this name (for paths)
          let fileNameToUpdate = '';
          
          this.progressSubject.value.forEach((_, storedFileName) => {
            if (storedFileName === cleanName || storedFileName.endsWith(`/${cleanName}`)) {
              fileNameToUpdate = storedFileName;
            }
          });
          
          this.debug('File name to update:', fileNameToUpdate);
          
          // If we found the file, update its progress
          if (fileNameToUpdate) {
            this.debug('Status: Complete');
            this.updateProgress(fileNameToUpdate, {
              stage: UploadStage.COMPLETED,
              progress: 100,
              message: 'Processing complete',
              currentOperation: 'Conversion complete'
            });
            
            // Notify any completion callbacks
            this.onCompletionCallbacks.forEach(callback => callback());
          }
        } catch (error) {
          console.error('Error handling manifest complete:', error);
        }
      } else {
        // Handle percentage values like "5%"
        const numberMatch = response.progress.match(/(\d+)/);
        this.debug('Number Match:', numberMatch);
        
        if (numberMatch && numberMatch[1]) {
          const percentage = parseInt(numberMatch[1], 10);
          this.debug('Extracted Percentage:', percentage);
          this.debug('Updating manifest progress to:', percentage);
          
          const finalProgress: Partial<UploadProgress> = {
            stage: UploadStage.MANIFEST_PROCESSING,
            progress: percentage,
            message: `Processing model: ${percentage}%`,
            currentOperation: response.progress || 'Processing'
          };
          
          this.debug('Final progress state:', finalProgress);
          this.updateProgress(fileName, finalProgress);
        } else {
          this.debug('No number found in progress value');
          this.updateProgress(fileName, {
            stage: UploadStage.MANIFEST_PROCESSING,
            message: `Processing: ${response.progress}`,
            currentOperation: response.progress || 'Processing'
          });
        }
      }
    }
  }

  clearProgress(fileName: string) {
    const current = this.progressSubject.value;
    const updated = new Map(current);
    updated.delete(fileName);
    this.progressSubject.next(updated);
  }

  clearAll() {
    // Abort all active uploads before clearing
    this.abortAllUploads();
    // Clear all progress states
    this.progressSubject.next(new Map());
    this.uploadQueue = [];
    this.activeControllers.clear();
  }

  public removeProgress(fileName: string): void {
    const currentProgress = this.progressSubject.getValue();
    currentProgress.delete(fileName);
    this.progressSubject.next(new Map(currentProgress));
  }

  // Register a callback to be called when any upload completes
  registerCompletionCallback(callback: () => void) {
    this.onCompletionCallbacks.push(callback);
  }

  // Remove a registered callback
  unregisterCompletionCallback(callback: () => void) {
    this.onCompletionCallbacks = this.onCompletionCallbacks.filter(cb => cb !== callback);
  }

  // Clear all registered callbacks
  clearCompletionCallbacks() {
    this.onCompletionCallbacks = [];
  }

  // Trigger all registered callbacks
  private triggerCompletionCallbacks() {
    this.onCompletionCallbacks.forEach(callback => {
      try {
        callback();
      } catch (error) {
        console.error('Error in completion callback:', error);
      }
    });
  }

  public abortAllUploads(): void {
    // Abort all active uploads using their controllers
    this.activeControllers.forEach((controller, fileName) => {
      controller.abort();
      this.updateProgress(fileName, {
        stage: UploadStage.ERROR,
        error: 'Upload cancelled by user',
        message: 'Upload cancelled'
      });
    });
    
    // Clear the controllers map
    this.activeControllers.clear();
    
    // Update all in-progress uploads to ERROR state
    const currentProgress = this.progressSubject.value;
    const updatedProgress = new Map(currentProgress);
    
    updatedProgress.forEach((progress, fileName) => {
      if (progress.stage === UploadStage.UPLOADING || 
          progress.stage === UploadStage.PROCESSING ||
          progress.stage === UploadStage.MANIFEST_PROCESSING ||
          progress.stage === UploadStage.PREPARING) {
        updatedProgress.set(fileName, {
          ...progress,
          stage: UploadStage.ERROR,
          error: 'Upload cancelled by user',
          message: 'Upload cancelled'
        });
      }
    });
    
    this.progressSubject.next(updatedProgress);
  }

  // Add method to register abort controller for a file
  public registerAbortController(fileName: string, controller: AbortController): void {
    this.activeControllers.set(fileName, controller);
  }

  // Add method to remove abort controller for a file
  public removeAbortController(fileName: string): void {
    this.activeControllers.delete(fileName);
  }
} 